import { Label } from '@rossum/api-client/labels';
import { IconCircleMinus } from '@rossum/ui/icons/tabler';
import { Chip, Stack, SvgIcon, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type LabelsListProps = {
  labels: Label[];
};

export const LabelsList = ({ labels }: LabelsListProps) => {
  const intl = useIntl();

  if (labels.length === 0) {
    return (
      <Typography variant="body2" color="text.secondary">
        {intl.formatMessage({
          id: 'components.sidebarV2.annotationData.labelsList.noLabels',
        })}
      </Typography>
    );
  }

  return (
    <Stack direction="row" spacing={1} useFlexGap sx={{ flexWrap: 'wrap' }}>
      {labels.map(label => (
        <Chip
          size="small"
          variant="outlined"
          key={label.id}
          label={label.name}
          deleteIcon={
            <SvgIcon fontSize="small">
              <IconCircleMinus />
            </SvgIcon>
          }
          // TODO: Will uncomment when we can implement label deleting
          // onDelete={() => {
          //   console.log('deleting label', label.id);
          // }}
          sx={{ alignSelf: 'flex-start' }}
        />
      ))}
    </Stack>
  );
};

import { extensionFunctionType, rossumStore } from '@rossum/api-client/hooks';
import { Chip, CircularProgress, Stack, Typography } from '@rossum/ui/material';
import { get } from 'lodash';
import { MouseEvent } from 'react';
import { Control } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import ControlledToggle from '../../../../components/ReactHookForm/Toggle';
import { boldText, white } from '../../../../lib/formaterValues/index';
import { Extension } from '../../../../types/extensions';
import PaperBoxTitle from '../../../../ui/paper-box/PaperBoxTitle';
import { getIcon } from '../../../Extensions/lib/helpers';
import styles from '../../style.module.sass';
import { ExtensionData } from './helpers';
import { RetryButton } from './RetryButton';

type ExtensionSettingsHeaderProps = {
  selectedExtension: Extension;
  isValid: boolean;
  onSubmit: () => void;
  control: Control<ExtensionData>;
};

export const ExtensionSettingsHeader = ({
  selectedExtension,
  isValid,
  onSubmit,
  control,
}: ExtensionSettingsHeaderProps) => {
  const intl = useIntl();
  const { push, location } = useHistory<{
    backLink?: string;
  }>();

  const isFromStore = selectedExtension.extensionSource === rossumStore;
  const returnLink = location.state?.backLink || '/settings/extensions';

  return (
    <PaperBoxTitle
      handleOnBackButtonClick={(e: MouseEvent) => {
        e.preventDefault();
        push(returnLink);
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography sx={{ whiteSpace: 'nowrap', fontWeight: 600 }}>
          {intl.formatMessage({
            id: `containers.settings.extensions.title.${
              isFromStore ? 'fromStore' : selectedExtension.type
            }`,
          })}
        </Typography>
        {selectedExtension.type === extensionFunctionType && (
          <div className={styles.FunctionIcon}>
            {getIcon(
              get(selectedExtension, 'type'),
              get(selectedExtension, ['config', 'runtime']),
              { size: 21 }
            )}
          </div>
        )}
        {isFromStore && (
          <Chip
            label={intl.formatMessage({
              id: 'containers.settings.extensions.title.fromStore.tag',
            })}
            size="tiny"
            color="primary"
          />
        )}
      </Stack>

      {selectedExtension?.status === 'failed' ? (
        <Stack
          marginLeft="auto"
          spacing={1}
          direction="row"
          alignItems="center"
        >
          <RetryButton onButtonClick={onSubmit} disabled={!isValid} />
        </Stack>
      ) : selectedExtension?.status === 'pending' ? (
        <Stack
          ml={2}
          spacing={2}
          direction="row"
          alignItems="center"
          data-cy={`extensions-${selectedExtension.type}-pending-message`}
          color="text.secondary"
        >
          <Stack>
            <CircularProgress size={20} color="inherit" />
          </Stack>
          <Typography variant="subtitle2">
            {intl.formatMessage({
              id: `components.editor.infoMessage.pending.${selectedExtension.type}`,
            })}
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mr: 3 }}>
          <ControlledToggle<ExtensionData>
            dataCy="extensions-toggle"
            control={control}
            name="active"
          />
          <Typography
            variant="body2"
            color="text.disabled"
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {intl.formatMessage(
              {
                id: `containers.settings.extensions.activity.${
                  selectedExtension.active ? 'active' : 'inactive'
                }`,
              },
              { boldText, white }
            )}
          </Typography>
        </Stack>
      )}
    </PaperBoxTitle>
  );
};

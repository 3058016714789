import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import {
  DatasetsGuard,
  ExtensionLogsGuard,
  ExtensionsCreateGuard,
  ExtensionsGuard,
  LabelsGuard,
} from '../../components/Restrictors';
import { DatasetsRoutes } from '../../features/datasets';
import { datasetsPath } from '../../features/datasets/routes';
import { Logs } from '../../features/extensions/logs';
import { fieldManagerPath } from '../../features/field-manager/constants';
import { FieldManagerRoutes } from '../../features/field-manager/field-manager';
import { labelsPath } from '../../features/labels/components/constants';
import { LabelList } from '../../features/labels/components/LabelList';
import { ruleTemplatesPath } from '../../features/rule-templates/paths';
import { RuleTemplatesRoute } from '../../features/rule-templates/RuleTemplatesRoutes';
import {
  enterCreateExtension,
  enterExtension,
  enterExtensionEditor,
  enterExtensionsList,
  leaveExtension,
  leaveExtensionEditor,
} from '../../redux/modules/ui/actions';
import CustomRoute from '../../routes/Route';
import { automationPath } from '../Automation/helpers';
import ExtensionEditor from '../Extension/containers/ExtensionEditor';
import ExtensionSettings from '../Extension/containers/ExtensionSettings';
import Extensions from '../Extensions';
import CreateExtension from '../Extensions/containers/CreateExtension';
import ExtensionsStore from '../Extensions/containers/Store';
import { usersPath } from '../Users/helpers';
import { UsersRoutes } from '../Users/UsersRoutes';
import Settings from './';
import { settingsPath } from './helpers';
import SettingsPage from './SettingsPage';

export const SettingsRoutes = (props: RouteChildrenProps) => {
  const dispatch = useDispatch();
  const { match: settingsRouteMatch, ...settingsRouteProps } = props;

  if (!settingsRouteMatch) {
    return null;
  }

  return (
    <Switch>
      <Route path={settingsPath()} exact component={SettingsPage} />
      <Route path={usersPath()} component={UsersRoutes} />
      <Route path={fieldManagerPath()} component={FieldManagerRoutes} />
      <Route
        path={ruleTemplatesPath()}
        component={RuleTemplatesRoute}
        exact={false}
      />
      <Route
        path={labelsPath()}
        render={() => (
          <LabelsGuard>
            <LabelList />
          </LabelsGuard>
        )}
      />
      <Route
        path={datasetsPath()}
        render={() => (
          <DatasetsGuard>
            <DatasetsRoutes />
          </DatasetsGuard>
        )}
      />
      <CustomRoute
        path={`${settingsRouteMatch.path}/extensions/:extensionId/code`}
        component={ExtensionEditor}
        onEnter={() => dispatch(enterExtensionEditor())}
        onExit={() => dispatch(leaveExtensionEditor())}
      />
      <Settings {...settingsRouteProps} match={settingsRouteMatch}>
        {({ selectedExtension }) => (
          <Switch>
            <Route
              path={`${settingsRouteMatch.path}/automation`}
              render={({ location }) => (
                <Redirect
                  to={location.pathname.replace(
                    `${settingsRouteMatch.path}/automation`,
                    automationPath()
                  )}
                />
              )}
            />
            <CustomRoute
              exact
              onEnter={() => dispatch(enterCreateExtension())}
              path={`${settingsRouteMatch.path}/extensions/create`}
            >
              <ExtensionsCreateGuard>
                <CreateExtension />
              </ExtensionsCreateGuard>
            </CustomRoute>
            <Route
              exact
              component={ExtensionsStore}
              path={`${settingsRouteMatch.path}/store`}
            />
            <CustomRoute
              path={`${settingsRouteMatch.path}/extensions`}
              exact
              onEnter={() => dispatch(enterExtensionsList())}
            >
              <ExtensionsGuard>
                <Extensions />
              </ExtensionsGuard>
            </CustomRoute>
            <Route path={`${settingsRouteMatch.path}/extensions/logs`} exact>
              <ExtensionLogsGuard>
                <Logs />
              </ExtensionLogsGuard>
            </Route>
            <CustomRoute
              onEnter={() => dispatch(enterExtension())}
              onExit={() => dispatch(leaveExtension())}
              path={`${settingsRouteMatch.path}/extensions/:extensionId`}
            >
              {({ match: extensionRouteMatch }: RouteChildrenProps) =>
                extensionRouteMatch !== null ? (
                  <Switch>
                    <Route path={`${extensionRouteMatch.path}`}>
                      {selectedExtension ? (
                        <ExtensionSettings
                          selectedExtension={selectedExtension}
                          url={`${extensionRouteMatch.url}`}
                        />
                      ) : null}
                    </Route>
                    <Route
                      render={() => (
                        <Redirect to={`${extensionRouteMatch.path}`} />
                      )}
                    />
                  </Switch>
                ) : null
              }
            </CustomRoute>
            <Route render={() => <Redirect to="/documents" />} />
          </Switch>
        )}
      </Settings>
    </Switch>
  );
};

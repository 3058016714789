import { alpha, Badge, Button, ButtonGroup } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { ViewOptions } from '../hooks/useDashboardQuery';

type Props = {
  view: ViewOptions;
  isVisible: boolean;
  setView: (view: ViewOptions) => void;
  newEmailsExist: boolean;
};

const ViewSwitch = ({ view, isVisible, setView, newEmailsExist }: Props) => {
  const intl = useIntl();

  if (!isVisible) {
    return null;
  }

  return (
    <ButtonGroup color="secondary" sx={{ ml: 'auto', height: 1 }}>
      <Button
        variant="outlined"
        onClick={() => setView('documents')}
        aria-selected={view === 'documents' ? 'true' : undefined}
        data-cy="switch-documents"
        sx={{
          '&, &:hover': {
            backgroundColor: t =>
              view === 'documents'
                ? alpha(t.palette.text.primary, 0.16)
                : 'transparent',
          },
        }}
      >
        {intl.formatMessage({
          id: 'containers.settings.queues.viewSwitch.documents',
        })}
      </Button>
      <Button
        variant="outlined"
        onClick={() => setView('emails')}
        aria-selected={view === 'emails' ? 'true' : undefined}
        data-cy="switch-emails"
        sx={{
          '&, &:hover': {
            backgroundColor: t =>
              view === 'emails'
                ? alpha(t.palette.text.primary, 0.16)
                : 'transparent',
          },
        }}
      >
        <Badge
          color="primary"
          variant="dot"
          invisible={!newEmailsExist}
          slotProps={{
            badge: {
              // @ts-expect-error
              sx: {
                top: 2,
                right: -5,
              },
            },
          }}
        >
          {intl.formatMessage({
            id: 'containers.settings.queues.viewSwitch.emails',
          })}
        </Badge>
      </Button>
    </ButtonGroup>
  );
};

export { ViewSwitch };

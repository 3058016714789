import { LinearProgress } from '@rossum/ui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BeamerProvider } from '../../beamer/BeamerProvider';
import GlobalErrorBoundary from '../../components/errorBoundaries/global/GlobalErrorBoundary';
import ObservableProvider from '../../components/ObservableProvider';
import { REACT_QUERY_DEVTOOLS_ENABLED } from '../../constants/config';
import { QuickSearchContextProvider } from '../../features/quick-search/QuickSearchContext';
import { ToasterContainer } from '../../features/toaster';
import { queryClient } from '../../lib/queryClient';
import store from '../../redux/configureStore';
import Router from '../../routes';
import RuiThemeProvider from '../../RuiThemeProvider';
import UnleashProvider from '../../unleash/Unleash';
import ConnectedIntlProvider from '../ConnectedIntlProvider';
import { EasterEggProvider } from '../EasterEggProvider';
import { LoginAnimationContextProvider } from '../Login/LoginAnimationContext';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ObservableProvider>
      <Provider store={store}>
        <Suspense fallback={<LinearProgress />}>
          <UnleashProvider>
            <EasterEggProvider>
              <RuiThemeProvider>
                <LoginAnimationContextProvider>
                  <ConnectedIntlProvider>
                    <GlobalErrorBoundary>
                      <BeamerProvider>
                        <QuickSearchContextProvider>
                          <ToasterContainer />
                          <Router />
                        </QuickSearchContextProvider>
                      </BeamerProvider>
                    </GlobalErrorBoundary>
                    {REACT_QUERY_DEVTOOLS_ENABLED && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </ConnectedIntlProvider>
                </LoginAnimationContextProvider>
              </RuiThemeProvider>
            </EasterEggProvider>
          </UnleashProvider>
        </Suspense>
      </Provider>
    </ObservableProvider>
  </QueryClientProvider>
);

export default App;

import {
  Link,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';

type MenuItemProps = {
  href: string | undefined;
  label: string;
  Icon: React.ComponentType;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  rel?: string;
  dataCy?: string;
};

export const MenuLinkItem = ({
  href,
  onClick,
  label,
  Icon,
  rel,
  dataCy,
}: MenuItemProps) => (
  <MenuItem sx={{ p: 0 }}>
    <Link
      target="_blank"
      rel={rel}
      href={href}
      onClick={onClick}
      data-cy={dataCy}
      sx={{
        px: 2,
        py: 1,
        width: '100%',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
        },
      }}
    >
      <Stack
        direction="row"
        gap={1.5}
        component={Typography}
        variant="body2"
        color="text.primary"
      >
        <SvgIcon
          sx={{ fill: 'none', color: t => t.palette.text.disabled }}
          fontSize="small"
          component={Icon}
        />
        {label}
      </Stack>
    </Link>
  </MenuItem>
);

import { produce } from 'immer';
import { findIndex } from 'lodash';
import { Reducer } from 'redux';
import * as R from 'remeda';
import { unique } from 'remeda';
import { getType } from 'typesafe-actions';
import { Workspace } from '../../../types/workspace';
import { Workspaces } from '../../../types/workspaces';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, logoutUser } from '../auth/actions';
import { createQueueFulfilled, deleteQueueFulfilled } from '../queues/actions';
import { initialPagination } from '../utils';
import {
  createWorkspaceFulfilled,
  deleteWorkspaceFulfilled,
  fetchWorkspaceFulfilled,
  fetchWorkspacesFulfilled,
  updateWorkspace,
} from './actions';

const initialState: Workspaces = {
  list: [],
  pagination: initialPagination,
  isLoaded: false,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchWorkspacesFulfilled): {
      const { results } = action.payload;

      const updatedWorkspaces = state.list.map(workspace => ({
        ...workspace,
        ...R.find(results, ws => ws.id === workspace.id),
      }));

      const newList = R.uniqueBy(
        [...updatedWorkspaces, ...results],
        ws => ws.id
      );

      return {
        ...state,
        list: newList,
        pagination: action.payload.pagination,
        isLoaded: true,
      };
    }

    case getType(fetchWorkspaceFulfilled): {
      const updatedWorkspaces = state.list.map(workspace =>
        action.payload.id === workspace.id
          ? { ...workspace, ...action.payload }
          : workspace
      );
      const newList = R.uniqueBy(
        [...updatedWorkspaces, action.payload],
        ws => ws.id
      );

      return { ...state, list: newList };
    }

    case getType(createWorkspaceFulfilled):
      return { ...state, list: [...state.list, action.payload] };

    case getType(deleteWorkspaceFulfilled):
      return {
        ...state,
        list: state.list.filter(
          workspace => workspace.id !== action.payload.id
        ),
      };

    case getType(deleteQueueFulfilled):
      return {
        ...state,
        list: state.list.map(workspace => ({
          ...workspace,
          queues: workspace.queues.filter(
            queue => !queue.endsWith(`/${action.payload.id}`)
          ),
        })),
      };

    case getType(createQueueFulfilled): {
      const { workspace, url } = action.payload;

      const workspaceIndex = state.list.findIndex(ws => ws.url === workspace);

      if (workspaceIndex > -1) {
        return produce(state, draft => {
          draft.list[workspaceIndex].queues = unique([
            ...draft.list[workspaceIndex].queues,
            url,
          ]);
        });
      }

      return state;
    }

    case getType(updateWorkspace): {
      const { id } = action.meta;
      const index = findIndex(state.list, { id });

      // TODO what if index is -1 ?
      return produce(state, draft => {
        // TODO: Is the cast avoidable with `mergeDeep`?
        draft.list[index] = R.mergeDeep(
          draft.list[index],
          action.payload
        ) as Workspace;
      });
    }

    case getType(logoutUser):
    case getType(fetchMembershipTokenFulfilled):
      return initialState;

    default:
      return state;
  }
};

export default reducer;

import { Stack } from '@rossum/ui/material';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useShallow } from 'zustand/react/shallow';
import { SIDEBAR_WIDTH } from '../../constants/values';
import { MIN_FOOTER_HEIGHT } from '../../decorators/makeResizable/config';
import { DocumentCanvas } from '../../features/annotation-view/document-canvas-svg/DocumentCanvas';
import { useCanvasDimensions } from '../../features/annotation-view/document-canvas-svg/useCanvasDimensions';
import { DocumentSidebar } from '../../features/annotation-view/document-sidebar/DocumentSidebar';
import {
  useCanvasGeometryActions,
  useDocumentStore,
} from '../../features/annotation-view/document-store/DocumentStore';
import { visiblePageZustandSelector } from '../../features/annotation-view/document-store/documentStoreSelectors';
import { SearchPanel } from '../../features/annotation-view/document-topbar/SearchPanel';
import { TopBarV2 } from '../../features/annotation-view/document-topbar/TopBarV2';
import { useTranslationEnabled } from '../../features/annotation-view/document-translation/hooks/useTranslationEnabled';
import {
  annotationSelector,
  pagesSelector,
} from '../../redux/modules/annotation/selectors';
import { State } from '../../types/state';
import { useFeatureFlag } from '../../unleash/useFeatureFlag';
import { usePreventSwipeNavigation } from '../../utils/hooks/usePreventSwipeNavigation';
import { DrawerConfig } from '../DocumentValidation/ValidationEmailDrawer';
import Footer from '../Footer/Footer';
import {
  getFittingHeightSelector,
  showFooterSelector,
} from '../Footer/selectors';
import DocumentToolbar from './components/DocumentToolbar';
import TopBar from './components/TopBar';
import styles from './style.module.sass';

const TOOLBAR_ZOOM_FACTOR = 0.2;

type DocumentV2Props = {
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
};

export const DocumentV2 = ({ onEmailThreadOpen }: DocumentV2Props) => {
  const initialHeight = useSelector(getFittingHeightSelector);

  const [footerHeight, setFooterHeight] = useState(initialHeight);

  const showFooter = useSelector(showFooterSelector);

  const pages = useSelector(pagesSelector);

  const dimensions = useCanvasDimensions(pages);

  const { zoomBy, resetZoom } = useCanvasGeometryActions();

  const { pageNumber: visiblePageNumber } = useDocumentStore(
    useShallow(visiblePageZustandSelector(dimensions))
  );
  const { targetLanguage } = useDocumentStore(state => state.translationState);

  const matrixActions = useCanvasGeometryActions();

  const nPages = pages.length;

  const showSearchPanel = useSelector(
    (state: State) => state.search.shouldShow
  );

  const navigateToPage = useCallback(() => {
    const isOnLastPage = nPages === visiblePageNumber;

    return isOnLastPage
      ? matrixActions.translateTo({ y: 0 })
      : matrixActions.translateBy({ x: 0, y: dimensions.canvas.height + 1 });
  }, [nPages, visiblePageNumber, matrixActions, dimensions.canvas]);

  const documentRef = useRef<HTMLDivElement>(null);

  usePreventSwipeNavigation();

  const isNewSidebarEnabled = useFeatureFlag('ac-5499-new-sidebar');
  const isTranslationEnabled = useTranslationEnabled();

  const annotation = useSelector(annotationSelector);

  const annotationLoaded = annotation && typeof annotation.id === 'number';

  return (
    <Stack
      sx={{
        height: '100%',
        width: isNewSidebarEnabled ? '100%' : `calc(100% - ${SIDEBAR_WIDTH}px)`,
      }}
    >
      {isNewSidebarEnabled ? (
        <TopBarV2 loading={!annotationLoaded} />
      ) : (
        <TopBar
          navigateToPage={navigateToPage}
          visiblePageNumber={visiblePageNumber}
          onEmailThreadOpen={onEmailThreadOpen}
        />
      )}
      <Stack
        direction="row"
        sx={{ height: 'calc(100% - 48px)', width: '100%', flex: '1 0 auto' }}
      >
        {isNewSidebarEnabled ? (
          <DocumentSidebar
            annotation={annotation}
            loading={!annotationLoaded}
            onEmailThreadOpen={onEmailThreadOpen}
          />
        ) : null}
        <Stack
          className={styles.DocumentWrapper}
          id="document"
          sx={{ ...(isNewSidebarEnabled ? { background: 'none' } : {}) }}
        >
          <SearchPanel
            open={showSearchPanel}
            shouldSearchTranslations={isTranslationEnabled && !!targetLanguage}
          />

          <div
            ref={documentRef}
            className={styles.WorkingArea}
            data-tourstep="dataCaptureProductTour-document"
          >
            <DocumentCanvas
              pages={pages}
              data-tourstep="dataCaptureProductTour-missing-bbox"
              dimensions={dimensions}
            />
            {!isNewSidebarEnabled ? (
              <DocumentToolbar
                footerHeight={footerHeight}
                increaseZoom={() => {
                  zoomBy(+TOOLBAR_ZOOM_FACTOR, undefined);
                }}
                decreaseZoom={() => {
                  zoomBy(-TOOLBAR_ZOOM_FACTOR, undefined);
                }}
                resetZoom={() => {
                  resetZoom();
                }}
              />
            ) : null}
          </div>
          {showFooter && (
            <Footer
              minHeight={MIN_FOOTER_HEIGHT}
              setHeight={setFooterHeight}
              height={initialHeight}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

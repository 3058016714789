import { ID } from '@rossum/api-client';
import { StateCreator } from 'zustand';
type SupportedDrawer = 'duplicates' | 'emails' | 'attachments' | 'activities';

type DocumentSidebarState = {
  messagesVisible: boolean;
  blockersVisible: boolean;
  topbarVisible: boolean;
  annotationDataVisible: boolean;
};

type TranslationState = {
  targetLanguage: string | undefined;
  searchValue: string;
};

export type DocumentInfoState = {
  currentDrawer: SupportedDrawer | undefined;
  annotationId: ID | undefined;
  sidebarState: DocumentSidebarState;
  translationState: TranslationState;
};

type DocumentTranslationActions = {
  setTranslationState: (state: Partial<TranslationState>) => void;
};

type DocumentSidebarActions = {
  toggleMessages: () => void;
  toggleBlockers: () => void;
  setTopbarVisibility: (visible: boolean) => void;
  toggleAnnotationDataVisible: () => void;
};

export type DocumentInfoActions = {
  closeDrawer: () => void;
  openDrawer: (params: { drawer: SupportedDrawer; annotationId: ID }) => void;
  sidebarActions: DocumentSidebarActions;
  translationActions: DocumentTranslationActions;
};

export type DocumentInfoStoreType = DocumentInfoState & DocumentInfoActions;

export const documentInfoStoreSlice: StateCreator<
  DocumentInfoStoreType,
  [],
  [],
  DocumentInfoStoreType
> = set => ({
  currentDrawer: undefined,
  annotationId: undefined,
  closeDrawer: () => {
    set({ currentDrawer: undefined, annotationId: undefined });
  },
  openDrawer: ({ drawer, annotationId }) => {
    set({ currentDrawer: drawer, annotationId });
  },
  sidebarState: {
    messagesVisible: false,
    blockersVisible: false,
    topbarVisible: true,
    annotationDataVisible: false,
  },
  sidebarActions: {
    toggleAnnotationDataVisible: () => {
      set(state => ({
        ...state,
        sidebarState: {
          ...state.sidebarState,
          annotationDataVisible: !state.sidebarState.annotationDataVisible,
        },
      }));
    },
    toggleMessages: () => {
      set(state => ({
        ...state,
        sidebarState: {
          ...state.sidebarState,
          messagesVisible: !state.sidebarState.messagesVisible,
        },
      }));
    },
    toggleBlockers: () => {
      set(state => ({
        ...state,
        sidebarState: {
          ...state.sidebarState,
          blockersVisible: !state.sidebarState.blockersVisible,
        },
      }));
    },
    setTopbarVisibility: visible => {
      set(state => ({
        ...state,
        sidebarState: {
          ...state.sidebarState,
          topbarVisible: visible,
        },
      }));
    },
  },
  translationActions: {
    setTranslationState: config =>
      set(state => ({
        translationState: { ...state.translationState, ...config },
      })),
  },
  translationState: {
    targetLanguage: undefined,
    searchValue: '',
  },
});

import { Box, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';

type SidebarItemLayout = {
  iconSlot?: ReactNode;
  labelSlot?: ReactNode;
  valueSlot?: ReactNode;
  decorationSlot?: ReactNode;
};

export const SidebarItemLayout = ({
  iconSlot,
  labelSlot,
  valueSlot,
  decorationSlot,
}: SidebarItemLayout) => {
  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <Box sx={{ flex: '0 0 20px', py: 1 }}>{iconSlot}</Box>
      <Box sx={{ flex: '0 0 180px', py: 1 }}>{labelSlot}</Box>
      {valueSlot ? (
        <Box sx={{ flex: '1 0 0px', pl: 2 }}>{valueSlot}</Box>
      ) : null}
      {decorationSlot ? (
        <Box
          sx={{
            flex: '0 0 24px',
            py: 1,
            marginLeft: valueSlot ? undefined : 'auto !important',
            marginRight: '5px !important',
          }}
        >
          {decorationSlot}
        </Box>
      ) : null}
    </Stack>
  );
};

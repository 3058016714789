import { CircularProgress, Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import OutputIcon from 'mdi-react/ExportIcon';
import InfoIcon from 'mdi-react/InformationIcon';
import { FormattedMessage } from 'react-intl';
import styles from '../style.module.sass';
import { TabNames } from '../types';

type Props = {
  hasError?: boolean;
  isRunning: boolean;
  activeTab: string;
  setActiveTab: (_resizingtab: TabNames) => void;
};

const OutputBoxMenu = ({
  activeTab,
  hasError,
  isRunning,
  setActiveTab,
}: Props) => (
  <div className={styles.InputBoxHeader}>
    <div className={styles.InputSection}>
      <div className={styles.BoxTitle}>
        <div className={styles.TitleIcon}>
          <OutputIcon size={20} />
        </div>
        <FormattedMessage id="components.editor.outputBox.title" />
        {
          // we might want to handle hasError a bit more user friendly - what if there was
          // an error but no response?
          // removed outputValue for this purpose but now it's a bit confusing to see 'Error' with no context
        }
        {!isRunning && hasError && (
          <div className={styles.OutputMessageError}>
            <InfoIcon size={20} />
            <div className={styles.OutputMessageText}>
              <FormattedMessage id="components.editor.outputBox.error" />
            </div>
          </div>
        )}
        {isRunning && (
          <Stack sx={{ mx: 2 }}>
            <CircularProgress size={20} color="inherit" />
          </Stack>
        )}
      </div>
    </div>
    <div className={styles.OutputTabs}>
      <div className={styles.ResponseTabs}>
        <div
          onClick={() => setActiveTab('response')}
          className={clsx(
            styles.ResponseTab,
            activeTab === 'response' && styles.ActiveTab
          )}
        >
          <FormattedMessage id="components.editor.outputBox.tabs.response" />
        </div>
        <div
          onClick={() => setActiveTab('log')}
          className={clsx(
            styles.ResponseTab,
            activeTab === 'log' && styles.ActiveTab
          )}
        >
          <FormattedMessage id="components.editor.outputBox.tabs.log" />
        </div>
      </div>
    </div>
  </div>
);

export default OutputBoxMenu;

import { getIDFromUrl } from '@rossum/api-client';
import { Box, Button, Chip, ListItemProps, Stack } from '@rossum/ui/material';
import { paperBackgroundMap } from '@rossum/ui/theme';
import deepEqual from 'fast-deep-equal/es6/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { datapointPathSelector } from '../../../../redux/modules/datapoints/selector';
import {
  sidebarFieldIdsEnabledSelector,
  sidebarHiddenFieldsVisibleSelector,
} from '../../../../redux/modules/user/selectors';
import { Annotation } from '../../../../types/annotation';
import { SidebarItemLabel } from '../shared/SidebarItemLabel';
import { SidebarItemLayout } from '../shared/SidebarItemLayout';
import {
  SidebarMultivalueChildFieldModel,
  SidebarMultivalueFieldModel,
} from '../useSidebarData';
import { MultivalueChildItem } from './MultivalueChildItem';
import { ItemContextActions } from './shared/ItemContextActions';
import { MagicContainer } from './shared/MagicContainer';
import { MessageIndicator } from './shared/MessageIndicator';
import { Messages } from './shared/Messages';
import { MultivalueInactiveChildren } from './shared/MultivalueInactiveChildren';
import { getFieldsSettingsPath } from './utils';

type MultivalueItemProps = Omit<ListItemProps, 'onChange' | 'onClick'> & {
  sidebarScrollableRef: HTMLDivElement | null;
  annotation: Annotation | undefined;
  item: SidebarMultivalueFieldModel;
  active: boolean;
  selected?: boolean;
  focused?: boolean;
  disabled: boolean;
  addValueActive: boolean;
  onClick: (datapointId: number) => void;
  onChange: (item: SidebarMultivalueChildFieldModel, newValue: string) => void;
  onAddChild: (parentIndex: number) => void;
  onDeleteChild: () => void;
  onDeleteAll: (parentIndex: number) => void;
};

const MultivalueItem = React.memo(
  ({
    sidebarScrollableRef,
    annotation,
    item,
    active,
    focused,
    selected,
    addValueActive,
    onClick,
    onChange,
    onAddChild,
    onDeleteChild,
    onDeleteAll,
    disabled,
    ...listItemProps
  }: MultivalueItemProps) => {
    const currentDatapointPath = useSelector(datapointPathSelector);

    const intl = useIntl();

    const handleClick = useCallback(() => {
      onClick(item.id);
    }, [item.id, onClick]);

    const handleSelectChild = useCallback(
      (childId: number) => onClick(childId),
      [onClick]
    );

    const hiddenFieldsVisible = useSelector(sidebarHiddenFieldsVisibleSelector);

    const isHidden = item.hidden && hiddenFieldsVisible;

    const isConfirmed = useMemo(() => {
      return item.validationSources.includes('human');
    }, [item.validationSources]);

    const iconVariant = useMemo(() => {
      if (isConfirmed) {
        return 'confirmed';
      }

      return 'default';
    }, [isConfirmed]);

    const iconColor = useMemo(() => {
      if (isConfirmed) {
        return 'success';
      }

      return 'inherit';
    }, [isConfirmed]);

    const isDisabled = disabled;

    const handleDeleteAllClick = useCallback(
      () => onDeleteAll(item.meta.datapointIndex),
      [item.meta.datapointIndex, onDeleteAll]
    );

    const handleAddClick = useCallback(
      () => onAddChild(item.meta.datapointIndex),
      [item.meta.datapointIndex, onAddChild]
    );

    const handleAddKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          onAddChild(item.meta.datapointIndex);
        }
      },
      [item.meta.datapointIndex, onAddChild]
    );

    const addButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      const button = addButtonRef.current;
      if (button) {
        if (addValueActive) {
          button.focus();
        } else {
          button.blur();
        }
      }
    }, [addValueActive]);

    const sidebarAdditionalInfo = useSelector(sidebarFieldIdsEnabledSelector);

    const queueId = annotation ? getIDFromUrl(annotation?.queue) : undefined;

    return (
      <>
        <MagicContainer
          active={active}
          focused={focused}
          selected={selected}
          hasMagicLine={false}
          disabled={isDisabled}
          sidebarScrollableRef={sidebarScrollableRef}
          onClick={active ? undefined : handleClick}
          {...listItemProps}
        >
          <Stack sx={{ width: '100%' }}>
            <SidebarItemLayout
              iconSlot={
                <ItemContextActions
                  item={item}
                  active={active}
                  disabled={isDisabled}
                  iconProps={{
                    type: 'multiValue',
                    variant: iconVariant,
                    color: iconColor,
                  }}
                  fieldSettingsPath={getFieldsSettingsPath(
                    queueId,
                    // multivalues use children schema id for field settings route
                    item.childrenSchemaId
                  )}
                  variant={isHidden ? 'secondary' : 'primary'}
                />
              }
              labelSlot={
                <SidebarItemLabel
                  label={item.label}
                  sublabel={sidebarAdditionalInfo ? item.schemaId : undefined}
                  variant={isHidden ? 'secondary' : 'primary'}
                />
              }
              valueSlot={
                <Box sx={{ py: 1 }}>
                  <Chip label={item.children.length} size="small" />
                </Box>
              }
              decorationSlot={
                item.messagesStats ? (
                  <MessageIndicator messagesStats={item.messagesStats} />
                ) : null
              }
            />
            {active ? <Messages messages={item.messages} /> : null}
            {active || (focused && !active) ? null : (
              <MultivalueInactiveChildren childItems={item.children} />
            )}
          </Stack>
        </MagicContainer>
        {focused ? (
          <>
            {item.children.map((child, i) => (
              <MultivalueChildItem
                key={child.id}
                active={currentDatapointPath.includes(child.id)}
                focused
                onChange={onChange}
                onClick={handleSelectChild}
                sidebarScrollableRef={sidebarScrollableRef}
                annotation={annotation}
                item={child}
                index={i}
                disabled={isDisabled}
              />
            ))}
            {isDisabled ? null : (
              <Stack
                direction="row-reverse"
                spacing={1}
                alignItems="center"
                sx={{
                  p: 1,
                  pl: 2,
                  // TODO: @ui put paper backgrounds in theme
                  backgroundColor: theme =>
                    paperBackgroundMap[theme.palette.mode][4],
                }}
              >
                <Button
                  ref={addButtonRef}
                  variant="contained"
                  onClick={handleAddClick}
                  onKeyDown={handleAddKeyDown}
                >
                  {intl.formatMessage({
                    id: 'components.sidebarv2.multivalue.add',
                  })}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={currentDatapointPath.length !== 3}
                  onClick={onDeleteChild}
                >
                  {intl.formatMessage({
                    id: 'components.sidebarv2.multivalue.delete',
                  })}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDeleteAllClick}
                >
                  {intl.formatMessage({
                    id: 'components.sidebarv2.multivalue.deleteAll',
                  })}
                </Button>
              </Stack>
            )}
          </>
        ) : null}
      </>
    );
  },
  (prev, next) => {
    return deepEqual(prev, next);
  }
);

MultivalueItem.displayName = 'MultivalueItem';

export { MultivalueItem };

import { produce } from 'immer';
import { findIndex } from 'lodash';
import { Reducer } from 'redux';
import * as R from 'remeda';
import { getType } from 'typesafe-actions';
import { User } from '../../../types/user';
import { Users } from '../../../types/users';
import { RootActionType } from '../../rootActions';
import { fetchMembershipTokenFulfilled, signOut } from '../auth/actions';
import { initialPagination } from '../utils';
import {
  clearUsers,
  deleteUserFulfilled,
  fetchActiveUsersCountFulfilled,
  fetchAdminsCountFulfilled,
  fetchAllUsersFulfilled,
  fetchUserDetailFulfilled,
  fetchUsersFulfilled,
  updateUserDetail,
} from './actions';

const initialState: Users = {
  list: [],
  pagination: initialPagination,
  activeUsersCount: undefined,
  adminsCount: undefined,
  loaded: false,
};

const reducer: Reducer<typeof initialState, RootActionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(fetchAllUsersFulfilled): {
      const {
        meta: { loaded },
        payload,
      } = action;

      return { ...state, list: [...state.list, ...payload], loaded };
    }
    case getType(fetchUsersFulfilled):
      return {
        ...state,
        pagination: action.payload.pagination,
        list: action.payload.results,
      };

    case getType(fetchAdminsCountFulfilled):
      return { ...state, adminsCount: action.payload };

    case getType(fetchUserDetailFulfilled):
      return { ...initialState, list: [action.payload] };

    case getType(updateUserDetail): {
      const { id } = action.meta;
      const index = findIndex(state.list, { id });

      // TODO what if index is -1?
      return produce(state, draft => {
        draft.list[index] = R.mergeDeep(
          draft.list[index],
          action.payload
        ) as User;
      });
    }

    case getType(fetchActiveUsersCountFulfilled):
      return { ...state, activeUsersCount: action.payload };

    case getType(fetchMembershipTokenFulfilled):
    case getType(signOut):
    case getType(clearUsers):
      return initialState;

    case getType(deleteUserFulfilled):
      return {
        ...state,
        list: state.list.filter(user => user.id !== action.payload.id),
      };

    default:
      return state;
  }
};

export default reducer;

import { TranslateAnnotationResponse } from '@rossum/api-client/annotations';
import { styled, Typography } from '@rossum/ui/material';
import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { clamp } from 'remeda';
import { currentDatapointSelector } from '../../../redux/modules/datapoints/selector';
import { isBoundedDatapoint } from '../../../redux/modules/datapoints/typedHelpers';
import { SearchResultBox } from '../document-canvas/components/SearchResultBox';
import {
  applyPadding,
  rectangleFromCoordinates,
} from '../document-canvas/utils/geometry';
import { useDocumentStore } from '../document-store/DocumentStore';
import { areBoxesIntersecting } from './utils';

type TranslationBoxesLayerProps = {
  translations: TranslateAnnotationResponse['results'][number]['items'];
};

const StyledRectangle = styled('rect')(() => ({
  fill: 'transparent',
  cursor: 'pointer',
}));

const StyledForeignObject = styled('foreignObject')(({ theme }) => ({
  overflow: 'visible',
  backgroundColor: theme.palette.common.white,
}));

export const TranslationBoxesLayer = memo(
  ({ translations }: TranslationBoxesLayerProps) => {
    const [hiddenElementPosition, setHiddenElementPosition] = useState<
      string | null
    >(null);

    const searchValue = useDocumentStore(
      state => state.translationState.searchValue
    );

    const activeDatapoint = useSelector(currentDatapointSelector);

    const translationBoxes = useMemo(() => {
      const selectedPosition =
        activeDatapoint && isBoundedDatapoint(activeDatapoint)
          ? activeDatapoint.content.position
          : null;

      return translations.map(translation => {
        // remove translations underneath selection
        if (
          selectedPosition &&
          areBoxesIntersecting(translation.position, selectedPosition)
        )
          return null;
        const translationPosition = translation.position.join(',');
        if (translationPosition === hiddenElementPosition) return null;

        const rect = rectangleFromCoordinates(translation.position);
        const { x, y, width, height } = applyPadding(rect, 1);

        return (
          <React.Fragment key={translationPosition}>
            <StyledRectangle
              x={x}
              y={y}
              width={width}
              height={height}
              strokeWidth={2}
              rx={3}
              ry={3}
            />

            <StyledForeignObject
              x={x}
              y={y}
              width={width}
              height={height}
              onMouseMove={() => {
                setHiddenElementPosition(translation.position.join(','));
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1,
                  fontSize: clamp(height - 4, { min: 12 }),
                  backgroundColor: 'white',
                  whiteSpace: 'nowrap',
                  color: t => t.palette.common.black,
                  userSelect: 'none',
                  width: 'fit-content',
                }}
              >
                {translation.text}
              </Typography>
            </StyledForeignObject>
          </React.Fragment>
        );
      });
    }, [activeDatapoint, hiddenElementPosition, translations]);

    // this can be improved.
    // We can highlight only the searched values instead of the whole bounding box.
    // We can enable navigating through the results as well, but for now this will do.
    const translationSearchBoxes = useMemo(() => {
      if (!searchValue) return null;
      const matchingTranslations = translations.filter(({ text }) =>
        text.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      );
      return matchingTranslations.map(translation => {
        return (
          <SearchResultBox
            key={translation.position.join(',')}
            position={translation.position}
            current={false}
          />
        );
      });
    }, [searchValue, translations]);

    return (
      <>
        {translationBoxes}
        {translationSearchBoxes}
      </>
    );
  }
);

import { rossumStore } from '@rossum/api-client/hooks';
import { DeleteRounded, SettingsRounded } from '@rossum/ui/icons';
import { Error as ErrorIcon } from '@rossum/ui/icons';
import {
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Stack,
  Switch,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { debounce } from 'remeda';
import { usePatchHook } from '../../business/hooks/usePatchHook';
import { linebreak, white } from '../../lib/formaterValues';
import {
  deleteExtension,
  updateExtensionDetailFulfilled,
} from '../../redux/modules/extensions/actions';
import { openModal } from '../../redux/modules/modal/actions';
import { Url } from '../../types/basic';
import { Extension } from '../../types/extensions';
import { WorkspaceWithQueues } from '../../types/workspace';
import EmptyExtensionsList from './components/EmptyExtensionsList';
import EventsBadges from './components/EventsDropdown/components/EventsBadges';
import ExpandableWorkspaceBadges from './components/ExpandableWorkspaceBadges';
import FirstExtensionBanner from './components/FirstExtensionBanner';
import StoreAdBanner from './components/StoreAdBanner';
import { fullscreenConfigAppPath } from './containers/ConfigApp/helpers';
import { getIcon } from './lib/helpers';
import { NextLineWithIcon } from './lib/shared';

const deleteButtonClassName = 'extension-delete-buttons';

const clearLinkStyles: SxProps<Theme> = {
  color: 'unset',
  '&:hover': {
    color: 'unset',
    textDecoration: 'none',
  },
};
type Props = {
  extensions: Array<Extension>;
  extensionsLoaded: boolean;
  filtersActive: boolean;
  workspacesWithQueues?: WorkspaceWithQueues[];
};

type OpenDeleteModal = (values: {
  url: Url;
  name: string;
  queues: Array<Url>;
}) => void;

const ExtensionsList = ({
  extensions,
  extensionsLoaded,
  filtersActive,
  workspacesWithQueues,
}: Props) => {
  const intl = useIntl();
  const { mutate } = usePatchHook();
  const dispatch = useDispatch();
  const history = useHistory();

  const openDeleteModal: OpenDeleteModal = ({ url, name, queues }) =>
    dispatch(
      openModal({
        textId: 'extensionDelete',
        confirmAction: () => deleteExtension(url),
        confirmType: 'Danger',
        values: {
          white,
          name,
          queuesCount: queues.length,
          nextLine: NextLineWithIcon,
        },
      })
    );

  const debouncedUpdate = useMemo(
    () => debounce(mutate, { waitMs: 300 }),
    [mutate]
  );

  if (extensions.length === 0 && extensionsLoaded) {
    return filtersActive ? (
      <EmptyExtensionsList />
    ) : (
      <>
        <StoreAdBanner />
        <FirstExtensionBanner dataCy="extensions-intro-create-banner" />
      </>
    );
  }

  return (
    <Stack spacing={3}>
      <List disablePadding sx={{ whiteSpace: 'nowrap' }}>
        {extensions.map(extension => {
          const {
            type,
            queues,
            events,
            id,
            active,
            name,
            status,
            url,
            description,
            extensionSource,
            config,
          } = extension;
          return (
            <ListItem
              key={id}
              disablePadding
              sx={{
                px: 1,
                '&:hover, &:focus-within': {
                  backgroundColor: 'background.paper',
                  [`& .${deleteButtonClassName}`]: {
                    visibility: 'visible',
                  },
                },
              }}
              alignItems="center"
              data-cy="extensions-table-row"
            >
              <Stack minWidth={50}>
                <Tooltip
                  placement="top"
                  disableInteractive
                  title={intl.formatMessage(
                    {
                      id: 'containers.settings.extensions.activityToggle',
                    },
                    { linebreak }
                  )}
                >
                  <Stack>
                    <Switch
                      disabled={status === 'failed' || status === 'pending'}
                      checked={active}
                      onChange={() => {
                        // we're optimistically updating the state to display a smoother UX. Otherwise the action to switch extensions takes a long time
                        dispatch(
                          updateExtensionDetailFulfilled({
                            ...extension,
                            active: !active,
                          })
                        );
                        debouncedUpdate.call({
                          hookId: id,
                          payload: { active: !active, type },
                        });
                      }}
                      size="small"
                      data-cy="extensions-list-toggle"
                    />
                  </Stack>
                </Tooltip>
              </Stack>
              <Stack
                direction="row"
                flex={1}
                alignItems="center"
                overflow="hidden"
              >
                <Stack
                  direction="row"
                  component={Link}
                  to={`/settings/extensions/${id}`}
                  alignItems="center"
                  sx={{
                    ...clearLinkStyles,
                    minHeight: 60,
                    // to make outline visible we add margin here, otherwise it gets hidden by overflow
                    m: '3px',
                  }}
                  flex="0 1 90%"
                  overflow="hidden"
                >
                  <Stack
                    flex="0 1 60%"
                    overflow="hidden"
                    // to enable text truncation based on flex width we must define a minWidth recursively to the typogrophy child
                    minWidth={0}
                    spacing={1.5}
                    direction="row"
                    sx={{
                      svg: { display: 'block', color: 'text.secondary' },
                    }}
                    alignItems="center"
                  >
                    {status === 'pending' ? (
                      <Tooltip
                        placement="top"
                        disableInteractive
                        title={intl.formatMessage({
                          id: `components.editor.infoMessage.pending.${type}`,
                        })}
                      >
                        <CircularProgress size={20} color="inherit" />
                      </Tooltip>
                    ) : (
                      getIcon(
                        type,
                        get(config, 'runtime'),
                        { size: 22 },
                        { disableInteractive: true }
                      )
                    )}
                    {status === 'failed' && (
                      <Tooltip
                        placement="top"
                        disableInteractive
                        title={intl.formatMessage({
                          id: 'containers.settings.extensions.retryTooltip',
                        })}
                      >
                        <ErrorIcon color="error" fontSize="small" />
                      </Tooltip>
                    )}
                    <Stack flex={1} minWidth={0} maxWidth={600}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          data-cy="extensions-list-name"
                          variant="body2"
                          fontWeight="bold"
                        >
                          {name}
                        </Typography>
                        {extensionSource === rossumStore && (
                          <Chip
                            size="tiny"
                            label={intl.formatMessage({
                              id: 'containers.settings.extensions.title.fromStore.tag',
                            })}
                          />
                        )}
                      </Stack>

                      {description && (
                        <Typography
                          variant="body2"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          sx={{ color: 'text.secondary', flex: 1 }}
                        >
                          {description}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <Stack flex="0 0 20%" overflow="hidden">
                    <EventsBadges id={id} events={events} />
                  </Stack>
                  <Stack flex="0 0 20%" overflow="hidden">
                    {workspacesWithQueues !== undefined ? (
                      queues?.length > 0 ? (
                        <ExpandableWorkspaceBadges
                          id="expandable-ws-qs-badges-extensions-list"
                          queueUrls={queues}
                          workspaces={workspacesWithQueues}
                          displayPlaceholder={false}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          color="text.disabled"
                          sx={{ ml: 1 }}
                        >
                          {intl.formatMessage(
                            {
                              id: 'containers.settings.extensions.queues',
                            },
                            { count: queues.length }
                          )}
                        </Typography>
                      )
                    ) : (
                      <Skeleton width={60} height="100%" />
                    )}
                  </Stack>
                </Stack>
                <Stack
                  flex="1 0 10%"
                  direction="row"
                  spacing={1}
                  flexDirection="row-reverse"
                >
                  <Tooltip
                    placement="top"
                    disableInteractive
                    title={intl.formatMessage({
                      id: 'containers.settings.extensions.actionButtons.delete',
                    })}
                  >
                    <IconButton
                      color="secondary"
                      onClick={() => openDeleteModal({ url, name, queues })}
                      className={deleteButtonClassName}
                      sx={{
                        visibility: 'hidden',
                      }}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </Tooltip>
                  {config.app?.displayMode === 'fullscreen' && (
                    <Tooltip
                      placement="top"
                      title={intl.formatMessage(
                        {
                          id: 'containers.settings.extensions.configApp.open',
                        },
                        { name }
                      )}
                    >
                      <IconButton
                        component={Link}
                        to={{
                          pathname: `/settings/extensions/${id}${fullscreenConfigAppPath}`,
                          state: { backLink: history.location.pathname },
                        }}
                        color="secondary"
                        sx={{
                          color: 'secondary.main',
                          '&:hover, &:focus': { color: 'secondary.main' },
                        }}
                      >
                        <SettingsRounded />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
            </ListItem>
          );
        })}
      </List>

      {extensionsLoaded && <StoreAdBanner withBackground={false} />}
    </Stack>
  );
};

export default ExtensionsList;

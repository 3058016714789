import { DescriptionOutlined } from '@rossum/ui/icons';
import { Chip } from '@rossum/ui/material';
import { GridRenderCellParams } from '@rossum/ui/x-data-grid-pro';
import { TransformedEmailThread } from '../helpers';

const CountsCell = ({
  row: emailThread,
}: GridRenderCellParams<TransformedEmailThread>) => {
  const {
    meta: { rootEmailRead, rejected },
    counts: { annotations, annotationsPurged },
  } = emailThread;

  const annotationCount = annotations - annotationsPurged;

  // we should alert the user in case the email has no data, has not been rejected, has not been read yet.
  // In theory `rejected` is not necessary any more, because you need to read the email before you can reject it.
  // but we still need to keep it because rootEmailRead was introduced later on
  // and there are existing emails with rootEmailRead = false (by default) even though they had been read.
  const shouldDisplayAlert = !annotations && !(rootEmailRead || rejected);

  const shouldDisplayChip = annotationCount || shouldDisplayAlert;

  if (!shouldDisplayChip) return null;

  return (
    <Chip
      size="small"
      label={annotationCount}
      color={shouldDisplayAlert ? 'error' : 'default'}
      icon={<DescriptionOutlined />}
    />
  );
};

export { CountsCell };

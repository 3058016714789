import {
  Chip,
  chipClasses,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { ForwardedRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import StatusIndicator from './StatusIndicator';
import { DisplayedStatus } from './types';

const CHIP_MAX_WIDTH = 160;

export type StatusChipProps = {
  status: DisplayedStatus;
  isOwned: boolean;
  modifier?: string;
  maxWidth?: number;
  tooltipIsHidden?: boolean;
};

const StatusChipWithoutTooltip = forwardRef(
  (
    {
      maxWidth,
      status,
      isOwned,
      modifier,
      ...rest
    }: Omit<StatusChipProps, 'tooltipIsHidden'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const intl = useIntl();
    return (
      <Chip
        // To display tooltip from parent
        {...rest}
        ref={ref}
        sx={{
          maxWidth: maxWidth ?? CHIP_MAX_WIDTH,
          cursor: 'default',
          [`.${chipClasses.label}`]: {
            px: 0.75,
          },
        }}
        size="small"
        label={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Stack minWidth={18} alignItems="center">
              <StatusIndicator status={status} isOwned={isOwned} />
            </Stack>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize={13}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                pr: 0.75,
                pt: 1 / 8,
              }}
            >
              {intl.formatMessage(
                {
                  id: `containers.annotationList.statuses.${status}`,
                },
                {
                  // TODO - Remove after localize update.
                  modifier:
                    modifier ??
                    intl.formatMessage({
                      id: 'containers.documents.unknownModifier',
                    }),
                }
              )}
            </Typography>
          </Stack>
        }
      />
    );
  }
);

const StatusChip = (props: StatusChipProps) => {
  const intl = useIntl();

  const { tooltipIsHidden, ...statusChipProps } = props;

  const tooltipTitle =
    statusChipProps.status === 'reviewing' && !tooltipIsHidden
      ? intl.formatMessage(
          {
            id: `containers.annotationList.statuses.reviewing.tooltip`,
          },
          {
            modifier:
              statusChipProps.modifier ??
              intl.formatMessage({
                id: 'containers.documents.unknownModifier',
              }),
          }
        )
      : null;

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <StatusChipWithoutTooltip {...statusChipProps} />
    </Tooltip>
  ) : (
    <StatusChipWithoutTooltip {...statusChipProps} />
  );
};

export default StatusChip;

import { DedicatedEngineStatus } from '@rossum/api-client/dedicatedEngines';
import { Chip, ChipProps } from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type EngineStatusChipProps = ChipProps & {
  // 'active' is a UI only status
  status: DedicatedEngineStatus | 'active';
};

const statusColorMap: Record<
  DedicatedEngineStatus | 'active',
  ChipProps['color']
> = {
  sample_review: 'warning',
  draft: 'primary',
  schema_review: 'warning',
  annotating_initial: 'primary',
  annotating_review: 'warning',
  annotating_training: 'primary',
  training_started: 'primary',
  training_finished: 'success',
  retraining: 'primary',
  active: 'success',
};

const EngineStatusChip = ({ status, ...rest }: EngineStatusChipProps) => {
  const intl = useIntl();

  return (
    <Chip
      size="tiny"
      variant="twoTone"
      label={intl.formatMessage({
        id: `components.engineStatusChip.${status}`,
      })}
      // Chips need to be adjusted in RUI, there is more refactoring to them (incl. updating MUI version)
      // so let's do it separately
      sx={{
        fontSize: theme => theme.typography.pxToRem(12),
        fontWeight: 'medium',
      }}
      color={statusColorMap[status]}
      {...rest}
    />
  );
};

export { EngineStatusChip };

import { Annotation } from '@rossum/api-client/annotations';
import { classes, DetailDrawer } from '@rossum/rossum-ui/DetailDrawer';
import { Stack } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UnpaidFeatureOverlay } from '../../pricing/components/UnpaidFeatureOverlay';
import { approvalWorkflowsFeatureSelector } from '../../pricing/selectors';
import { RequestDetailBodyActivity } from '../../workflows';

type WorkflowActivityDrawerProps = {
  annotation: Annotation;
  onClose: () => void;
};

export const WorkflowActivityDrawer = ({
  annotation,
  onClose,
}: WorkflowActivityDrawerProps) => {
  const intl = useIntl();

  const isWorkflowsPurchased = useSelector(approvalWorkflowsFeatureSelector);

  return (
    <DetailDrawer
      PaperProps={{ elevation: 2 }}
      open
      onClose={onClose}
      title={
        <Stack component="span" spacing={2} direction="row">
          {intl.formatMessage({
            id: 'components.workflowActivityDrawer.title',
          })}
        </Stack>
      }
      sx={{
        [`& .${classes.body}`]: {
          height: '100%',
        },
      }}
    >
      {isWorkflowsPurchased ? (
        <Stack p={2}>
          <RequestDetailBodyActivity annotation={annotation} />
        </Stack>
      ) : (
        <UnpaidFeatureOverlay
          title={intl.formatMessage({
            id: 'features.pricing.unpaidFeatureOverlay.title.approvalWorkflows',
          })}
          dataCy="unpaid-overlay-workflow-activity-drawer"
        />
      )}
    </DetailDrawer>
  );
};

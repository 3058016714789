import { IntlShape } from 'react-intl';
import { SidebarFooterActionsOrder } from '../../../features/annotation-view/document-sidebar/sidebar-footer/sidebar-footer-actions/utils';

export const sidebarAdditionalInfoTypes = [
  'sidebarAdditionalInfo.fieldIds',
  'sidebarAdditionalInfo.confidenceScores',
  'sidebarAdditionalInfo.hiddenFieldsVisible',
] as const;

export const annotationMethodTypes = [
  'annotationMethod.gridDesign',
  'annotationMethod.suggestions',
] as const;

export const showSuggestionsTypes = [
  'showSuggestions.automatically',
  'showSuggestions.onDemand',
] as const;

export const sortFooterColumnsTypes = [
  'sortColumns.automatically',
  'sortColumns.queueSettings',
] as const;

export const footerLayoutTypes = [
  'footerLayout.table-per-page',
  'footerLayout.one-table',
] as const;

export type ValueType = (
  | typeof sidebarAdditionalInfoTypes
  | typeof annotationMethodTypes
  | typeof showSuggestionsTypes
  | typeof sortFooterColumnsTypes
  | typeof footerLayoutTypes
)[number];

export type FormValues = {
  additionalInfo?: Array<(typeof sidebarAdditionalInfoTypes)[number]>;
  actionsOrder: SidebarFooterActionsOrder;
  annotationMethod: (typeof annotationMethodTypes)[number];
  showSuggestions: (typeof showSuggestionsTypes)[number];
  sortFooterColumns: (typeof sortFooterColumnsTypes)[number];
  footerLayout: (typeof footerLayoutTypes)[number];
};

export const getOption = <T extends ValueType>(intl: IntlShape, value: T) => ({
  title: intl.formatMessage({
    id: `components.lineItemsSettings.${value}.title`,
  }),
  description: intl.formatMessage({
    id: `components.lineItemsSettings.${value}.description`,
  }),
  value,
});

import { zodResolver } from '@hookform/resolvers/zod';
import { RuleTemplate } from '@rossum/api-client/rule-templates';
import { Url } from '@rossum/api-client/utils';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { Redirect, useHistory, useLocation } from 'react-router';
import { z } from 'zod';
import { useSchema } from '../../../business/schema/useSchema';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import { SettingsBreadcrumbs } from '../../../containers/Settings/SettingsBreadcrumbs';
import { boldText } from '../../../lib/formaterValues';
import { DeleteConfirmationDialog } from '../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import { Header } from '../../../ui/header/Header';
import { LeavingDialog } from '../../../ui/leaving-dialog/LeavingDialog';
import { useWorkspacesWithQueues } from '../../queues/hooks/useWorkspacesWithQueues';
import { InitialCopilot } from '../../rules/copilots/InitialCopilot';
import { useListRules } from '../../rules/hooks/useListRules';
import { notify } from '../../toaster';
import { RuleTemplateForm } from '../components/RuleTemplateForm';
import { TemplateDistribution } from '../components/TemplateDistribution';
import { useBulkUpdateRules } from '../hooks/useBulkUpdateRules';
import { useCreateRuleTemplate } from '../hooks/useCreateRuleTemplate';
import { useDefaultQueue } from '../hooks/useDefaultQueue';
import { useDeleteRuleTemplate } from '../hooks/useDeleteRuleTemplate';
import { useGetRuleTemplate } from '../hooks/useGetRuleTemplate';
import { usePatchRuleTemplate } from '../hooks/usePatchRuleTemplate';
import { RuleTemplateFormType } from '../types';

type RuleTemplatePageProps = {
  ruleTemplate?: RuleTemplate;
};

export const ruleTemplateFormSchema = (intl: IntlShape) =>
  z.object({
    name: z.string().min(
      1,
      intl.formatMessage({
        id: 'features.queueSettings.rules.error.form.fieldRequired',
      })
    ),
    description: z.string(),
    ruleActions: z.array(
      z.object({
        id: z.string(),
        enabled: z.boolean(),
        action: z.object({
          type: z.string(),
          payload: z.unknown(),
        }),
      })
    ),
    enabled: z.boolean(),
    triggerCondition: z
      .string()
      .min(
        1,
        intl.formatMessage({
          id: 'features.queueSettings.rules.error.form.fieldRequired',
        })
      )
      .max(
        500,
        intl.formatMessage({
          id: 'features.queueSettings.rules.error.form.formulaLength',
        })
      ),
  });

const useRuleTemplateForm = ({
  ruleTemplate,
}: {
  ruleTemplate?: RuleTemplate;
}) => {
  const intl = useIntl();
  return useForm<z.TypeOf<ReturnType<typeof ruleTemplateFormSchema>>>({
    defaultValues: ruleTemplate ?? {
      name: '',
      description: '',
      triggerCondition: '',
      enabled: true,
    },
    resolver: zodResolver(ruleTemplateFormSchema(intl)),
  });
};

export const RuleTemplatePage = ({ ruleTemplate }: RuleTemplatePageProps) => {
  const intl = useIntl();
  const { goBack, push, replace, location } = useHistory<{
    schemasToAdd?: string[];
  }>();

  const { hash } = useLocation();

  const [schemasToAdd, setSchemasToAdd] = useState<Url[]>(
    location.state?.schemasToAdd ?? []
  );

  const { mutate: patchRuleTemplate } = usePatchRuleTemplate();

  const { mutate: createRuleTemplate } = useCreateRuleTemplate();

  const { mutate: deleteRuleTemplate, isLoading: isDeleting } =
    useDeleteRuleTemplate();

  const { mutate: bulkUpdateRules } = useBulkUpdateRules();

  const formMethods = useRuleTemplateForm({ ruleTemplate });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false);

  // Get rules that belong to this template
  const { data: rules } = useListRules({
    ruleTemplate: ruleTemplate?.id ?? 0,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isSubmitSuccessful },
  } = formMethods;

  const onDeleteSuccess = useCallback(() => {
    const redirectUrl = `/settings/rule-templates`;

    notify.success({
      title: intl.formatMessage({
        id: 'containers.message.ruleTemplateDeleteSuccess.title',
      }),
    });

    return replace(redirectUrl);
  }, [replace, intl]);

  const onDeleteError = useCallback(
    () =>
      notify.error({
        title: intl.formatMessage({
          id: 'containers.message.ruleTemplateDeleteFailed.text',
        }),
        description: intl.formatMessage({
          id: 'containers.message.ruleTemplateDeleteFailed.title',
        }),
      }),
    [intl]
  );

  const buttons = [
    !ruleTemplate ? null : (
      <Button
        key="delete-rule-template-button"
        data-cy="delete-rule-template-button"
        variant="outlined"
        color="secondary"
        startIcon={isDeleting && <CircularProgress color="inherit" size={20} />}
        disabled={isDeleting}
        onClick={() => setDeleteDialogOpen(true)}
      >
        {intl.formatMessage({
          id: 'features.queueSettings.rules.detail.delete',
        })}
      </Button>
    ),
    <Button
      key="save-rule-template-button"
      data-cy="save-rule-template-button"
      variant="contained"
      type="submit"
    >
      {intl.formatMessage({
        id: 'features.queueSettings.rules.detail.save',
      })}
    </Button>,
  ];

  const onSubmit = async (formValues: RuleTemplateFormType) => {
    return ruleTemplate
      ? patchRuleTemplate(
          {
            id: ruleTemplate.id,
            patchedRuleTemplate: formValues,
          },
          {
            onSuccess: () => {
              if (saveTemplateDialogOpen) {
                setSaveTemplateDialogOpen(false);
              }
              notify.success({
                title: intl.formatMessage({
                  id: 'containers.message.ruleTemplateUpdateSuccess.title',
                }),
              });
            },
            onError: () =>
              notify.error({
                title: intl.formatMessage({
                  id: 'containers.message.ruleTemplateUpdateFailed.title',
                }),
                description: intl.formatMessage({
                  id: 'containers.message.ruleTemplateUpdateFailed.text',
                }),
              }),
          }
        )
      : createRuleTemplate(formValues, {
          onSuccess: response => {
            if (saveTemplateDialogOpen) {
              setSaveTemplateDialogOpen(false);
            }

            replace(`${response.id}/detail`, {
              schemasToAdd,
            });
            notify.success({
              title: intl.formatMessage({
                id: 'containers.message.ruleTemplateCreateSuccess.title',
              }),
            });
          },
          onError: () =>
            notify.error({
              title: intl.formatMessage({
                id: 'containers.message.ruleTemplateCreationFailed.title',
              }),
              description: intl.formatMessage({
                id: 'containers.message.ruleTemplateCreationFailed.text',
              }),
            }),
        });
  };

  useEffect(() => {
    // Reset form state when form is submitted (so that you don't see leaving dialog)
    // TODO: isSubmitSuccessful does not actually check success now at all.
    reset(undefined, { keepValues: true });
  }, [isSubmitSuccessful, reset]);

  // TODO: Now we can play with this to improve functionality
  const onBackButtonClicked = useCallback(() => {
    // If the queue settings page was opened in a new tab, there is no history stack to go back, so fallbacking to "/documents" path instead
    if (history.length > 1) {
      return goBack();
    }
    return push('/documents');
  }, [goBack, push]);

  const name = watch('name');
  const description = watch('description');
  const ruleTemplateDraft = watch();

  const rulesToSync = useMemo(
    () =>
      rules?.flatMap(rule =>
        rule.synchronizedFromTemplate === true ? [rule.url] : []
      ) ?? [],
    [rules]
  );

  const { isLoading: isLoadingQueues, queues } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const defaultQueue = useDefaultQueue({
    queues,
    schemasToAdd,
    rulesSchema: rules?.[0]?.schema,
  });

  const [queueUrl, setQueueUrl] = useState<Url | null>(
    defaultQueue?.url ?? null
  );

  const { data: schema } = useSchema(
    queues?.find(queue => queue.url === queueUrl)?.schema
  );

  const [initialCopilotOpen, setInitialCopilotOpen] = useState<boolean>(
    ruleTemplate === undefined && hash === '#copilot'
  );

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      height="100%"
      id="rule-template-form"
    >
      <PageLayoutV2
        renderHeader={params => (
          <Header
            scrollableDivRef={params.scrollableDivRef}
            onBackButtonClicked={onBackButtonClicked}
            buttons={buttons}
            title={name}
            description={description}
            breadcrumbs={
              <SettingsBreadcrumbs
                breadcrumbs={[
                  {
                    label: intl.formatMessage({
                      id: 'containers.settings.templates.header.title',
                    }),
                    to: '/settings/rule-templates',
                  },
                  ruleTemplate
                    ? {
                        label: ruleTemplate.name,
                      }
                    : {
                        label: intl.formatMessage({
                          id: 'features.ruleTemplates.detail.new',
                        }),
                      },
                ]}
              />
            }
          />
        )}
      >
        <Stack px={4} py={4} spacing={4}>
          {isLoadingQueues ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <InitialCopilot
                schemaContent={schema?.content ?? []}
                onSuccess={value => {
                  // keepDefaultValues ensures that isDirty is set properly for leaving dialog
                  reset(value, { keepDefaultValues: true });
                  replace({ hash: undefined });
                  setInitialCopilotOpen(false);
                }}
                onClose={() => {
                  replace({ hash: undefined });
                  setInitialCopilotOpen(false);
                }}
                open={initialCopilotOpen}
              />
              <FormProvider {...formMethods}>
                <RuleTemplateForm
                  control={control}
                  schema={schema}
                  queueUrl={queueUrl}
                  setQueueUrl={setQueueUrl}
                />
                <TemplateDistribution
                  rules={rules}
                  value={schemasToAdd}
                  onChange={setSchemasToAdd}
                  ruleTemplate={ruleTemplate}
                  ruleTemplateDraft={ruleTemplateDraft}
                  onDistribute={() =>
                    isDirty
                      ? setSaveTemplateDialogOpen(true)
                      : ruleTemplate &&
                        bulkUpdateRules(
                          {
                            template: ruleTemplate,
                            schemasToAdd,
                            rulesToSync,
                          },
                          {
                            onSuccess: () => {
                              setSchemasToAdd([]);
                              return notify.success({
                                title: intl.formatMessage({
                                  id: 'containers.message.ruleTemplateDistributionSuccess.title',
                                }),
                              });
                            },
                            onError: () =>
                              notify.error({
                                title: intl.formatMessage({
                                  id: 'containers.message.ruleTemplateDistributionFailed.title',
                                }),
                                description: intl.formatMessage({
                                  id: 'containers.message.ruleTemplateDistributionFailed.text',
                                }),
                              }),
                          }
                        )
                  }
                />
              </FormProvider>
              <LeavingDialog when={isDirty} />
              {ruleTemplate ? (
                <DeleteConfirmationDialog
                  title={intl.formatMessage({
                    id: 'features.ruleTemplates.detail.deleteDialog.title',
                  })}
                  description={intl.formatMessage(
                    {
                      id: 'features.ruleTemplates.detail.deleteDialog.text',
                    },
                    {
                      name,
                      bold: boldText,
                    }
                  )}
                  open={deleteDialogOpen}
                  onCancel={() => setDeleteDialogOpen(false)}
                  onConfirm={() => {
                    setDeleteDialogOpen(false);
                    deleteRuleTemplate(ruleTemplate.id, {
                      onSuccess: onDeleteSuccess,
                      onError: onDeleteError,
                    });
                  }}
                />
              ) : null}
              <Dialog
                data-cy="save-template-dialog"
                open={saveTemplateDialogOpen}
                onClose={() => setSaveTemplateDialogOpen(false)}
                sx={{ transition: 'smooth' }}
                PaperProps={{
                  sx: { width: 480, minHeight: 213, position: 'fixed' },
                  elevation: 2,
                }}
              >
                <>
                  <DialogTitle
                    title={intl.formatMessage({
                      id: 'features.ruleTemplates.distribution.saveTemplate.dialog.title',
                    })}
                    onClose={() => setSaveTemplateDialogOpen(false)}
                  />
                  {description ? (
                    <DialogContent sx={{ mx: 4, mt: 4, p: 0 }}>
                      <Typography variant="body2">
                        {intl.formatMessage({
                          id: 'features.ruleTemplates.distribution.saveTemplate.dialog.description',
                        })}
                      </Typography>
                    </DialogContent>
                  ) : null}
                  <DialogActions sx={{ pb: 4, pt: 2.5, mr: 4, pr: 0 }}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="secondary"
                      data-cy="save-template-dialog-cancel-btn"
                      onClick={() => setSaveTemplateDialogOpen(false)}
                    >
                      {intl.formatMessage({
                        id: 'features.ruleTemplates.distribution.saveTemplate.dialog.button.keepEditing.label',
                      })}
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      data-cy="save-template-dialog-confirm-btn"
                      form="rule-template-form"
                      type="submit"
                    >
                      {intl.formatMessage({
                        id: 'features.ruleTemplates.distribution.saveTemplate.dialog.button.save.label',
                      })}
                    </Button>
                  </DialogActions>
                </>
              </Dialog>
            </>
          )}
        </Stack>
      </PageLayoutV2>
    </Stack>
  );
};

export const RuleTemplatePageDetail = ({
  ruleTemplateId,
  parentPath,
}: {
  ruleTemplateId: number;
  parentPath: string;
}) => {
  const { data: ruleTemplate, isLoading } = useGetRuleTemplate(ruleTemplateId);

  return ruleTemplate ? (
    <RuleTemplatePage ruleTemplate={ruleTemplate} />
  ) : isLoading ? (
    <LinearProgress />
  ) : (
    <Redirect to={`${parentPath}/rule-templates`} />
  );
};

import { Box, Grid, Grow, Link, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ConditionalAuroraChip from '../../../components/conditional-aurora-chip/ConditionalAuroraChip';
import { useMountTransition } from '../../../components/UI/MountTransition/useMountTransition';
import { button, link } from '../../../lib/formaterValues/index';
import { snakeToCamel } from '../../../lib/keyConvertor';
import { isTrialSelector } from '../../../redux/modules/organization/selectors';
import { auroraEngineEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { useFeatureFlag } from '../../../unleash/useFeatureFlag';
import {
  customTrainingDocumentTypesGroups,
  DocumentType,
  OutOfTheBoxDocumentType,
  outOfTheBoxDocumentTypes,
} from './data';
import { DocumentTypeCard } from './DocumentTypeCard';
export const requestDemo = 'request-demo';

type SelectDocumentTypeStepProps = {
  onNext: (documentType: DocumentType['id'] | typeof requestDemo) => void;
  unmountOnSelection?: boolean;
  loadingDocumentType?: OutOfTheBoxDocumentType['id'] | '';
};

const SelectDocumentTypeStep = ({
  onNext,
  unmountOnSelection = true,
  loadingDocumentType,
}: SelectDocumentTypeStepProps) => {
  const [transitionProps, transitionOut] = useMountTransition();
  const intl = useIntl();

  const isTrial = useSelector(isTrialSelector);
  const auroraEngineEnabled = useSelector(auroraEngineEnabledSelector);

  const customDocumentTypeEnabled = useFeatureFlag(
    'ac-4343-forrester-demo-custom-document-type'
  );

  const filteredOutOfTheBoxDocumentTypes = outOfTheBoxDocumentTypes.filter(
    dt => customDocumentTypeEnabled || dt.id !== 'custom_document_type'
  );

  return (
    <Grow {...transitionProps}>
      <Stack spacing={6} alignItems="center">
        <Stack alignItems="center">
          {auroraEngineEnabled ? (
            <>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{ position: 'relative' }}
              >
                <Typography variant="h5">
                  {intl.formatMessage({
                    id: 'components.selectDocumentType.outOfTheBoxDocumentTypes.headline',
                  })}
                </Typography>
                <Box sx={{ position: 'absolute', left: '100%' }}>
                  <ConditionalAuroraChip size="small" />
                </Box>
              </Stack>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage({
                  id: 'components.selectDocumentType.outOfTheBoxDocumentTypes.subtitle',
                })}
              </Typography>
            </>
          ) : null}
          <div>
            <Stack
              direction="row"
              sx={{
                minWidth: '100%',
                alignItems: 'end',
                mt: 3,
              }}
            >
              <Grid container spacing={1}>
                {filteredOutOfTheBoxDocumentTypes.map(dt => (
                  <DocumentTypeCard
                    key={dt.id}
                    documentType={dt}
                    onClick={() =>
                      unmountOnSelection
                        ? transitionOut(() => onNext(dt.id))
                        : onNext(dt.id)
                    }
                    disabled={!!loadingDocumentType}
                    loading={loadingDocumentType === dt.id}
                    boldTitle
                    data-cy={`card-${dt.id}`}
                  />
                ))}
              </Grid>
            </Stack>
          </div>
        </Stack>
        <Stack alignItems="center" gap={2} pb={4}>
          {isTrial ? (
            <Stack alignItems="center">
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'components.selectDocumentType.customTraining.headline',
                })}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {intl.formatMessage(
                  {
                    id: 'components.selectDocumentType.customTraining.subtitle',
                  },
                  {
                    button: button(
                      () => onNext(requestDemo),
                      undefined,
                      requestDemo
                    ),
                  }
                )}
              </Typography>
              <Stack
                sx={{
                  mt: 3,
                  px: 3,
                  py: 4,
                  backgroundColor: 'background.default',
                  backgroundImage:
                    'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                  borderRadius: '4px',
                }}
              >
                <div>
                  <Grid container spacing={2}>
                    {customTrainingDocumentTypesGroups.map(dt => (
                      <Grid item key={dt.id} xs={4}>
                        <Link
                          component="button"
                          underline="hover"
                          disabled={!!loadingDocumentType}
                          onClick={() => onNext(dt.id)}
                          sx={{
                            color: 'text.secondary',
                            '&:hover': { color: 'text.primary' },
                          }}
                          data-cy={`button-${dt.id}`}
                        >
                          <Typography variant="body2">
                            {intl.formatMessage({
                              id: `components.selectDocumentType.documentTypeId.${snakeToCamel(
                                dt.id
                              )}`,
                            })}
                          </Typography>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Stack>
            </Stack>
          ) : null}
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage(
              {
                id: 'components.selectDocumentType.didntFindYourDocType',
              },
              {
                link: link(
                  'https://go.rossum.ai/contact',
                  undefined,
                  'did-not-find-doc-type-link'
                ),
              }
            )}
          </Typography>
        </Stack>
      </Stack>
    </Grow>
  );
};

export { SelectDocumentTypeStep };

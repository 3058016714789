import { yupResolver } from '@hookform/resolvers/yup';
import { DedicatedEngineSchema } from '@rossum/api-client/dedicatedEngineSchema';
import { Check } from '@rossum/ui/icons';
import { Button, Card, FormHelperText, Stack } from '@rossum/ui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import * as yup from 'yup';
import { HeaderFieldFormModel } from '../utils';
import { DatapointFieldControl } from './DatapointFieldControl';
const SourceSchema = (intl: IntlShape) =>
  yup.object({
    queue: yup.string().required(),
    // TODO: This is supposed to be optional but currently the UX around that is eh
    // so I'm keeping this after discussing with design
    schemaId: yup.string().required(
      intl.formatMessage({
        id: 'components.headerFieldForm.fieldErrors.sources.schemaId',
      })
    ),
  });

export const HeaderFieldFormSchema = (intl: IntlShape) =>
  yup.object({
    label: yup.string().required(
      intl.formatMessage({
        id: 'components.headerFieldForm.fieldErrors.label',
      })
    ),
    engineOutputId: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'components.headerFieldForm.fieldErrors.engineOutputId.required',
        })
      )
      .matches(
        /^[-a-zA-Z0-9_]+$/,
        intl.formatMessage({
          id: 'components.headerFieldForm.fieldErrors.engineOutputId.slug',
        })
      ),
    type: yup
      .string()
      .oneOf(
        ['number', 'string', 'date', 'enum'],
        intl.formatMessage({
          id: 'components.headerFieldForm.fieldErrors.type',
        })
      )
      .nullable(),
    description: yup.string(),
    sources: yup.array(SourceSchema(intl)),
    isMultivalue: yup.boolean().nullable(),
  });

type HeaderFieldFormProps = {
  engineSchema: DedicatedEngineSchema;
  index?: number;
  onSubmit: (value: HeaderFieldFormModel, index?: number) => void;
  onCancel?: () => void;
  defaultValues: HeaderFieldFormModel;
  // is form considered a control?
  disabled?: boolean;
  submissionError?: boolean;
};

// MAYBE: ?Add backend validation sometime? /validate endpoint could be used for that maybe
// errors already come back in pretty good format
const HeaderFieldForm = ({
  engineSchema,
  index,
  onSubmit,
  onCancel,
  defaultValues,
  disabled,
  submissionError,
}: HeaderFieldFormProps) => {
  const intl = useIntl();

  const { control, handleSubmit, formState } = useForm<HeaderFieldFormModel>({
    mode: 'onChange',
    resolver: yupResolver(HeaderFieldFormSchema(intl)),
    defaultValues,
  });

  const handleCancelOrDelete = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <form
      onSubmit={handleSubmit(values => onSubmit(values, index))}
      // to disable browser "helpers" with native input validation -_-
      noValidate
      id="header-field-form"
    >
      <Card sx={{ p: 5 }} elevation={2}>
        <Stack spacing={2} alignItems="center">
          {submissionError && (
            <FormHelperText error>
              {intl.formatMessage({ id: 'components.headerFieldForm.error' })}
            </FormHelperText>
          )}
          <DatapointFieldControl
            ControllerProps={{
              control,
              nameMap: {
                label: 'label',
                engineOutputId: 'engineOutputId',
                type: 'type',
                description: 'description',
                sources: {
                  __rootField: 'sources',
                  nameMap: (index: number) => ({
                    __rootField: `sources.${index}`,
                    schemaId: `sources.${index}.schemaId`,
                    queue: `sources.${index}.queue`,
                  }),
                },
                isMultivalue: 'isMultivalue',
              },
            }}
            disabled={disabled || formState.isSubmitting}
            trainingQueues={engineSchema.content.trainingQueues}
            fieldType="headerField"
          />
          <Stack
            // Trick to keep proper tabIndexes, Submit first, then Delete
            direction="row-reverse"
            justifyContent="flex-start"
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Button
              type="submit"
              variant="contained"
              startIcon={<Check />}
              disabled={disabled || formState.isSubmitting}
            >
              {intl.formatMessage({
                id: 'components.headerFieldForm.buttons.submit',
              })}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={disabled || formState.isSubmitting}
              onClick={handleCancelOrDelete}
            >
              {intl.formatMessage({
                id: 'components.headerFieldForm.buttons.cancel',
              })}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </form>
  );
};

export { HeaderFieldForm };

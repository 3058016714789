import { Chip, Stack, Tooltip } from '@rossum/ui/material';
import { useRef } from 'react';

const CHIP_MAX_WIDTH = 240;

type Props = {
  name: string;
};

const TruncatedLabelChip = ({ name }: Props) => {
  const chipEl = useRef<HTMLDivElement>(null);

  const shouldRenderTooltip = chipEl.current
    ? chipEl.current.clientWidth >= CHIP_MAX_WIDTH
    : false;

  return (
    <Tooltip
      placement="top"
      title={shouldRenderTooltip ? name : ''}
      enterDelay={600}
    >
      <Stack ref={chipEl} sx={{ maxWidth: CHIP_MAX_WIDTH }}>
        <Chip variant="outlined" size="small" label={name} />
      </Stack>
    </Tooltip>
  );
};

export { TruncatedLabelChip };

import { Query, QueryClient } from '@tanstack/react-query';
import { DashboardAnnotationListWithSideload } from './columns/helpers';
import { ALL_DOCUMENTS_QUERY_KEY } from './hooks/useFetchDashboardData';

// Returns all annotations that are currently observed by a an `ALL_DOCUMENTS_QUERY_KEY` query

export const getObservedAnnotations = (queryClient: QueryClient) => {
  return queryClient
    .getQueryCache()
    .findAll([ALL_DOCUMENTS_QUERY_KEY], { exact: false })
    .filter(query => query.isActive())
    .flatMap(
      query =>
        // no idea how to do this better
        (query as Query<DashboardAnnotationListWithSideload>).state.data
          ?.results ?? []
    );
};

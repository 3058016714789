import { config } from 'ace-builds';
import AceEditor from 'react-ace'; // MUST be the first import
import './style.sass';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/snippets/json?url';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import { useTheme } from '@rossum/ui/material';
import workerJsonUrl from 'ace-builds/src-min-noconflict/worker-json?url';
import workerPlainTextUrl from 'ace-builds/src-noconflict/mode-plain_text?url';
import clsx from 'clsx';
import { ChangeEvent, LegacyRef } from 'react';
import ReactAce, { IAceEditorProps } from 'react-ace/lib/ace';
import styles from '../styles.module.sass';

config.setModuleUrl('ace/mode/json_worker', workerJsonUrl);
config.setModuleUrl('ace/mode/plain_text', workerPlainTextUrl);

export type Props = {
  debounceChangePeriod: number;
  forwardRef?: LegacyRef<ReactAce>;
  height: number | `${number}%`;
  name: string;
  onChange?: (_value: string, _event?: ChangeEvent) => void;
  onValidate?: IAceEditorProps['onValidate'];
  value: string;
  readonly?: boolean;
  mode?: 'plain_text' | 'json';
};

const JsonEditor = ({
  debounceChangePeriod,
  forwardRef,
  height,
  name,
  onChange,
  onValidate,
  value,
  readonly = false,
  mode = 'json',
}: Props) => {
  const theme = useTheme();
  const isLightTheme = theme.palette.mode === 'light';

  return (
    <AceEditor
      className={clsx(
        styles.JsonEditor,
        // static class used for style overriding
        'extensions-json-editor'
      )}
      scrollMargin={[7, 7, 0, 0]}
      name={name}
      mode={mode}
      theme={isLightTheme ? 'tomorrow' : 'tomorrow_night'}
      width="100%"
      height={typeof height === 'number' ? `${height}px` : height}
      ref={forwardRef}
      onChange={onChange}
      fontSize={13}
      tabSize={2}
      debounceChangePeriod={debounceChangePeriod}
      highlightActiveLine
      value={value}
      editorProps={{
        $blockScrolling: Infinity,
      }}
      setOptions={{
        useWorker: true,
        hScrollBarAlwaysVisible: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        enableBasicAutocompletion: true,
      }}
      onValidate={onValidate}
      readOnly={readonly}
    />
  );
};

export default JsonEditor;

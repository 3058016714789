import { ArrowDropDown, ArrowDropUp } from '@rossum/ui/icons';
import { Button, ButtonProps, Typography } from '@rossum/ui/material';
import { ReactNode } from 'react';

type StatisticsFilterDropdownProps = {
  children: ReactNode;
  open: boolean;
} & ButtonProps;

const StatisticsFilterDropdown = ({
  children,
  open,
  ...ButtonProps
}: StatisticsFilterDropdownProps) => (
  <Typography color="text.white">
    <Button
      endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
      color={open ? 'primary' : 'inherit'}
      variant="outlined"
      size="medium"
      sx={{
        height: '40px',
        fontWeight: 'normal',
        fontSize: '13px',
        borderRadius: 3,
        borderColor: theme => theme.palette.divider,
      }}
      {...ButtonProps}
    >
      {children}
    </Button>
  </Typography>
);

export { StatisticsFilterDropdown };

import { Paper, Stack } from '@rossum/ui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { fromEntries } from 'remeda';
import { pagesSelector } from '../../redux/modules/annotation/selectors';
import { EditDocumentConfig, EditState } from './editState';
import { OriginalDocumentHeader } from './OriginalDocumentHeader';
import { OriginalDocumentPage } from './OriginalDocumentPage';
import { UiStateTuple } from './uiState';

export const OriginalDocument = ({
  uiState: [uiState, setUiState],
  config,
  editState,
}: {
  editState: EditState;
  uiState: UiStateTuple;
  config: EditDocumentConfig;
}) => {
  const pages = useSelector(pagesSelector);
  const editStateByUrl = useMemo(
    () =>
      fromEntries(
        editState.parts.flatMap(({ pages }) => pages.map(p => [p.url, p]))
      ),
    [editState]
  );

  return (
    <Paper
      elevation={6}
      sx={{
        height: '100%',
        overflowY: 'auto',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        background: theme =>
          theme.palette.mode === 'light'
            ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #F8F8F8'
            : undefined,
        boxShadow: theme =>
          theme.palette.mode === 'light'
            ? '0px 0px 10px rgba(81, 81, 81, 0.04), inset 0px 0px 0px 1px #EEEEEE'
            : undefined,
      }}
    >
      <Stack width="100%" spacing={4} sx={{ p: 4 }}>
        <OriginalDocumentHeader config={config} />
        {pages.map(page => (
          <OriginalDocumentPage
            key={page.number}
            pageNumber={page.number}
            isCurrent={page.number === uiState.currentPageNumber}
            onClick={() =>
              setUiState(s => ({ ...s, currentPageNumber: page.number }))
            }
            rotationDeg={editStateByUrl[page.url]?.rotationDeg ?? 0}
          />
        ))}
      </Stack>
    </Paper>
  );
};

import { getIDFromUrl, Url } from '@rossum/api-client';
import { CreateQueueFromTemplateQuery } from '@rossum/api-client/queues';
import {
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  Paper,
  Stack,
} from '@rossum/ui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useStepWorkflow } from '../../../components/UI/StepWorkflow/useStepWorkflow';
import CallToActionDialog from '../../../containers/CallToAction/CallToActionDialog';
import { auroraEngineConfigEnabledSelector } from '../../../redux/modules/organizationGroup/selectors';
import { State } from '../../../types/state';
import DialogTitle from '../../../ui/dialog-title/DialogTitle';
import { useWorkspacesWithQueues } from '../../queues/hooks/useWorkspacesWithQueues';
import { AddQueueStepper } from './AddQueueStepper';
import { DocumentType, isOutOfTheBoxDocType } from './data';
import { EngineSelectionForm } from './EngineSelectionForm';
import { QueueDetailForm } from './QueueDetailsForm';
import { requestDemo, SelectDocumentTypeStep } from './SelectDocumentTypeStep';
import { useCreateQueueAndWorkspace } from './useCreateQueueAndWorkspace';
import {
  dialogWidthDefault,
  getTemplateConfig,
  QueueDetailFormModel,
} from './utils';

const queueDetailFormDefaultValues: QueueDetailFormModel = {
  documentType: 'tax_invoice',
  queueName: '',
  addingNewWorkspace: false,
  workspaceId: 0,
  workspaceUrl: '',
  newWorkspaceName: '',
  includeDocuments: true,
  // TODO: Select default based on locale?
  region: '',
};

type AddQueueDialogProps = DialogProps & {
  onSuccess: (queueId: number) => void;
  workspaceUrl?: Url;
  currentQueueId?: number;
};

const AddQueueDialog = ({
  workspaceUrl,
  onSuccess,
  currentQueueId,
  ...dialogProps
}: AddQueueDialogProps) => {
  const intl = useIntl();
  const auroraConfigEnabled = useSelector(auroraEngineConfigEnabledSelector);

  const { state, goToStep, unComplete, complete } = useStepWorkflow({
    steps: [
      {
        key: 'select-document-type',
        label: intl.formatMessage({
          id: 'components.addQueueDialog.step.documentType',
        }),
      },
      {
        key: 'choose-queue-name',
        label: intl.formatMessage({
          id: auroraConfigEnabled
            ? 'components.addQueueDialog.step.basicInformation'
            : 'components.addQueueDialog.step.queueDetail',
        }),
      },
      ...(auroraConfigEnabled
        ? [
            {
              key: 'choose-engine',
              label: intl.formatMessage({
                id: 'components.addQueueDialog.step.engineDetail',
              }),
            },
          ]
        : []),
    ],
  });

  const { activeStep, steps } = state;

  // Step 1 state
  const [selectedType, setSelectedType] = useState<
    DocumentType['id'] | typeof requestDemo | null
  >(null);

  const { allWorkspacesWithQueues: workspaces } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  // opening the dialog from the no-workspace specified place, but some queue is active
  const currentQueueWorkspaceUrl = currentQueueId
    ? workspaces?.flatMap(ws =>
        ws.queues.some(({ id }) => id === currentQueueId) ? ws : []
      )[0]?.url
    : undefined;

  const currentWorkspaceUrl = workspaceUrl || currentQueueWorkspaceUrl || '';

  // Step 2 - create queue state
  const [queueDetailFormState, setQueueDetailFormState] =
    useState<QueueDetailFormModel>({
      ...queueDetailFormDefaultValues,
      includeDocuments: false,
      workspaceUrl: currentWorkspaceUrl,
      workspaceId: getIDFromUrl(currentWorkspaceUrl),
    });

  const handleDialogClose = useCallback(() => {
    dialogProps.onClose?.({}, 'backdropClick');
  }, [dialogProps]);

  const isOutOfTheBoxType =
    selectedType !== requestDemo &&
    selectedType !== null &&
    isOutOfTheBoxDocType(selectedType);

  const requestDemoDialogOpen = selectedType !== null && !isOutOfTheBoxType;

  const onboardingSurvey = useSelector(
    (state: State) => state.user.uiSettings.onboardingSurvey?.stepsData
  );

  const ctaLocation = 'new-queue-selection';

  const { submitForm, mutationLoading } = useCreateQueueAndWorkspace({
    onSuccess: queueId => {
      handleDialogClose();
      onSuccess(queueId);
    },
  });

  const getDialogDimensions = () => {
    if (activeStep === 0) {
      return { width: dialogWidthDefault, height: 620 };
    }

    if (activeStep === 2) {
      return { width: 648, height: 550 };
    }

    if (auroraConfigEnabled) {
      return {
        width: 648,
        height: selectedType === 'tax_invoice' ? 525 : 460,
      };
    }

    return { width: 450, height: selectedType === 'tax_invoice' ? 470 : 380 };
  };

  const getCreateQueueQueryParamsFromEngineValues = (engineValues: {
    engine?: string;
  }): CreateQueueFromTemplateQuery => {
    if (engineValues.engine === 'legacyEngine') {
      return { legacy: true };
    }

    if (engineValues.engine === 'newEngine') {
      return { legacy: false };
    }

    return {};
  };

  const getEngineUrlFromEngineValues = (engineValues: { engine?: string }) => {
    if (
      engineValues.engine === 'legacyEngine' ||
      engineValues.engine === 'newEngine'
    ) {
      return undefined;
    }

    return engineValues.engine;
  };

  return (
    <Dialog
      PaperProps={{
        elevation: 2,
        sx: {
          ...getDialogDimensions(),
          transition: theme =>
            theme.transitions.create(['width', 'height'], { duration: 300 }),
        },
      }}
      maxWidth={false}
      {...dialogProps}
      data-cy="add-queue-modal"
    >
      <Paper
        elevation={10}
        sx={{
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Stack divider={<Divider />}>
          <DialogTitle
            title={intl.formatMessage({
              id: 'components.addQueueDialog.title',
            })}
            onClose={handleDialogClose}
          />
          <AddQueueStepper
            activeStep={activeStep}
            steps={steps}
            onChange={index => {
              goToStep(index);
              unComplete(index);
            }}
          />
        </Stack>
      </Paper>
      <DialogContent>
        <CallToActionDialog
          open={requestDemoDialogOpen}
          onClose={() => {
            setSelectedType(null);
          }}
          key={`request-demo-${requestDemoDialogOpen}`}
          extraInfoPayload={
            selectedType === requestDemo
              ? undefined
              : `Request document type: ${selectedType}; Initial survey data: ${JSON.stringify(
                  onboardingSurvey
                )}; Request location: ${ctaLocation}`
          }
          ctaLocation={ctaLocation}
          selectedDocType={
            selectedType === requestDemo ? undefined : selectedType
          }
        />

        {activeStep === 0 ? (
          <Stack sx={{ pt: 3 }}>
            <SelectDocumentTypeStep
              onNext={documentType => {
                setSelectedType(documentType);
                if (
                  documentType !== requestDemo &&
                  isOutOfTheBoxDocType(documentType)
                ) {
                  complete(0);
                }
              }}
            />
          </Stack>
        ) : activeStep === 1 && selectedType !== null && isOutOfTheBoxType ? (
          <QueueDetailForm
            defaultValues={{
              ...queueDetailFormState,
              documentType: selectedType,
            }}
            onClose={handleDialogClose}
            onSubmit={values => {
              if (auroraConfigEnabled) {
                setQueueDetailFormState(values);
                complete(1);
              } else {
                submitForm(values);
              }
            }}
            mutationLoading={mutationLoading}
            onChange={setQueueDetailFormState}
            selectedTypeName={selectedType}
          />
        ) : activeStep === 2 ? (
          <EngineSelectionForm
            onClose={handleDialogClose}
            templateName={
              getTemplateConfig(
                queueDetailFormState.documentType,
                queueDetailFormState.region
              ).templateName
            }
            onSubmit={engineValues => {
              submitForm(
                queueDetailFormState,
                getCreateQueueQueryParamsFromEngineValues(engineValues),
                getEngineUrlFromEngineValues(engineValues)
              );
            }}
            mutationLoading={mutationLoading}
          />
        ) : (
          <span />
        )}
      </DialogContent>
    </Dialog>
  );
};

export { AddQueueDialog };

import { EmailThread } from '@rossum/api-client/emailThreads';
import { DetailDrawer } from '@rossum/rossum-ui/DetailDrawer';
import { SplitButton } from '@rossum/rossum-ui/SplitButton';
import { Block, Drafts, Forward, Reply } from '@rossum/ui/icons';
import { MailLock as MailLockIcon } from '@rossum/ui/icons';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DrawerProps,
  Grow,
  Skeleton,
  Stack,
} from '@rossum/ui/material';
import { findLast, last } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import { updateEmail } from '../../redux/modules/email/actions';
import { openModal } from '../../redux/modules/modal/actions';
import { Url } from '../../types/basic';
import { EmailLabels, LABEL_REJECTED } from '../../types/email';
import { useScrollToElementOnLoad } from '../../utils/hooks/useScrollToElementOnLoad';
import { UnpaidFeatureOverlay } from '../pricing/components/UnpaidFeatureOverlay';
import { mailboxFeatureSelector } from '../pricing/selectors';
import { Email } from './api-client';
import { DocumentEmailCard } from './components/DocumentEmailCard/DocumentEmailCard';
import { AttachmentT } from './components/EmailAttachment/helpers';
import { EmailCard } from './components/EmailCard';
import {
  EmailResponseForm,
  EmailResponseFormProps,
} from './components/EmailResponseForm/EmailResponseForm';
import { EmailResponseFormMode } from './components/EmailResponseForm/types';
import { useEmailTemplates } from './hooks/useEmailTemplates';
import { useEmailThread } from './hooks/useEmailThread';
import {
  documentIdsForEmail,
  useEmailThreadDocuments,
} from './hooks/useEmailThreadDocuments';
import { useEmailThreadEmails } from './hooks/useEmailThreadEmails';
import { useUpdateEmailThread } from './hooks/useUpdateEmailThread';

type RejectionStateT =
  | 'enabled'
  | 'rejecting'
  | 'alreadyRejected'
  | 'nonZeroAnnotations'
  | 'loading';

const resolveRejectionState = ({
  emailLabels,
  annotationsCount,
}: {
  emailLabels: EmailLabels;
  annotationsCount: number | null;
}): RejectionStateT => {
  if (emailLabels.includes(LABEL_REJECTED)) {
    return 'alreadyRejected';
  }

  if (annotationsCount) {
    return 'nonZeroAnnotations';
  }

  return annotationsCount === null ? 'loading' : 'enabled';
};

type OnSendParams = {
  email: Email;
  mode: EmailResponseFormMode;
  rootEmail?: Email;
};

export type EmailDrawerProps = {
  DrawerProps: DrawerProps;
  EmailResponseFormProps?: Pick<EmailResponseFormProps, 'hideTemplateButton'>;
  emailThreadUrl: Url | undefined;
  onSend?: (params: OnSendParams) => void;
  onThreadLoad?: (emailThread: EmailThread) => void;
  queueUrl: Url;
  defaultFormMode?: EmailResponseFormMode;
  highlightedEmailUrl?: string;
  defaultAttachment?: AttachmentT;
  attachmentMode?: 'annotation' | 'document';
  canSendEmails: boolean;
};

const EmailError = () => {
  const intl = useIntl();
  return (
    <Alert icon={<MailLockIcon />} severity="error">
      {intl.formatMessage({
        id: 'components.emailDrawer.errors.noAccessToEmailQueue.message',
      })}
    </Alert>
  );
};

const EmailDrawer = ({
  emailThreadUrl,
  queueUrl,
  defaultFormMode,
  highlightedEmailUrl,
  DrawerProps,
  EmailResponseFormProps,
  defaultAttachment,
  onSend: onSendCallback,
  attachmentMode,
  canSendEmails,
}: EmailDrawerProps) => {
  const isMailboxFeaturePurchased = useSelector(mailboxFeatureSelector);

  const intl = useIntl();
  const [expandedEmails, setExpandedEmails] = useState<Record<number, boolean>>(
    {}
  );

  // fetch relevant data
  const dispatch = useDispatch();

  const {
    data: thread,
    isInitialLoading: threadLoading,
    isError: threadError,
  } = useEmailThread(emailThreadUrl);

  const { mutate } = useUpdateEmailThread();

  const { data: emails, isInitialLoading: emailsLoading } =
    useEmailThreadEmails(emailThreadUrl);

  useEffect(() => {
    // Mark as read on opening.
    if (thread && (thread.hasNewReplies || !thread.rootEmailRead)) {
      mutate({
        emailThreadUrl: thread.url,
        payload: { hasNewReplies: false, rootEmailRead: true },
      });
    }
  }, [mutate, thread]);

  // EmailResponseForm state including rejection
  const rootEmail =
    thread && emails
      ? emails.find(email => email.url === thread.rootEmail)
      : undefined;

  const highlightedEmail = useMemo(
    () => emails?.find(email => email.url === highlightedEmailUrl),
    [emails, highlightedEmailUrl]
  );

  const lastIncomingEmail = useMemo(
    () => findLast(emails, { type: 'incoming' }),
    [emails]
  );

  const highlightedEmailId =
    highlightedEmail?.id ??
    (thread?.hasNewReplies && !defaultFormMode ? lastIncomingEmail?.id : null);

  const expandedEmailId = highlightedEmailId || emails?.[0]?.id;

  const setExpanded = (expandedEmailId: number, value: boolean) => {
    setExpandedEmails(emails => ({
      ...emails,
      [expandedEmailId]: value,
    }));
  };

  useEffect(() => {
    if (expandedEmailId) setExpanded(expandedEmailId, true);
  }, [expandedEmailId]);

  const [animationFinished, setAnimationFinished] = useState(false);

  // other relevant state data
  const { data: attachments, isInitialLoading: attachmentsLoading } =
    useEmailThreadDocuments(emails ?? []);

  const isLoading = threadLoading || emailsLoading;

  const { refCallback, reset } = useScrollToElementOnLoad({
    behavior: 'smooth',
  });

  useEffect(() => {
    if (!DrawerProps.open) {
      reset();
    }
  }, [reset, DrawerProps.open]);

  // disable unnecessary fetching when the drawer is closed
  const { data: templates, status: templateStatus } = useEmailTemplates(
    DrawerProps.open ? queueUrl : null
  );

  const [formMode, setFormMode] = useState<EmailResponseFormMode | null>(
    defaultFormMode ?? null
  );

  useEffect(() => {
    setFormMode(DrawerProps.open ? defaultFormMode ?? null : null);
  }, [defaultFormMode, DrawerProps.open]);

  const rejectionState = resolveRejectionState({
    emailLabels: rootEmail?.labels ?? [],
    annotationsCount: rootEmail?.annotationCounts?.annotations ?? 0,
  });

  const onMarkAsRejected = (emailId: number) =>
    dispatch(
      openModal({
        textId: 'confirmMarkAsRejected',
        confirmAction: () => updateEmail({ labels: ['rejected'] }, emailId),
        confirmType: 'Danger',
      })
    );

  const onFormClose = () => {
    // Close the drawer completely if there is no thread.
    return thread
      ? setFormMode(null)
      : DrawerProps.onClose?.({}, 'escapeKeyDown');
  };

  const attachmentsForResponseForm = useMemo(
    () =>
      rootEmail && attachments
        ? attachments.filter(attachment =>
            documentIdsForEmail(rootEmail).includes(attachment.document.id)
          )
        : [],
    [attachments, rootEmail]
  );

  const onSend = (email: Email) => {
    setExpanded(email.id, true);

    // It doesn't make sense to be called without formMode
    if (formMode)
      onSendCallback?.({
        email,
        mode: formMode,
        rootEmail,
      });
  };

  return (
    <DetailDrawer
      {...DrawerProps}
      PaperProps={{
        ...DrawerProps.PaperProps,
        elevation: 2,
        sx: { maxWidth: '95%' },
      }}
      title={
        <Stack component="span" spacing={2} direction="row">
          <Drafts />
          {isLoading ? (
            <Skeleton width={300} height={26} />
          ) : (
            <span>
              {threadError
                ? intl.formatMessage({
                    id: 'components.emailDrawer.errors.noAccessToEmailQueue.title',
                  })
                : thread?.subject ||
                  emails?.[0]?.subject ||
                  intl.formatMessage({
                    id: 'containers.documents.emails.noSubject',
                  })}
            </span>
          )}
        </Stack>
      }
    >
      <Stack spacing={2} sx={{ p: 3 }}>
        {isLoading ? (
          <Stack alignItems="center" justifyContent="center" sx={{ py: 5 }}>
            <CircularProgress size={48} />
          </Stack>
        ) : threadError ? (
          <EmailError />
        ) : thread && emails ? (
          <>
            {emails.map((email, index) => {
              const documentIds = documentIdsForEmail(email);
              return attachmentMode === 'document' ? (
                <DocumentEmailCard
                  key={email.id}
                  email={email}
                  documents={attachments
                    ?.filter(attachment =>
                      documentIds.includes(attachment.document.id)
                    )
                    .map(attachment => attachment.document)}
                />
              ) : (
                <EmailCard
                  elevation={5}
                  sx={{
                    boxShadow: theme => theme.shadows[2],
                    scrollMarginTop: 16,
                    overflow: 'visible',
                  }}
                  email={email}
                  emailAttachments={
                    attachmentsLoading
                      ? 'loading'
                      : attachments?.filter(attachment =>
                          documentIds.includes(attachment.document.id)
                        )
                  }
                  expanded={!!expandedEmails[email.id]}
                  setExpanded={value => setExpanded(email.id, value)}
                  // only root e-mails that are not rejected should have red chips when there are missing attachments
                  shouldDisplayAttachmentError={
                    index === 0 &&
                    !(thread.rootEmailRead || email.labels.includes('rejected'))
                  }
                  key={email.id}
                  ref={
                    // Highlight email only if opened without form
                    // Otherwise, the form focus should take priority
                    email.id === highlightedEmailId && !formMode
                      ? refCallback
                      : null
                  }
                />
              );
            })}
            {canSendEmails ? (
              <SwitchTransition>
                <Grow
                  key={formMode === null ? 'buttons' : 'form'}
                  onEntered={() => setAnimationFinished(true)}
                  onExited={() => setAnimationFinished(false)}
                  appear
                  unmountOnExit
                >
                  {formMode === null ? (
                    rootEmail && isMailboxFeaturePurchased ? (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <Button
                          data-cy="email-footer-button-reply"
                          variant="contained"
                          color={
                            rejectionState === 'enabled'
                              ? 'secondary'
                              : 'primary'
                          }
                          sx={{ alignSelf: 'flex-end' }}
                          startIcon={<Reply />}
                          onClick={() => {
                            setFormMode('reply');
                          }}
                        >
                          {intl.formatMessage({
                            id: 'components.emailDrawer.buttons.reply',
                          })}
                        </Button>
                        <Button
                          data-cy="email-footer-button-forward"
                          variant="outlined"
                          color="secondary"
                          sx={{ alignSelf: 'flex-end' }}
                          onClick={() => {
                            setFormMode('forward');
                          }}
                          startIcon={<Forward />}
                        >
                          {intl.formatMessage({
                            id: 'components.emailDrawer.buttons.forward',
                          })}
                        </Button>

                        {rejectionState === 'enabled' && (
                          <SplitButton
                            data-cy="email-footer-button-reject"
                            variant="contained"
                            color="error"
                            sx={{ alignSelf: 'flex-end' }}
                            ActionButtonProps={{
                              startIcon: <Block />,
                            }}
                            DropdownTriggerButtonProps={{
                              size: 'small',
                            }}
                            defaultLabel={intl.formatMessage({
                              id: 'components.emailDrawer.buttons.reject',
                            })}
                            actions={[
                              {
                                label: intl.formatMessage({
                                  id: 'components.emailDrawer.buttons.reject',
                                }),
                                callback: () => setFormMode('rejectEmail'),
                              },
                              {
                                label: intl.formatMessage({
                                  id: 'components.emailDrawer.buttons.markAsRejected',
                                }),
                                callback: () => onMarkAsRejected(rootEmail.id),
                              },
                            ]}
                          />
                        )}
                      </Stack>
                    ) : (
                      // Do not display the buttons when there is no root email to answer to.
                      // Need to include the element because of the SwitchTransition.
                      <Stack />
                    )
                  ) : (formMode === 'rejectEmail' ||
                      formMode === 'forward' ||
                      formMode === 'reply') &&
                    templateStatus === 'success' &&
                    rootEmail ? (
                    <Box position="relative">
                      {isMailboxFeaturePurchased ? (
                        <EmailResponseForm
                          {...EmailResponseFormProps}
                          parentAnnotation={defaultAttachment?.annotation}
                          attachmentMode={attachmentMode}
                          animationFinished={animationFinished}
                          mode={formMode}
                          emailTemplates={templates ?? []}
                          rootEmail={rootEmail}
                          parentEmailUrl={rootEmail.url}
                          defaultAttachments={
                            attachmentsLoading
                              ? 'loading'
                              : attachmentsForResponseForm
                          }
                          onClose={onFormClose}
                          onSend={onSend}
                          queueUrl={queueUrl}
                          emailThreadUrl={thread?.url}
                        />
                      ) : (
                        <UnpaidFeatureOverlay
                          title={intl.formatMessage({
                            id: 'features.pricing.unpaidFeatureOverlay.title.mailbox',
                          })}
                          dataCy="unpaid-overlay-mailbox-response-form-root-email"
                          sx={{ backgroundColor: 'inherit' }}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box />
                  )}
                </Grow>
              </SwitchTransition>
            ) : null}
          </>
        ) : null}
        {(formMode === 'rejectDocument' ||
          formMode === 'forwardDocument' ||
          formMode === 'postponeDocument') &&
          templateStatus === 'success' &&
          canSendEmails && (
            <Box
              ref={isLoading ? null : refCallback}
              sx={{
                scrollMarginTop: 16,
                // Hide form when thread is loading
                // We don't want to unmount it when thread changes
                // Otherwise, we lose the success message
                height: !isLoading ? 'auto' : 0,
                overflow: !isLoading ? 'visible' : 'hidden',
                position: 'relative',
              }}
            >
              {isMailboxFeaturePurchased ? (
                <EmailResponseForm
                  {...EmailResponseFormProps}
                  parentAnnotation={defaultAttachment?.annotation}
                  attachmentMode={attachmentMode}
                  parentEmailUrl={
                    highlightedEmail?.url ??
                    defaultAttachment?.annotation?.email ??
                    last(emails)?.url ??
                    last(defaultAttachment?.annotation?.relatedEmails)
                  }
                  mode={formMode}
                  emailTemplates={templates ?? []}
                  rootEmail={highlightedEmail}
                  // Attach a current annotation from annotation screen.
                  defaultAttachments={
                    defaultAttachment ? [defaultAttachment] : 'loading'
                  }
                  onSend={onSend}
                  onClose={onFormClose}
                  queueUrl={queueUrl}
                  emailThreadUrl={thread?.url}
                />
              ) : (
                <UnpaidFeatureOverlay
                  title={intl.formatMessage({
                    id: 'features.pricing.unpaidFeatureOverlay.title.mailbox',
                  })}
                  dataCy="unpaid-overlay-mailbox-response-form-generic"
                  sx={{ backgroundColor: 'inherit' }}
                />
              )}
            </Box>
          )}
      </Stack>
    </DetailDrawer>
  );
};

export { EmailDrawer };

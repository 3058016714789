import { AnnotationPayload } from '@rossum/api-client/annotations';
import { Label } from '@rossum/api-client/labels';
import { Page } from '@rossum/api-client/pages';
import { Queue } from '@rossum/api-client/queues';
import { Relation } from '@rossum/api-client/relations';
import { User } from '@rossum/api-client/users';
import { Workspace } from '@rossum/api-client/workspaces';
import { ActionType, createAction } from 'typesafe-actions';
import { apiUrl } from '../../../constants/config';
import { ProcessingDuration } from '../../../timeSpent/timeSpent';
import { Annotation, ModifierType } from '../../../types/annotation';
import { ID, Url } from '../../../types/basic';
import { Document } from '../../../types/document';
import {
  AnnotationLoadMode,
  AutomationBlocker,
  CancelAnnotationPayload,
  ConfirmAnnotationFulfilledMeta,
  DeleteAnnotationMeta,
  DisplayAnnotation,
  DisplayAnnotationMeta,
  ExitAnnotationPayload,
  NextAnnotationMeta,
  PostponeAnnotationMeta,
  RejectAnnotationMeta,
  StartAnnotationFullfiledMeta,
  StartAnnotationPayload,
  SuggestedEdit,
  UpdateAnnotationFulfilledMeta,
  UpdateAnnotationFulfilledPayload,
  UpdateAnnotationMeta,
} from './types';

export const startAnnotation = createAction(
  'START_ANNOTATION',
  (id: number, mode?: AnnotationLoadMode) => ({
    id,
    mode: mode ?? 'full-load',
  })
)<StartAnnotationPayload>();

export const startAnnotationFulfilled = createAction(
  'START_ANNOTATION_FULFILLED',
  undefined,
  (url: string, mode?: AnnotationLoadMode) => ({
    url,
    mode: mode ?? 'full-load',
  })
)<undefined, StartAnnotationFullfiledMeta>();

export const displayAnnotation = createAction(
  'DISPLAY_ANNOTATION',
  undefined,
  ({ id, url, mode }: DisplayAnnotation = {}) => ({
    url: id ? `${apiUrl}/annotations/${id}` : url,
    mode: mode ?? 'full-load',
  })
)<undefined, DisplayAnnotationMeta>();

export const annotationExpired = createAction(
  'ANNOTATION_EXPIRED',
  (id: ID) => id
)<ID>();

export const updateAnnotation = createAction(
  'UPDATE_ANNOTATION',
  (_, payload: AnnotationPayload) => payload,
  ({ url }: UpdateAnnotationMeta) => ({ url })
)<AnnotationPayload, UpdateAnnotationMeta>();

export const updateAnnotationFulfilled = createAction(
  'UPDATE_ANNOTATION_FULFILLED',
  undefined,
  ({ url, queue }: UpdateAnnotationFulfilledPayload) => ({
    annotationUrl: url,
    queueUrl: queue,
  })
)<undefined, UpdateAnnotationFulfilledMeta>();

// This action should be used only in annotations/epics
// for correctly displaying of messages
export const deleteAnnotation = createAction(
  'DELETE_ANNOTATION',
  undefined,
  (url: string, skipRedirect?: boolean, emailId?: number) => ({
    url,
    skipRedirect: !!skipRedirect,
    emailId,
  })
)<undefined, DeleteAnnotationMeta>();

export const deleteAnnotationFulfilled = createAction(
  'DELETE_ANNOTATION_FULFILLED',
  undefined,
  (
    url: string,
    skipRedirect: boolean,
    emailId?: number,
    withMessage?: boolean
  ) => ({
    url,
    skipRedirect,
    emailId,
    withMessage,
  })
)<undefined, DeleteAnnotationMeta>();

// This action should be used only in annotations/epics
// for correctly displaying of messages
export const postponeAnnotation = createAction(
  'POSTPONE_ANNOTATION',
  undefined,
  (url: string, skipRedirect?: boolean) => ({
    url,
    skipRedirect: !!skipRedirect,
  })
)<undefined, PostponeAnnotationMeta>();

export const postponeAnnotationFulfilled = createAction(
  'POSTPONE_ANNOTATION_FULFILLED',
  undefined,
  (url: string, skipRedirect: boolean, withMessage?: boolean) => ({
    url,
    skipRedirect,
    withMessage,
  })
)<undefined, PostponeAnnotationMeta>();

export const rejectAnnotation = createAction(
  'REJECT_ANNOTATION',
  undefined,
  (url: string, skipRedirect?: boolean) => ({
    url,
    skipRedirect: !!skipRedirect,
  })
)<undefined, RejectAnnotationMeta>();

export const rejectAnnotationFulfilled = createAction(
  'REJECT_ANNOTATION_FULFILLED',
  undefined,
  (annotationUrl: string, skipRedirect: boolean, withMessage?: boolean) => ({
    skipRedirect,
    url: annotationUrl,
    withMessage,
  })
)<undefined, RejectAnnotationMeta>();

export const confirmAnnotation = createAction(
  'CONFIRM_ANNOTATION',
  undefined,
  (annotationUrl: Url, skipWorkflows?: boolean) => ({
    annotationUrl,
    skipWorkflows,
  })
)<undefined, { annotationUrl: Url; skipWorkflows: boolean }>();

export const confirmAnnotationFulfilled = createAction(
  'CONFIRM_ANNOTATION_FULFILLED',
  undefined,
  (url: string) => ({ url })
)<undefined, ConfirmAnnotationFulfilledMeta>();

export const cancelAnnotation = createAction(
  'CANCEL_ANNOTATION',
  (url: string) => ({ url })
)<CancelAnnotationPayload>();

export const cancelAnnotationFulfilled = createAction(
  'CANCEL_ANNOTATION_FULFILLED'
)<void>();

export const nextAnnotation = createAction(
  'NEXT_ANNOTATION',
  undefined,
  // HACK: Redirecting to a different annotation URL doesn't work (
  // there is no epic which properly cancels the old one and starts the new one).
  // Instead, we are extending this action, since in its original behavior, it already
  // does what's needed, and we don't need to modify many epics.
  //
  // There are two ways of using this action:
  // nSteps - redirects to next annotation in the annotation stack (original behavior)
  // 'cancel-previous-annotation' - clears state belonging to previous annotation and cancels in-flight requests
  (nSteps: number | 'cancel-previous-annotation') => ({
    nSteps,
  })
)<undefined, NextAnnotationMeta>();

export const nextAnnotableAnnotation = createAction(
  'NEXT_ANNOTABLE_ANNOTATION'
)<void>();

export const fetchAnnotationFulfilled = createAction(
  'FETCH_ANNOTATION_FULFILLED',
  (
    annotation: Annotation,
    sideload: {
      automationBlockers: AutomationBlocker[];
      document: Document;
      modifier: ModifierType;
      pages: Page[];
      relations: Relation[];
      queue: Queue | undefined;
      workspace: Workspace | undefined;
      confirmedBy: User | undefined;
      exportedBy: User | undefined;
      labels: Label[];
    }
  ) => ({ annotation, sideload })
)();

export const exitAnnotation = createAction('EXIT_ANNOTATION', (id: number) => ({
  id,
}))<ExitAnnotationPayload>();

export const refetchAnnotationEmails = createAction(
  'REFETCH_ANNOTATION_EMAILS',
  (annotationId: number) => ({ annotationId })
)<{ annotationId: number }>();

export const refetchAnnotationEmailsFulfilled = createAction(
  'REFETCH_ANNOTATION_EMAILS_FULFILLED',
  (annotation: Pick<Annotation, 'emailThread' | 'relatedEmails'>) => annotation
)<Pick<Annotation, 'emailThread' | 'relatedEmails'>>();

export const clearAnnotationData = createAction(
  'CLEAR_ANNOTATION_DATA'
)<void>();

export const fetchSuggestedEdit = createAction(
  'FETCH_SUGGESTED_EDIT',
  (url: string) => url
)<string>();

export const fetchSuggestedEditFulfilled = createAction(
  'FETCH_SUGGESTED_EDIT_FULFILLED',
  (payload: SuggestedEdit) => payload
)<SuggestedEdit>();

export const fetchProcessingDuration = createAction(
  'FETCH_PROCESSING_DURATION',
  (annotationUrl: string) => annotationUrl
)<string>();

export const fetchProcessingDurationFulfilled = createAction(
  'FETCH_PROCESSING_DURATION_FULFILLED',
  (payload: ProcessingDuration & { annotation: Url }) => payload
)<ProcessingDuration & { annotation: Url }>();

export type AnnotationActions = ActionType<
  | typeof startAnnotation
  | typeof startAnnotationFulfilled
  | typeof displayAnnotation
  | typeof annotationExpired
  | typeof updateAnnotation
  | typeof updateAnnotationFulfilled
  | typeof deleteAnnotation
  | typeof deleteAnnotationFulfilled
  | typeof postponeAnnotation
  | typeof postponeAnnotationFulfilled
  | typeof confirmAnnotation
  | typeof confirmAnnotationFulfilled
  | typeof cancelAnnotation
  | typeof cancelAnnotationFulfilled
  | typeof nextAnnotation
  | typeof nextAnnotableAnnotation
  | typeof fetchAnnotationFulfilled
  | typeof exitAnnotation
  | typeof clearAnnotationData
  | typeof fetchSuggestedEdit
  | typeof fetchSuggestedEditFulfilled
  | typeof rejectAnnotation
  | typeof rejectAnnotationFulfilled
  | typeof fetchProcessingDuration
  | typeof fetchProcessingDurationFulfilled
  | typeof refetchAnnotationEmails
  | typeof refetchAnnotationEmailsFulfilled
>;

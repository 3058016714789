import { Skeleton, Stack } from '@rossum/ui/material';
import React, { useEffect, useRef, useState } from 'react';
import * as R from 'remeda';
import { DrawerConfig } from '../../../../containers/DocumentValidation/ValidationEmailDrawer';
import { Annotation } from '../../../../types/annotation';
import { ConfirmButton } from './sidebar-footer-actions/ConfirmButton';
import { SidebarFooterActions } from './sidebar-footer-actions/SidebarFooterActions';

type SidebarFooterSkeletonProps = {
  actionCount: number;
};

const SidebarFooterSkeleton = ({ actionCount }: SidebarFooterSkeletonProps) => {
  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%', px: 1 }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: '50%', flexGrow: 0, flexShrink: 0 }}
      >
        {R.range(0, actionCount).map(item => (
          <Skeleton key={item} sx={{ width: '50%', height: 50 }} />
        ))}
      </Stack>
      <Skeleton sx={{ height: 50, width: '100%' }} />
    </Stack>
  );
};

// 35px width + 8px spacing
const SIDEBAR_ACTION_BUTTON_WIDTH = 44;

type SidebarFooterProps = {
  annotation: Annotation | undefined;
  onEmailThreadOpen: (drawerConfig?: DrawerConfig) => void;
  loading?: boolean;
};

const SidebarFooter = React.memo(
  ({ annotation, onEmailThreadOpen, loading = false }: SidebarFooterProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [visibleActionButtonCount, setVisibleActionButtonCount] = useState(4);

    const resizeObserverRef = useRef<ResizeObserver>(
      new ResizeObserver(([entry]) => {
        const contentBox = entry ? entry.contentBoxSize[0] : null;
        if (contentBox) {
          const availableSpace = contentBox.inlineSize / 2;
          const noOfActions = Math.floor(
            availableSpace / SIDEBAR_ACTION_BUTTON_WIDTH
          );

          setVisibleActionButtonCount(count =>
            noOfActions !== count ? noOfActions : count
          );
        }
      })
    );

    useEffect(() => {
      const resizeObserver = resizeObserverRef.current;
      const container = containerRef.current;

      if (container) {
        resizeObserver.observe(container);
      }

      return () => {
        if (container) {
          resizeObserver.unobserve(container);
        }
      };
    }, []);

    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{
          py: 1,
          px: 1,
          width: '100%',
          borderTop: '1px solid',
          borderColor: theme => theme.palette.divider,
          backgroundColor: theme => theme.palette.background.paper,
        }}
        ref={containerRef}
      >
        {loading || !annotation ? (
          <SidebarFooterSkeleton actionCount={visibleActionButtonCount} />
        ) : (
          <>
            <SidebarFooterActions
              visibleButtonsCount={visibleActionButtonCount}
              annotation={annotation}
              onEmailThreadOpen={onEmailThreadOpen}
            />
            <ConfirmButton annotation={annotation} />
          </>
        )}
      </Stack>
    );
  }
);

SidebarFooter.displayName = 'SidebarFooter';

export { SidebarFooter };

import { Queue } from '@rossum/api-client/queues';
import { Breadcrumbs, Typography } from '@rossum/ui/material';
import clsx from 'clsx';
import { compact, get } from 'lodash';
import AlertCircle from 'mdi-react/AlertCircleIcon';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { goBack } from 'redux-first-history';
import Scrollable from '../../../../../components/Scrollable';
import ArrowLeft from '../../../../../components/Sidebar/ArrowLeft';
import Header from '../../../../../components/Sidebar/Header';
import SidebarWrapper from '../../../../../components/Sidebar/Sidebar';
import { OriginalAnyDatapointSchema } from '../../../../../types/schema';
import { Workspace } from '../../../../../types/workspace';
import styles from '../styles.module.sass';
import PreviewSection from './Preview';
import SchemaAlert from './SchemaAlert';

type Props = {
  isCurrentPath: (path: string[]) => boolean;
  isSchemaOutdated: boolean;
  redirectionData: {
    pathname: string;
    state: unknown;
  };
  queue?: Queue;
  schemaConceptExists: boolean;
  toggleCurrent: (path: string[]) => void;
  validSchema?: OriginalAnyDatapointSchema[];
  workspace?: Workspace;
};

const Sidebar = ({
  isCurrentPath,
  isSchemaOutdated,
  queue,
  schemaConceptExists,
  toggleCurrent,
  validSchema,
  workspace,
}: Props) => {
  const breadcrumbPath = compact([get(workspace, 'name'), get(queue, 'name')]);
  const dispatch = useDispatch();

  return (
    <SidebarWrapper
      sx={{
        position: 'relative',
        pt: 0,
        width: 400,
      }}
    >
      <Header
        className={styles.SidebarHeader}
        title="containers.settings.queues.schema.sidebar.title"
        onLeft={<ArrowLeft to={() => dispatch(goBack())} />}
        onRight={
          <Breadcrumbs>
            {breadcrumbPath.map((str, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Typography variant="caption" key={index}>
                {str}
              </Typography>
            ))}
          </Breadcrumbs>
        }
      />
      <Scrollable>
        {!validSchema || !validSchema.length ? (
          <SchemaAlert schemaConceptExists={schemaConceptExists} />
        ) : (
          <div>
            <div
              className={clsx(
                styles.Preview,
                isSchemaOutdated && styles.OutdatedPreview
              )}
            >
              {validSchema.map((section, i) => (
                <PreviewSection
                  key={section.id}
                  toggleCurrent={toggleCurrent}
                  path={[i.toString(), 'children']}
                  isCurrentPath={isCurrentPath}
                  {...section}
                />
              ))}
            </div>
            {isSchemaOutdated && (
              <div className={styles.Outdated}>
                <AlertCircle />
                <FormattedMessage id="containers.settings.queues.schema.sidebar.outdated" />
              </div>
            )}
          </div>
        )}
      </Scrollable>
    </SidebarWrapper>
  );
};

export default Sidebar;

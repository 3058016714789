import { CircularProgress } from '@rossum/ui/material';
import { FormattedMessage } from 'react-intl';
import styles from '../style.module.sass';

const InputContentLoader = () => (
  <div className={styles.InputContentLoaderWrapper}>
    <div className={styles.InputLoader}>
      <CircularProgress size={20} color="inherit" />
    </div>
    <div className={styles.LoadingMessage}>
      <FormattedMessage id="components.editor.inputBox.loading" />
    </div>
  </div>
);

export default InputContentLoader;

import { Hook, LogsQuery } from '@rossum/api-client/hooks';
import { SelectChangeEvent, Stack } from '@rossum/ui/material';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { asScalar } from '../../../../lib/url';
import SearchInput from '../../../../ui/search-input/SearchInput';
import { statusMap } from '../helpers';
import { DatepickerFilters } from './DatepickerFilters';
import { FilterSelect } from './FilterSelect';
import { HooksAutocomplete } from './HooksAutocomplete';

const FILTERS_SELECT_WIDTH = 200;
const LOG_LEVEL_OPTIONS = ['INFO', 'ERROR', 'WARNING'];
const PAGE_SIZE_OPTIONS = ['5', '10', '25', '50', '100'];
const STATUS_CODES_OPTIONS = ['2xx', '4xx', '5xx'];

type Props = {
  filters: LogsQuery;
  setFilters: (filters: LogsQuery) => void;
  extensions: Array<Hook> | undefined;
  isFetchingExtensions: boolean;
  isLoadingExtensions: boolean;
};

type StatusKey = keyof typeof statusMap;

const Filters = ({
  isFetchingExtensions,
  isLoadingExtensions,
  extensions,
  filters,
  setFilters,
}: Props) => {
  const [searchState, setSearchState] = useState(filters.search || '');

  const selectedCategories = Object.entries(statusMap)
    .filter(([_, v]) => v.every(code => filters.statusCode?.includes(code)))
    .map(([k]) => k);

  const handleChange = (
    event: SelectChangeEvent<string | string[]>,
    filterId: 'pageSize' | 'logLevel' | 'statusCode'
  ) => {
    if (filterId === 'statusCode') {
      const selected = event.target.value as StatusKey[];
      const selectedCodes = selected
        .flatMap(category => statusMap[category])
        .filter((value, index, self) => self.indexOf(value) === index);

      return setFilters({ ...filters, statusCode: selectedCodes });
    }

    const selected = event.target.value;
    return setFilters({ ...filters, [filterId]: selected });
  };

  const handleClear = (
    filterId: 'pageSize' | 'logLevel' | 'statusCode',
    isMultiple: boolean
  ) => {
    setFilters({
      ...filters,
      [filterId]: isMultiple ? [] : undefined,
    });
  };

  const debouncedSetFilters = useMemo(
    () =>
      debounce((search, filters) => {
        setFilters({
          ...filters,
          search: search.length > 0 ? search : undefined,
        });
      }, 400),
    [setFilters]
  );

  // having a memoised search that gets re-rendered every time `filters` query changes, ensures that this component always shows whats in the url.
  // otherwise we end up with empty search bar with a search query in the url (when there is a redirect from logs to logs?{something})
  const MemoisedSearch = useMemo(() => {
    const handleOnChange = (search: string) => {
      setSearchState(search);
      debouncedSetFilters(search, filters);
    };
    return <SearchInput onChange={handleOnChange} value={searchState} />;
  }, [debouncedSetFilters, filters, searchState]);

  return (
    <Stack
      direction="row"
      spacing={2}
      minHeight={40}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <DatepickerFilters
          filters={filters}
          setFilters={setFilters}
          width={FILTERS_SELECT_WIDTH}
        />
        <HooksAutocomplete
          extensions={extensions}
          value={asScalar(filters.hook)}
          setHookFilter={hooks => setFilters({ ...filters, hook: hooks })}
          isFetchingExtensions={isFetchingExtensions}
          isLoadingExtensions={isLoadingExtensions}
          width={FILTERS_SELECT_WIDTH}
        />
        <FilterSelect
          filterId="logLevel"
          selectProps={{
            value: filters.logLevel,
            options: LOG_LEVEL_OPTIONS,
            multiple: false,
          }}
          onChange={handleChange}
          onClear={handleClear}
          isLoadingExtensions={isLoadingExtensions}
        />
        <FilterSelect
          filterId="statusCode"
          selectProps={{
            value: selectedCategories,
            options: STATUS_CODES_OPTIONS,
            multiple: true,
          }}
          onChange={handleChange}
          onClear={handleClear}
          isLoadingExtensions={isLoadingExtensions}
        />
        <FilterSelect
          filterId="pageSize"
          selectProps={{
            value: filters.pageSize,
            options: PAGE_SIZE_OPTIONS,
            multiple: false,
          }}
          onChange={handleChange}
          onClear={handleClear}
          isLoadingExtensions={isLoadingExtensions}
          width={120}
        />
      </Stack>
      {MemoisedSearch}
    </Stack>
  );
};

export { Filters };

import {
  Autocomplete,
  autocompleteClasses,
  FilterOptionsState,
  Popper,
  styled,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from '@rossum/ui/material';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useState } from 'react';
import { EnumOption } from '../../../../../types/schema';
import { MessagesStatsWithBlockers } from '../../sidebar-infosections/automation-blockers/helpers';
import { MessageIndicator } from './MessageIndicator';
import {
  RenderRowData,
  VirtualizedListBox,
} from './SidebarItemActiveEnumValue.VirtualizedListBox';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const autocompleteStyles: SxProps<Theme> = {
  [`.${autocompleteClasses.clearIndicator}`]: { visibility: 'visible' },
};

const filterOptions = (
  options: Array<EnumOption>,
  { inputValue }: FilterOptionsState<EnumOption>
) => {
  return inputValue
    ? matchSorter(options, inputValue, {
        keys: [
          {
            threshold: matchSorter.rankings.CONTAINS,
            key: 'label',
          },
          {
            threshold: matchSorter.rankings.CONTAINS,
            key: option => option.value.toString(),
          },
        ],
      })
    : options;
};

type SidebarItemActiveValueProps = {
  value: EnumOption | undefined;
  onChange: (val: EnumOption | null) => void;
  messagesStats: MessagesStatsWithBlockers | undefined;
  options: EnumOption[];
  clearable: boolean;
  disabled: boolean;
};

export const SidebarItemActiveEnumValue = ({
  value,
  onChange,
  messagesStats,
  options,
  clearable,
  disabled,
}: SidebarItemActiveValueProps) => {
  const theme = useTheme();
  const handleChange: (value: EnumOption | null) => void = useCallback(
    value => {
      return onChange(value);
    },
    [onChange]
  );

  const [open, setOpen] = useState(false);
  const [highlightedOption, setHighlightedOption] = useState<EnumOption | null>(
    null
  );

  const handleHighlighted = useCallback(
    (_: React.SyntheticEvent, value: EnumOption | null) => {
      setHighlightedOption(value);
    },
    []
  );

  const onKeyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (['Enter'].includes(e.key) && open) {
        e.preventDefault();
        e.stopPropagation();

        handleChange(highlightedOption);
        setOpen(false);
      }

      if (e.key === 'Tab' && open) {
        if (highlightedOption) {
          handleChange(highlightedOption);
        }
      }

      if (['Delete', 'Backspace'].includes(e.key)) {
        e.stopPropagation();

        if (clearable) {
          handleChange(null);
        }
      }
    },
    [clearable, handleChange, open, highlightedOption]
  );

  const textFieldInputOnFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    // https://stackoverflow.com/questions/35951771/react-autofocus-sets-cursor-to-beginning-of-input-value
    const val = event.target.value;
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
    // eslint-disable-next-line no-param-reassign
    event.target.value = val;
  };

  return (
    <Autocomplete
      sx={autocompleteStyles}
      autoHighlight
      size="small"
      disabled={disabled}
      disableClearable={!clearable}
      disableListWrap
      options={options}
      autoComplete
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onHighlightChange={handleHighlighted}
      renderInput={params => {
        return (
          <TextField
            {...params}
            size="small"
            multiline
            autoFocus
            onFocus={textFieldInputOnFocus}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  <MessageIndicator messagesStats={messagesStats} />
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              onKeyDown: onKeyDownHandler,
              value: params.inputProps.value || value?.label || '',
              style: {
                ...params.inputProps?.style,
                ...theme.typography.body2,
              },
            }}
          />
        );
      }}
      renderOption={(props, option, state) => {
        const tuple: RenderRowData = [
          props,
          option,
          state.index,
          state.inputValue,
        ] as const;
        // typecasting to make Autocomplete happy,
        // it is consumed in VirtualizedListBox and renderRow
        return tuple as React.ReactNode;
      }}
      filterOptions={filterOptions}
      value={value ?? null}
      onChange={(_, selected) => {
        handleChange(selected);
      }}
      PopperComponent={StyledPopper}
      ListboxComponent={VirtualizedListBox}
    />
  );
};

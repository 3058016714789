import {
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
} from '@rossum/ui/material';
import clsx from 'clsx';
import RefreshIcon from 'mdi-react/BackupRestoreIcon';
import ContentSaveIcon from 'mdi-react/ContentSaveOutlineIcon';
import FileIcon from 'mdi-react/FileOutlineIcon';
import PrettifyIcon from 'mdi-react/FormatAlignLeftIcon';
import InputIcon from 'mdi-react/ImportIcon';
import RunIcon from 'mdi-react/PlayCircleOutlineIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../style.module.sass';
import { EventNameOptions } from '../types';
import FunctionEventsDropdown from './FunctionEventsDropdown';

type Props = {
  hasError: boolean;
  isRunning: boolean;
  functionEvents: Array<EventNameOptions>;
  assignedFunctionEventNames: Array<EventNameOptions>;
  currentEvent: EventNameOptions;
  currentObject: string;
  onPrettify: () => void;
  onRun: () => void;
  onSave: () => void;
  onLoad: () => void;
  canRun: boolean;
  canSave: boolean;
  savedInput: string;
  setCurrentEvent: (_event: EventNameOptions) => void;
  setCurrentObject: (_objectRef: string) => void;
  setShowInputLoading: (loading: boolean) => void;
  isSaving?: boolean;
};

const InputBoxMenu = ({
  assignedFunctionEventNames,
  canRun,
  canSave,
  currentEvent,
  currentObject,
  functionEvents,
  hasError,
  isRunning,
  onPrettify,
  onRun,
  onLoad,
  onSave,
  savedInput,
  setCurrentEvent,
  setCurrentObject,
  setShowInputLoading,
  isSaving,
}: Props) => {
  const intl = useIntl();
  const unsavedChanges = canSave && currentEvent !== 'mySavedInput';
  return (
    <div className={styles.InputBoxHeader}>
      <div className={styles.InputSection}>
        <div className={styles.BoxTitle}>
          <div className={styles.TitleIcon}>
            <InputIcon size={20} />
          </div>
          <FormattedMessage id="components.editor.inputBox.title" />
        </div>
        <div className={styles.InputButtonsSection}>
          <Tooltip
            title={intl.formatMessage({
              id: unsavedChanges
                ? 'components.editor.inputBox.dropdown.unsavedChanges'
                : 'components.editor.inputBox.dropdown.event',
            })}
            placement="top"
          >
            <Stack direction="row" spacing={1}>
              <FunctionEventsDropdown
                assignedFunctionEventNames={assignedFunctionEventNames}
                currentEvent={currentEvent}
                setCurrentEvent={event => {
                  setShowInputLoading(true);
                  setCurrentEvent(event);
                }}
                savedInput={savedInput}
                events={functionEvents}
                disabled={unsavedChanges}
              />
            </Stack>
          </Tooltip>
          <Tooltip
            placement="top"
            title={intl.formatMessage({
              id: 'components.editor.inputBox.prettify',
            })}
          >
            <button
              onClick={onPrettify}
              className={styles.InputBoxButton}
              disabled={isRunning || hasError}
              data-cy="prettify-button"
            >
              <PrettifyIcon size={16} />
            </button>
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({ id: 'components.editor.inputBox.run' })}
            placement="top"
          >
            <button
              onClick={() => onRun()}
              className={clsx(
                styles.InputBoxButton,
                styles.InputBoxButtonLast,
                isRunning && styles.LoadingButton
              )}
              disabled={!canRun || hasError}
              data-cy="run-button"
              style={{ width: 40 }}
            >
              {isRunning ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                <RunIcon size={16} />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      <div className={styles.InputSection}>
        <div className={styles.BoxTitle}>
          <div className={styles.TitleIcon}>
            <FileIcon size={20} />
          </div>
          {currentEvent === 'invocation' || currentEvent === 'mySavedInput' ? (
            ''
          ) : (
            <div className={styles.DocumentName}>
              <TextField
                placeholder={intl.formatMessage({
                  id: `components.editor.inputBox.objectInput.placeholder.${currentEvent}`,
                })}
                value={currentObject}
                onChange={newValue => setCurrentObject(newValue.target.value)}
                size="small"
                margin="dense"
                inputProps={{
                  'data-cy': 'extensions-editor-object-input',
                  sx: { fontSize: '13px', padding: '4px 14px' },
                }}
                sx={{ margin: '0 0', width: '100%' }}
              />
              <button
                onClick={() => onLoad()}
                className={clsx(
                  styles.InputBoxButton,
                  currentObject || styles.InputButtonDisabled
                )}
              >
                <RefreshIcon size={16} />
              </button>
            </div>
          )}
        </div>
        <Tooltip
          placement="top"
          title={intl.formatMessage({
            id: canSave
              ? 'components.editor.inputBox.save'
              : hasError
                ? 'components.editor.inputBox.hasError'
                : 'components.editor.inputBox.noChangesToSave',
          })}
        >
          <button
            onClick={() => onSave()}
            className={clsx(
              styles.InputBoxButton,
              styles.InputBoxButtonLast,
              isSaving && styles.LoadingButton,
              (!canSave || hasError) && styles.InputButtonDisabled
            )}
            data-cy="save-button"
          >
            {isSaving ? (
              <CircularProgress size={16} color="inherit" sx={{ mx: 1.2 }} />
            ) : (
              <ContentSaveIcon size={16} />
            )}
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default InputBoxMenu;

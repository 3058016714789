import { getIDFromUrl } from '@rossum/api-client';
import { BillingStats } from '@rossum/api-client/billing';
import { PaginationQuery } from '@rossum/api-client/utils';
import {
  Button,
  Paper,
  Stack,
  TableCell,
  Typography,
} from '@rossum/ui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { formatNumberToLocale } from '../../../lib/helpers';
import { State } from '../../../types/state';
import { BillingUnit, getPercentage, getValuesByUnit } from '../helpers';
import { OrganizationGroupBillingEntity } from '../hooks/useBillingEntity';
import { usePageStatisticsPerOrganization } from '../hooks/usePageStatisticsPerOrganization';
import { usePageStatisticsPerOrganizationExport } from '../hooks/usePageStatisticsPerOrganizationExport';
import { BillingTable, TableRow } from './BillingTable/BillingTable';
import { EmptyTable } from './BillingTable/EmptyTable';
import { LoadingTable } from './BillingTable/LoadingTable';
import { LoadingOverlay } from './LoadingOverlay';
type OrganizationsUsageTableProps = {
  usedPages: number;
  period: Pick<BillingStats, 'endDate' | 'beginDate'>;
  unit: BillingUnit;
  billingEntity: OrganizationGroupBillingEntity;
};

const OrganizationsUsageTable = ({
  usedPages,
  period,
  unit,
  billingEntity,
}: OrganizationsUsageTableProps) => {
  const intl = useIntl();

  const [paginationQuery, setPaginationQuery] = useState<
    Required<PaginationQuery>
  >({ page: 0, pageSize: 10 });

  const { data: organizationStats, isLoading } =
    usePageStatisticsPerOrganization(billingEntity, period, paginationQuery);

  const { triggerExport, isExporting } = usePageStatisticsPerOrganizationExport(
    billingEntity,
    period
  );

  const organizations = useSelector(
    (state: State) => state.user.organizationGroupOrganizations
  );

  useEffect(() => {
    setPaginationQuery(prevState => {
      return { ...prevState, page: 0 };
    });
  }, [period]);

  const columns = [
    'organizations',
    'organizationId',
    unit === 'documents' ? 'usedDocuments' : 'usedPages',
    'usedPercentage',
  ] as const;

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'containers.billing.overview.organizationsUsage',
          })}
        </Typography>

        <Button
          color="secondary"
          variant="outlined"
          onClick={() => triggerExport()}
          disabled={isExporting}
        >
          {isExporting && <LoadingOverlay size={16} />}
          {intl.formatMessage({
            id: 'containers.billing.export',
          })}
        </Button>
      </Stack>

      <Paper sx={{ overflow: 'hidden' }} elevation={2}>
        <BillingTable
          paginationResponse={organizationStats?.pagination}
          paginationState={paginationQuery}
          setPaginationState={setPaginationQuery}
          headColumns={columns.map(column => ({
            id: `containers.billing.overview.${column}` as const,
          }))}
        >
          {isLoading ? (
            <LoadingTable
              columnsCount={columns.length}
              rowsCount={paginationQuery.pageSize + 1} // + 1 is for visual purposes - replace for pagination
            />
          ) : organizationStats && organizationStats.results.length ? (
            organizationStats.results.map(row => {
              const values = getValuesByUnit(row.values, unit);
              const rowOrganizationId = row.organization
                ? getIDFromUrl(row.organization)
                : null;
              const organization = organizations?.find(
                group => group.id === rowOrganizationId
              );
              return (
                <TableRow key={row.organization} className="TableRowOverride">
                  <TableCell component="th" scope="row">
                    {organization?.name}
                  </TableCell>
                  <TableCell>{rowOrganizationId ?? ''}</TableCell>
                  <TableCell>
                    {formatNumberToLocale(values.billable, intl.locale)}
                  </TableCell>
                  <TableCell>
                    {usedPages > 0 &&
                      getPercentage((values.billable / usedPages) * 100)}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <EmptyTable
              title="containers.billing.emptyTable"
              text="containers.billing.emptyDescription"
              columnsCount={columns.length}
            />
          )}
        </BillingTable>
      </Paper>
    </Stack>
  );
};

export { OrganizationsUsageTable };

import { ActionType, createAction } from 'typesafe-actions';
import { ID } from '../../../types/basic';
import { EmbeddedConfig } from '../../../types/ui';
import { RepeatedStatus } from './types';

export const hideHints = createAction('HIDE_HINTS')<void>();

export const enterQueueEditor = createAction('ENTER_QUEUE_EDITOR')<void>();
export const enterLogin = createAction('ENTER_LOGIN')<void>();
export const enterValidation = createAction('ENTER_VALIDATION')<void>();
export const enterUser = createAction('ENTER_USER')<void>();
export const enterStatistics = createAction('ENTER_STATISTICS')<void>();
export const leaveStatistics = createAction('LEAVE_STATISTICS')<void>();
export const enterUserList = createAction('ENTER_USER_LIST')<void>();
export const enterQueue = createAction('ENTER_QUEUE')<void>();
export const enterExtensionsList = createAction(
  'ENTER_EXTENSIONS_LIST'
)<void>();
export const enterExtension = createAction('ENTER_EXTENSION')<void>();
export const enterCreateExtension = createAction(
  'ENTER_CREATE_EXTENSION'
)<void>();
export const enterExtensionEditor = createAction(
  'ENTER_EXTENSION_EDITOR'
)<void>();
export const leaveExtension = createAction('LEAVE_EXTENSION')<void>();
export const leaveExtensionEditor = createAction(
  'LEAVE_EXTENSION_EDITOR'
)<void>();

export const exitQueue = createAction('EXIT_QUEUE')<void>();

export const leaveValidation = createAction('LEAVE_VALIDATION')<void>();

export const startReviewing = createAction(
  'START_REVIEWING',
  undefined,
  (queueUrl: string) => ({ queueUrl })
)<undefined, { queueUrl: string }>();

export const startValidation = createAction(
  'START_VALIDATION',
  undefined,
  (
    url: string,
    annotationsIds?: number[],
    previousId?: number,
    nextId?: number
  ) => ({ annotationsIds, url, previousId, nextId })
)<
  undefined,
  {
    url: string;
    annotationsIds: number[] | undefined;
    previousId: number | undefined;
    nextId: number | undefined;
  }
>();

export const showUpload = createAction('SHOW_UPLOAD')<void>();

export const hideUpload = createAction('HIDE_UPLOAD')<void>();

export const showHints = createAction('SHOW_HINTS')<void>();

export const openSelectMenu = createAction('OPEN_SELECT_MENU')<void>();

export const closeSelectMenu = createAction('CLOSE_SELECT_MENU')<void>();

export const setNavigateToNext = createAction(
  'SET_NAVIGATE_TO_NEXT',
  (navigateToNext: boolean) => navigateToNext
)<boolean>();

export const startEditMode = createAction('START_EDIT_MODE')<void>();
export const cancelEditMode = createAction(
  'CANCEL_EDIT_MODE',
  (annotationId?: ID) => annotationId
)<ID | undefined>();

export const startEditingDatapointValue = createAction(
  'START_EDITING_DATAPOINT_VALUE'
)<void>();

export const stopEditingDatapointValue = createAction(
  'STOP_EDITING_DATAPOINT_VALUE'
)<void>();

export const toggleFooter = createAction('TOGGLE_FOOTER')<void>();

export const alertNetworkOutage = createAction('ALERT_NETWORK_OUTAGE')<void>();

export const fetchEmbeddedConfigFullfiled = createAction(
  'FETCH_EMBEDDED_CONFIG_FULFILLED',
  (payload: EmbeddedConfig) => payload
)<{
  cancelUrl: string;
  returnUrl: string;
  deleteUrl: string | undefined;
  postponeUrl: string | undefined;
}>();

const enableDatapointsSuggestion = createAction(
  'ENABLE_DATAPOINTS_SUGGESTION',
  undefined,
  (id: ID) => ({ id })
)<undefined, { id: ID }>();

const disableDatapointsSuggestion = createAction(
  'DISABLE_DATAPOINTS_SUGGESTION',
  undefined,
  (id: ID) => ({ id })
)<undefined, { id: ID }>();

export type StartValidation = typeof startValidation;
export type SetNavigateToNext = typeof setNavigateToNext;

export const setRepeatedStatus = createAction(
  'SET_REPEATED_STATUS',
  (status: RepeatedStatus) => status
)<RepeatedStatus>();

export const setApplicationTabVisibility = createAction(
  'SET_APPLICATION_TAB_VISIBILITY',
  (payload: boolean) => payload
)<boolean>();

export const showBlockers = createAction('SHOW_BLOCKERS')<void>();
export const hideBlockers = createAction(
  'HIDE_BLOCKERS',
  undefined,
  ({ readOnly }: { readOnly: boolean }) => ({ readOnly })
)<undefined, { readOnly: boolean }>();

export const setGridActionInProgress = createAction(
  'SET_GRID_ACTION_IN_PROGRESS',
  (payload: boolean) => payload
)();

export type UiActions = ActionType<
  | typeof hideHints
  | typeof enterQueueEditor
  | typeof enterLogin
  | typeof enterValidation
  | typeof enterUser
  | typeof enterStatistics
  | typeof enterUserList
  | typeof enterQueue
  | typeof enterCreateExtension
  | typeof exitQueue
  | typeof leaveValidation
  | typeof leaveExtensionEditor
  | typeof startReviewing
  | StartValidation
  | typeof showUpload
  | typeof hideUpload
  | typeof showHints
  | typeof openSelectMenu
  | typeof closeSelectMenu
  | SetNavigateToNext
  | typeof startEditMode
  | typeof cancelEditMode
  | typeof startEditingDatapointValue
  | typeof stopEditingDatapointValue
  | typeof toggleFooter
  | typeof alertNetworkOutage
  | typeof fetchEmbeddedConfigFullfiled
  | typeof enableDatapointsSuggestion
  | typeof disableDatapointsSuggestion
  | typeof setApplicationTabVisibility
  | typeof setRepeatedStatus
  | typeof enterExtensionsList
  | typeof showBlockers
  | typeof hideBlockers
  | typeof setGridActionInProgress
>;

import { getIDFromUrl, Url } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Workspace } from '@rossum/api-client/workspaces';
import { ChevronRight, ExpandMore, FolderOpen } from '@rossum/ui/icons';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@rossum/ui/material';
import { compact } from 'lodash';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLazyQueues } from '../../../../../../../../business/queues/useLazyQueues';
import CopyToClipboardButton from '../../../../../../../../ui/copy-to-clipboard/CopyToClipboardButton';
import { ListSkeletonPlaceholder } from './ListSkeletonPlaceholder';
import { useIntersectionObserver } from './useIntersectionObserver';

const PREFIX = 'WorkspaceFolder';

const classes = {
  root: `${PREFIX}-root`,
  expandIconContainer: `${PREFIX}-expandIconContainer`,
  folderIconContainer: `${PREFIX}-folderIconContainer`,
  textContainer: `${PREFIX}-textContainer`,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  // Simulate the same color as underlying paper - this is hacky, but we
  // cannot leave it transparent. Better solution brewing in someone's head
  backgroundColor: theme.palette.background.paper,
  backgroundImage: `linear-gradient(rgba(255,255,255,0.05), rgba(255,255,255,0.05))`,
  [`& .${listItemButtonClasses.root}`]: {
    // MAYBE: can we compute this dynamically based on secondaryAction contents?
    // the trick is it's absolutely positioned and changes width based on translation and chip counter
    // it's complex but defo something to investigate in the future
    // would be better to sort out with UX to ensure secondaryAction does not change size
    paddingRight: 250,
  },
}));

// TODO: Add ability to switch between single and multiple value selection
type WorkspaceFolderProps = {
  workspace: Workspace;
  value: Url[];
  onChange: (newValue: Url[]) => void;
  listRef?: MutableRefObject<HTMLUListElement | null>;
  defaultOpen?: boolean;
  disabled?: boolean;
};

type QueueItemProps = {
  queue: Queue;
  workspaceId: number;
  handleToggle: (queue: Queue) => void;
  value: Url[];
  disabled?: boolean;
};

const QueueItem = ({
  queue,
  workspaceId,
  handleToggle,
  value,
  disabled,
}: QueueItemProps) => (
  <ListItem
    sx={{ padding: 0 }}
    secondaryAction={
      <CopyToClipboardButton
        content={`${queue.id}`}
        iconColor="text.disabled"
        iconSize="16px"
      >
        <Typography variant="body2" color="text.disabled">
          ID {queue.id}
        </Typography>
      </CopyToClipboardButton>
    }
  >
    <ListItemButton
      sx={{ pl: 6 }}
      divider
      onClick={() => handleToggle(queue)}
      focusRipple
      disabled={disabled}
    >
      <ListItemIcon sx={{ minWidth: 40 }}>
        <Checkbox
          sx={{ padding: 0 }}
          checked={value.includes(queue.url)}
          disableRipple
          tabIndex={-1}
          inputProps={{
            'aria-labelledby': `${workspaceId}/${queue.id}`,
          }}
        />
      </ListItemIcon>
      <ListItemText
        id={`${workspaceId}/${queue.id}`}
        disableTypography
        sx={{ paddingRight: 5 }}
      >
        <Typography variant="body2">{queue.name}</Typography>
      </ListItemText>
    </ListItemButton>
  </ListItem>
);

const WorkspaceFolder = ({
  workspace,
  value,
  onChange,
  defaultOpen = false,
  listRef,
  disabled,
}: WorkspaceFolderProps) => {
  const [open, setOpen] = useState(defaultOpen);

  const workspaceRef = useRef<HTMLUListElement>(null);

  const infiniteLoaderRef = useRef<HTMLUListElement>(null);

  const { data, status, hasNextPage, isFetching, isRefetching, fetchNextPage } =
    useLazyQueues(
      {
        id: compact(workspace.queues.map(getIDFromUrl)),
      },
      { enabled: open && workspace.queues.length > 0, staleTime: 30 * 1000 }
    );

  const remainingCount = data?.pages
    ? workspace.queues.length -
      (data.pages.reduce((total, page) => total + page.results.length, 0) ?? 0)
    : 0;

  useIntersectionObserver({
    target: infiniteLoaderRef,
    root: listRef,
    enabled: hasNextPage && !isFetching,
    threshold: 0.1,
    rootMargin: '100px',
    onIntersect: () => fetchNextPage(),
  });

  const handleToggle = useCallback(
    (queue: Queue) => {
      if (value.includes(queue.url)) {
        onChange(value.filter(url => url !== queue.url));
      } else {
        onChange([...value, queue.url]);
      }
    },
    [onChange, value]
  );

  const allSelected = value.length === workspace.queues.length;

  const handleSelectAll = useCallback(
    () => onChange(workspace.queues),
    [onChange, workspace.queues]
  );

  const handleDeselectAll = useCallback(() => onChange([]), [onChange]);

  // not perfect but works best from trivial options imo
  const handleOnExit = useCallback(() => {
    if (listRef && listRef.current && workspaceRef.current && !open) {
      // if workspace is in sticky position and we're scrolled down, scroll list so that
      // workspace is on top before collapsing
      if (workspaceRef.current.offsetTop < listRef.current.scrollTop) {
        listRef.current.scrollTo({
          left: 0,
          top: workspaceRef.current.offsetTop,
          behavior: 'auto',
        });
      }
    }
  }, [listRef, open]);

  const intl = useIntl();

  // TODO: When loading is slow and you scroll far before items are loaded into pages
  // list items jump
  return (
    <ul style={{ position: 'relative' }} ref={workspaceRef}>
      <StyledListItem
        className={classes.root}
        disablePadding
        divider
        secondaryAction={
          <Stack direction="row" alignItems="center" spacing={1}>
            <CopyToClipboardButton
              content={`${workspace.id}`}
              iconColor="text.disabled"
              iconSize="16px"
            >
              <Typography variant="body2" color="text.disabled">
                ID {workspace.id}
              </Typography>
            </CopyToClipboardButton>

            {workspace.queues.length > 0 && (
              <Button
                variant="text"
                color="secondary"
                size="small"
                onClick={allSelected ? handleDeselectAll : handleSelectAll}
                disabled={disabled}
                sx={{ paddingLeft: 0 }}
              >
                {allSelected
                  ? intl.formatMessage({
                      id: 'components.workspaceFolder.deselectAll',
                    })
                  : intl.formatMessage({
                      id: 'components.workspaceFolder.selectAll',
                    })}
              </Button>
            )}
            <Chip
              size="tiny"
              color="default"
              label={`${value.length}/${workspace.queues.length}`}
            />
          </Stack>
        }
      >
        <ListItemButton onClick={() => setOpen(!open)} focusRipple>
          <ListItemIcon
            sx={{ color: theme => theme.palette.primary.main, marginRight: 1 }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              {open ? <ExpandMore /> : <ChevronRight />}
              {status === 'loading' || isRefetching ? (
                <CircularProgress size={16} />
              ) : (
                <FolderOpen />
              )}
            </Stack>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle2' }}>
            {workspace.name}
          </ListItemText>
        </ListItemButton>
      </StyledListItem>
      <Collapse in={open} timeout="auto" unmountOnExit onExit={handleOnExit}>
        <List disablePadding>
          {status === 'success' &&
            (data?.pages ?? []).map(page =>
              page.results.map(queue => (
                <QueueItem
                  queue={queue}
                  workspaceId={workspace.id}
                  value={value}
                  handleToggle={handleToggle}
                  key={`${workspace.id}/${queue.id}`}
                  disabled={disabled}
                />
              ))
            )}
          {(hasNextPage || status === 'loading') && (
            <ListSkeletonPlaceholder
              length={remainingCount}
              ref={infiniteLoaderRef}
            />
          )}
        </List>
      </Collapse>
    </ul>
  );
};

export { WorkspaceFolder };

import { Logout, Receipt } from '@rossum/ui/icons';
import { IconBell } from '@rossum/ui/icons/tabler';
import {
  Avatar,
  Badge,
  IconButton,
  ListItem,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import { first } from 'lodash';
import UserIcon from 'mdi-react/UserIcon';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useBeamerContext } from '../../beamer/BeamerProvider';
import { beamerSelector, isEmbedded } from '../../constants/config';
import { useBillingEnabled } from '../../features/billing';
import { hasSubscriptionPlanExistSelector } from '../../features/pricing/selectors';
import {
  logoutUser,
  switchOrganization,
} from '../../redux/modules/auth/actions';
import { organizationSelector } from '../../redux/modules/organization/selectors';
import { GroupOrganizationUser } from '../../types/groupOrganizationUser';
import { Organization } from '../../types/organization';
import { State } from '../../types/state';
import HelpPanel from '../HelpPanel';
import Dropdown, { ItemPropsT } from '../UI/Dropdown';
import OrganizationSwitcher from './OrganizationSwitcher';
import styles from './styles.module.sass';

type StateProps = {
  email: string;
  firstName: string;
  lastName: string;
  organization: Partial<Organization>;
  organizationLoaded: boolean;
  organizationUser: GroupOrganizationUser;
  topRightLogo?: string;
  userInitials: string;
};

type Props = StateProps;

const UserPanel = ({
  email,
  firstName,
  lastName,
  organization,
  organizationLoaded,
  organizationUser,
  topRightLogo,
  userInitials,
}: Props) => {
  const dispatch = useDispatch();
  const isPricingActive = useSelector(hasSubscriptionPlanExistSelector);

  const [notificationNumber, setNotificationNumber] = useState(
    window.Beamer?.notificationNumber
  );

  const { isBeamerEnabled } = useBeamerContext();

  const billingEnabled = useBillingEnabled().isEnabled;

  useEffect(() => {
    if (isBeamerEnabled) {
      window.Beamer?.update({
        onopen: () => setNotificationNumber(0),
        callback: (count: number) => setNotificationNumber(count),
      });
    }
  }, [isBeamerEnabled]);

  const billingPath = `/billing/${isPricingActive ? 'my-plan' : 'overview'}`;

  return (
    <Stack spacing={1} direction="row" alignItems="center" sx={{ pr: 0.5 }}>
      <HelpPanel />
      {isBeamerEnabled && (
        <IconButton size="medium" color="secondary">
          <Badge
            color="primary"
            variant="dot"
            invisible={!notificationNumber}
            id={beamerSelector}
            data-cy="beamer-selector"
          >
            <SvgIcon fontSize="small">
              <IconBell />
            </SvgIcon>
          </Badge>
        </IconButton>
      )}
      {organizationLoaded &&
        (isEmbedded() ? (
          topRightLogo && (
            <div className={styles.CompanyLogoEmbedded}>
              <img src={topRightLogo} alt="logo" />
            </div>
          )
        ) : (
          <Dropdown
            className={styles.DropdownWrapper}
            listClassName={styles.UsersDropdownList}
            dataCy="userpanel"
            items={[
              (_props: ItemPropsT) => (
                <ListItem
                  key="user-menu-info"
                  sx={{
                    borderBottom: theme => `1px solid ${theme.palette.divider}`,
                    pb: 2,
                  }}
                  divider
                >
                  <Avatar
                    sx={
                      topRightLogo
                        ? {
                            width: 32,
                            height: 32,
                            backgroundColor: 'transparent',
                            padding: 0,
                          }
                        : {
                            width: 26,
                            height: 26,
                            backgroundColor: theme =>
                              theme.palette.text.primary,
                            padding: 1,
                          }
                    }
                    src={topRightLogo}
                  >
                    <Typography
                      fontSize={theme => theme.typography.pxToRem(12)}
                      sx={{
                        color: theme =>
                          theme.palette.getContrastText(
                            theme.palette.text.primary
                          ),
                      }}
                    >
                      {userInitials}
                    </Typography>
                  </Avatar>
                  <Stack sx={{ marginLeft: 1 }}>
                    <Typography variant="subtitle2" color="text.primary" noWrap>
                      {`${firstName} ${lastName}`}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="gray"
                      noWrap
                      data-cy="email"
                    >
                      {email}
                    </Typography>
                  </Stack>
                </ListItem>
              ),
              (props: ItemPropsT) => (
                <OrganizationSwitcher
                  key="user-panel-organizations"
                  organization={organization}
                  organizationUser={organizationUser}
                  onOrganizationClick={(orgUrl: string, orgName: string) =>
                    dispatch(switchOrganization(orgUrl, orgName))
                  }
                  {...props}
                />
              ),
              (props: ItemPropsT) => (
                <Link
                  {...props}
                  key="user-panel-personal-info"
                  data-cy="personal-settings-link"
                  to="/account/personalInfo"
                >
                  <UserIcon />
                  <FormattedMessage id="containers.personalInfo.title" />
                </Link>
              ),
              (props: ItemPropsT) =>
                billingEnabled && (
                  <Link
                    {...props}
                    data-cy="user-panel-billing"
                    to={billingPath}
                    key="user-panel-billing"
                  >
                    <Receipt />
                    <FormattedMessage id="containers.billing.title" />
                  </Link>
                ),
              (props: ItemPropsT) => (
                <a
                  {...props}
                  key="user-panel-sign-out"
                  data-cy="logout-button"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    dispatch(logoutUser());
                  }}
                >
                  <Logout />
                  <FormattedMessage id="components.dashboard.user.signOut" />
                </a>
              ),
            ]}
          >
            {({ open }: Record<'open', boolean>) => (
              <IconButton
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                data-cy="user-profile-btn"
                sx={
                  topRightLogo
                    ? {
                        border: theme => `2px solid ${theme.palette.divider}`,
                        padding: 0,
                      }
                    : {}
                }
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: theme =>
                      topRightLogo ? 'transparent' : theme.palette.text.primary,
                  }}
                  src={topRightLogo}
                >
                  <Typography
                    fontSize={theme => theme.typography.pxToRem(16)}
                    sx={{
                      color: theme =>
                        theme.palette.getContrastText(
                          theme.palette.text.primary
                        ),
                    }}
                  >
                    {userInitials}
                  </Typography>
                </Avatar>
              </IconButton>
            )}
          </Dropdown>
        ))}
    </Stack>
  );
};

const mapStateToProps = (state: State): StateProps => {
  const { email, username, firstName, lastName } = state.user;
  return {
    email: email || username,
    firstName,
    lastName,
    userInitials: `${first(firstName || username || email)}${
      first(lastName) || ''
    }`.toUpperCase(),
    topRightLogo: organizationSelector(state)?.uiSettings?.branding?.topRight,
    organizationLoaded: !!organizationSelector(state)?.id,
    organization: organizationSelector(state),
    organizationUser: state.user,
  };
};

export default connect<StateProps, null, Record<string, never>, State>(
  mapStateToProps
)(UserPanel);

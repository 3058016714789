import { ThemeOptions } from '@mui/material';

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#1E6EE5',
      light: '#61A0FF',
      dark: '#0B5BD2',
    },
    secondary: {
      main: '#384352',
      light: '#8895AC',
      dark: '#272F3A',
    },
    success: {
      main: '#10B40D',
      light: '#10B40D',
      dark: '#0E980B',
    },
    info: {
      main: '#0BACD0',
      light: '#0BACD0',
      dark: '#087C96',
    },
    warning: {
      main: '#FF7A0D',
      light: '#FF7A0D',
      dark: '#DB6300',
    },
    error: {
      main: '#F33321',
      light: '#F33321',
      dark: '#C61B0B',
    },
    aurora: {
      main: '#8811E3',
      light: '#8811E3',
      dark: '#720EBE',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: 'rgba(13, 17, 23, 1)',
      secondary: 'rgba(82, 97, 117, 1)',
      disabled: 'rgba(141, 153, 176, 1)',
    },
    action: {
      active: '#4B596C',
      hover: 'rgba(13, 17, 23, 0.06)',
      selected: 'rgba(13, 17, 23, 0.1)',
      disabled: 'rgba(166, 177, 193, 0.8)',
      disabledBackground: 'rgba(202, 209, 218, 0.5)',
      focus: 'rgba(166, 177, 193, 0.5)',
    },
    other: {
      tooltip: '#d0d5dd',
    },
    divider: 'rgba(26, 31, 39, 0.12)',
    background: {
      default: '#F3F4F7',
      paper: '#FFFFFF',
    },
  },
};

import { snakeToCamel } from '@rossum/api-client/utils';
import {
  IconCalendar,
  IconCircleDot,
  IconSTurnRight,
  IconTag,
} from '@rossum/ui/icons/tabler';
import {
  Button,
  Collapse,
  Stack,
  SvgIcon,
  Typography,
} from '@rossum/ui/material';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useShowWorkflowActivity } from '../../../../../components/AnnotationInformation/components/useShowWorkflowActivity';
import { DocumentStatus } from '../../../../../components/UI/DocumentStatus';
import { annotationSideloadsSelector } from '../../../../../redux/modules/annotation/selectors';
import { Annotation } from '../../../../../types/annotation';
import { labelsFeatureSelector } from '../../../../pricing/selectors';
import { useDocumentStore } from '../../../document-store/DocumentStore';
import { SidebarItemLabel } from '../../shared/SidebarItemLabel';
import { SidebarItemLayout } from '../../shared/SidebarItemLayout';
import { SidebarSectionTitle } from '../../shared/SidebarSectionTitle';
import { VerticalContainer } from '../../shared/VerticalContainer';
import { DateDetail } from './DateDetail';
import { LabelsList } from './LabelsList';

type AnnotationDataProps = {
  annotation: Annotation | undefined;
};

const AnnotationData = React.memo(({ annotation }: AnnotationDataProps) => {
  const intl = useIntl();

  const annotationDataVisible = useDocumentStore(
    state => state.sidebarState.annotationDataVisible
  );

  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (containerRef.current && annotationDataVisible) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [annotationDataVisible]);

  // TODO: Why do we keep needing to use selectors and why are sideloads spearated in types when they are in redux :thinking:
  const annotationSideloads = useSelector(annotationSideloadsSelector);

  const modifier = annotationSideloads.modifier?.user?.email ?? '-';

  // for compatibility with a component displaying the chip
  const documentStatusModifier = annotationSideloads.modifier?.user
    ? {
        id: annotationSideloads.modifier.user.id,
        username: annotationSideloads.modifier.user.email ?? '-',
      }
    : undefined;

  // Confirmed/exported by
  const { confirmedBy, exportedBy } = annotationSideloads;

  // Workflows
  const showWorkflowActivity = useShowWorkflowActivity(annotation?.id);

  const openDrawer = useDocumentStore(state => state.openDrawer);

  // Labels
  const labelsEnabled = useSelector(labelsFeatureSelector);

  return (
    <Collapse in={annotationDataVisible} ref={containerRef}>
      <SidebarSectionTitle
        title={intl.formatMessage({
          id: 'components.sidebarV2.annotationData.title',
        })}
      />
      <Stack sx={{ pl: 2, pr: 1 }} color="text.secondary">
        {annotation && annotationSideloads ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconCircleDot />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.status',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <span>
                  <DocumentStatus
                    annotationId={annotation.id}
                    annotationUrl={annotation.url}
                    status={snakeToCamel(annotation.status)}
                    modifier={documentStatusModifier}
                  />
                </span>
              </VerticalContainer>
            }
          />
        ) : null}
        <SidebarItemLayout
          iconSlot={
            <SvgIcon
              fontSize="small"
              sx={{
                display: 'block',
              }}
            >
              <IconCalendar />
            </SvgIcon>
          }
          labelSlot={
            <SidebarItemLabel
              label={intl.formatMessage({
                id: 'components.sidebarV2.annotationData.labels.created',
              })}
            />
          }
          valueSlot={
            <VerticalContainer>
              <DateDetail dateString={annotation?.createdAt} />
            </VerticalContainer>
          }
        />
        <SidebarItemLayout
          iconSlot={
            <SvgIcon
              fontSize="small"
              sx={{
                display: 'block',
              }}
            >
              <IconCalendar />
            </SvgIcon>
          }
          labelSlot={
            <SidebarItemLabel
              label={intl.formatMessage({
                id: 'components.sidebarV2.annotationData.labels.edited',
              })}
            />
          }
          valueSlot={
            <VerticalContainer>
              <DateDetail dateString={annotation?.modifiedAt} />
              <Typography variant="caption" color="text.secondary">
                {modifier}
              </Typography>
            </VerticalContainer>
          }
        />
        {annotation?.confirmedAt ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconCalendar />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.confirmed',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <DateDetail dateString={annotation?.confirmedAt} />
                <Typography variant="caption" color="text.secondary">
                  {confirmedBy?.email ?? '-'}
                </Typography>
              </VerticalContainer>
            }
          />
        ) : null}
        {annotation?.exportedAt ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconCalendar />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.exported',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <DateDetail dateString={annotation?.exportedAt} />
                <Typography variant="caption" color="text.secondary">
                  {exportedBy?.email ?? '-'}
                </Typography>
              </VerticalContainer>
            }
          />
        ) : null}
        {showWorkflowActivity ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon fontSize="small" sx={{ display: 'block' }}>
                <IconSTurnRight />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.workflows',
                })}
              />
            }
            valueSlot={
              <VerticalContainer>
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  sx={{
                    alignSelf: 'flex-start',
                    // TODO: @ui put this into theme?
                    minWidth: 'unset',
                    px: 0.5,
                    py: 0.5,
                  }}
                  onClick={
                    annotation
                      ? () =>
                          openDrawer({
                            drawer: 'activities',
                            annotationId: annotation.id,
                          })
                      : undefined
                  }
                >
                  {intl.formatMessage({
                    id: 'components.sidebarV2.annotationData.buttons.open',
                  })}
                </Button>
              </VerticalContainer>
            }
          />
        ) : null}
        {labelsEnabled ? (
          <SidebarItemLayout
            iconSlot={
              <SvgIcon
                fontSize="small"
                sx={{
                  display: 'block',
                }}
              >
                <IconTag />
              </SvgIcon>
            }
            labelSlot={
              <SidebarItemLabel
                label={intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.labels',
                })}
              />
            }
            valueSlot={
              <VerticalContainer spacing={1}>
                <LabelsList labels={annotationSideloads.labels} />
                {/* TODO: Will uncomment when we can implement adding labels */}
                {/* <Button
                variant="text"
                size="small"
                color="secondary"
                startIcon={
                  <SvgIcon fontSize="small">
                    <IconCirclePlus />
                  </SvgIcon>
                }
                sx={{
                  alignSelf: 'flex-start',
                }}
              >
                {intl.formatMessage({
                  id: 'components.sidebarV2.annotationData.labels.labels.addLabel',
                })}
              </Button> */}
              </VerticalContainer>
            }
          />
        ) : null}
      </Stack>
    </Collapse>
  );
});

AnnotationData.displayName = 'AnnotationData';

export { AnnotationData };

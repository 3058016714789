import { AnnotationPayload } from '@rossum/api-client/annotations';
import { useQueryClient } from '@tanstack/react-query';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { QUERY_KEY_QUEUES_UNPAGINATED } from '../../../business/queues/useUnpaginatedQueues';
import AnnotationsActionModal, {
  AnnotationsActionModalProps,
} from '../../../components/AnnotationsActionModal';
import {
  constructDocumentUrl,
  getIDFromString,
  getIDsFromURLs,
} from '../../../lib/url';
import { handleLabelsUpdate } from '../../../redux/modules/annotations/helpers';
import { throwInfo } from '../../../redux/modules/messages/actions';
import { startValidation } from '../../../redux/modules/ui/actions';
import { Url } from '../../../types/basic';
import { useAddAttachmentsModal } from '../../document/annotation-attachment/hooks/useAddAttachmentsModal';
import { OnLabelAction } from '../../labels/BatchLabelsList';
import { useRequestLabelsApply } from '../../labels/hooks/useRequestLabelsApply';
import { useDownloadOriginalDocuments } from '../../tasks/hooks/useDownloadOriginalDocuments';
import { SelectionPanelButtonAction } from '../annotation-actions/config';
import { DownloadFormat } from '../download-buttons/DownloadFormatsSelect';
import { useExportAnnotationsCrossQueue } from '../hooks/useExportAnnotationsCrossQueue';
import {
  ALL_DOCUMENTS_QUERY_KEY,
  useInvalidateDashboardData,
  useSetDashboardData,
} from '../hooks/useFetchDashboardData';
import { usePurgeModal } from '../hooks/usePurgeModal';
import { QUERY_KEY_STATUS_COUNTS } from '../statuses/hooks/useStatusTabCounts';
import { AllDocsAnnotation } from '../types';
import { useDeleteAnnotations } from './hooks/useDeleteAnnotations';
import { useMoveAnnotationsModal } from './hooks/useMoveAnnotationsModal';
import { usePatchAnnotations } from './hooks/usePatchAnnotations';
import { usePostponeAnnotations } from './hooks/usePostponeAnnotations';
import { usePurgeAnnotations } from './hooks/usePurgeAnnotations';

export type ActionFnParams = {
  annotations: AllDocsAnnotation[];
  onSuccess?: () => void;
};

type ActionFn = (params: ActionFnParams) => void;

type OnBatchActionParams = {
  urls: Url[];
  parentUrl?: Url;
  onSuccess?: () => void;
};

type BatchActionWithModal = Exclude<
  AnnotationsActionModalProps['action'],
  'reject' | undefined
>;

type DownloadAllPayload = {
  format: DownloadFormat;
  selectedAnnotationsIds?: number[];
};

type DownloadActions = {
  download: (context: DownloadAllPayload) => void;
  asyncDownload: (params: ActionFnParams) => void;
  downloadAndExport: (context: DownloadAllPayload) => void;
};

type LabelAction = {
  label: OnLabelAction;
};

type ActionsWithCommonParams =
  | 'addAttachment'
  | Exclude<
      SelectionPanelButtonAction,
      'download' | 'downloadAndExport' | 'label'
    >;

export type DocListAnnotationActions = DownloadActions &
  LabelAction &
  Record<ActionsWithCommonParams, ActionFn>;

type AnnotationActions = DocListAnnotationActions | null;

type ContextValue = {
  annotationActions: AnnotationActions;
};

type Props = {
  children: ReactNode;
  activeQueueName: string | null;
};

const Context = createContext<ContextValue>({
  annotationActions: null,
});

export const useAnnotationActionsContext = () => {
  const ctx = useContext(Context);

  if (ctx === undefined) {
    throw new Error(
      '`useAnnotationActionsContext` must be used within a AnnotationActionsContext provider'
    );
  }

  return ctx;
};

const AnnotationActionsContext = ({ children, activeQueueName }: Props) => {
  const dispatch = useDispatch();
  const setDashboardData = useSetDashboardData();

  const onBatchValidation = ({ urls }: { urls: Url[] }) =>
    urls.length &&
    dispatch(
      startValidation(
        constructDocumentUrl({ id: getIDFromString(urls[0]) }),
        getIDsFromURLs(urls)
      )
    );

  const queryClient = useQueryClient();

  const { mutate: onApplyLabel } = useRequestLabelsApply();

  const onBatchLabel = ({
    payload,
    onSuccess,
    onError,
    labelsInQuery,
  }: Parameters<DocListAnnotationActions['label']>[0]) => {
    return onApplyLabel(payload, {
      onSuccess: () => {
        onSuccess();

        setDashboardData(response => {
          if (!response) return response;

          return {
            ...response,
            results: handleLabelsUpdate(response.results, payload, {
              labelsInQuery,
            }),
          };
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [ALL_DOCUMENTS_QUERY_KEY],
        });
      },
      onError: () => {
        onError();
      },
    });
  };

  const batchOnSettled = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_STATUS_COUNTS],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_QUEUES_UNPAGINATED],
    });
    return queryClient.invalidateQueries({
      queryKey: [ALL_DOCUMENTS_QUERY_KEY],
    });
  };

  const { node: moveModal, openModal } = useMoveAnnotationsModal();

  const { mutate: purgeAnnotations } = usePurgeAnnotations();
  const { node: purgeModal, openPurgeModal } = usePurgeModal();

  const { mutate: download } = useExportAnnotationsCrossQueue();

  const { mutate: downloadOriginalDocuments } = useDownloadOriginalDocuments();

  const [action, setAction] = useState<{
    name: BatchActionWithModal;
    selectedUrls: Array<Url>;
    parentUrl?: Url;
    onSuccess?: () => void;
  } | null>(null);

  const onClose = () => setAction(null);

  const onBatchAction =
    (name: BatchActionWithModal) =>
    ({ urls, parentUrl, onSuccess }: OnBatchActionParams) => {
      setAction({ name, selectedUrls: urls, parentUrl, onSuccess });
    };

  const invalidateDashboardData = useInvalidateDashboardData();
  const { node: addAttachmentModal, openModal: openAddAttachmentModal } =
    useAddAttachmentsModal({ onClose: invalidateDashboardData });

  // this is a util fn to avoid extra code. It converts incoming annotations into urls.
  const retrieveUrl =
    (actionFn: ReturnType<typeof onBatchAction> | typeof onBatchValidation) =>
    ({
      annotations,
      ...rest
    }: Omit<OnBatchActionParams, 'urls'> & ActionFnParams) => {
      const urls = annotations.map(a => a.url);
      return actionFn({ urls, ...rest });
    };

  const actions: DocListAnnotationActions = {
    validate: retrieveUrl(onBatchValidation),
    label: onBatchLabel,
    postpone: retrieveUrl(onBatchAction('annotationSkip')),
    delete: retrieveUrl(onBatchAction('annotationDelete')),
    reExtract: retrieveUrl(onBatchAction('reExtract')),
    reprocess: retrieveUrl(onBatchAction('reprocessToToReview')),
    reprocessConfirmed: retrieveUrl(onBatchAction('reprocessToConfirmed')),
    move: ({ annotations, onSuccess }) => {
      openModal({
        annotations,
        onSuccess,
        onSettled: batchOnSettled,
      });
    },
    purge: retrieveUrl(({ urls, onSuccess }: OnBatchActionParams) => {
      openPurgeModal(
        {
          annotationCount: urls.length,
          payload: { annotations: urls },
        },
        {
          onSubmit: ({ payload, annotationCount }) =>
            purgeAnnotations(payload, {
              onSuccess: () => {
                dispatch(
                  throwInfo('annotationPurged', undefined, {
                    count: annotationCount,
                  })
                );
                onSuccess?.();
              },
              onSettled: batchOnSettled,
            }),
        }
      );
    }),
    download: ({ format, selectedAnnotationsIds }) => {
      download({
        format,
        selectedAnnotationsIds,
        queueName: activeQueueName,
      });
    },
    asyncDownload: ({ annotations }: ActionFnParams) => {
      const documents = annotations.map(a => a.document);
      downloadOriginalDocuments({ documents });
    },
    downloadAndExport: ({ format, selectedAnnotationsIds }) => {
      download(
        {
          format,
          selectedAnnotationsIds,
          toExport: true,
          queueName: activeQueueName,
        },
        { onSettled: batchOnSettled }
      );
    },
    addAttachment: retrieveUrl(({ urls }: { urls: Url[] }) => {
      const annotationUrl = urls[0];
      if (annotationUrl) openAddAttachmentModal(annotationUrl);
    }),
  };

  const { mutate: postponeAnnotations } = usePostponeAnnotations();
  const { mutate: deleteAnnotations } = useDeleteAnnotations();
  const { mutate: patchAnnotations } = usePatchAnnotations();

  const batchOnSuccess = (
    throwInfoKey?: 'annotationPostponedNoTab' | 'annotationDeletedNoTab'
  ) => {
    if (throwInfoKey) {
      dispatch(
        throwInfo(throwInfoKey, undefined, {
          count: action?.selectedUrls.length ?? 0,
        })
      );
    }
    onClose();
    action?.onSuccess?.();
  };

  const reprocessRequest = (status: 'confirmed' | 'to_review') =>
    patchAnnotations(
      getIDsFromURLs(action?.selectedUrls ?? []).map(id => ({
        id,
        payload: { status },
      })),
      {
        onSuccess: () => batchOnSuccess(),
        onSettled: () => batchOnSettled(),
      }
    );

  const batchRequests: Record<BatchActionWithModal, () => void> = {
    annotationSkip: () => {
      postponeAnnotations(getIDsFromURLs(action?.selectedUrls ?? []), {
        onSuccess: () => batchOnSuccess('annotationPostponedNoTab'),
        onSettled: () => batchOnSettled(),
      });
    },
    annotationDelete: () => {
      deleteAnnotations(getIDsFromURLs(action?.selectedUrls ?? []), {
        onSuccess: () => batchOnSuccess('annotationDeletedNoTab'),
        onSettled: () => batchOnSettled(),
      });
    },
    reExtract: () => {
      patchAnnotations(
        getIDsFromURLs(action?.selectedUrls ?? []).map(id => {
          const payload: AnnotationPayload = {
            rirPollId: null,
            status: 'importing',
            messages: [],
          };

          return { id, payload };
        }),
        {
          onSuccess: () => batchOnSuccess(),
          onSettled: () => batchOnSettled(),
        }
      );
    },
    reprocessToConfirmed: () => reprocessRequest('confirmed'),
    reprocessToToReview: () => reprocessRequest('to_review'),
  };

  return (
    <Context.Provider value={{ annotationActions: actions }}>
      {children}

      {moveModal}
      {purgeModal}
      {addAttachmentModal}
      <AnnotationsActionModal
        preventOnClose
        dataCyConfirm="modal-confirm-button"
        action={action?.name}
        annotationsLength={action?.selectedUrls.length ?? 0}
        onClose={onClose}
        onPrimaryActionClick={() => {
          if (action?.name) {
            batchRequests[action.name]();
          }
        }}
      />
    </Context.Provider>
  );
};

export { AnnotationActionsContext };

import { zodResolver } from '@hookform/resolvers/zod';
import { ID, WithEtag } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Schema, SchemaRule } from '@rossum/api-client/schemas';
import { IconToggleLeft, IconToggleRight } from '@rossum/ui/icons/tabler';
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Stack,
  SvgIcon,
} from '@rossum/ui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router';
import { z } from 'zod';
import { PageLayoutV2 } from '../../../../components/PageLayoutV2/PageLayoutV2';
import { boldText } from '../../../../lib/formaterValues';
import {
  throwError,
  throwInfo,
} from '../../../../redux/modules/messages/actions';
import { DeleteConfirmationDialog } from '../../../../ui/delete-confirmation-dialog/DeleteConfirmationDialog';
import { Header } from '../../../../ui/header/Header';
import { LeavingDialog } from '../../../../ui/leaving-dialog/LeavingDialog';
import { InitialCopilot } from '../../../rules/copilots/InitialCopilot';
import { useCreateRule } from '../../../rules/hooks/useCreateRule';
import { useDeleteRule } from '../../../rules/hooks/useDeleteRule';
import { useGetRule } from '../../../rules/hooks/useGetRule';
import { usePatchRule } from '../../../rules/hooks/usePatchRule';
import { RuleTemplate } from '../../../rules/hooks/useSuggestRule';
import { RuleFormType } from '../../../rules/types';
import { QueueSettingsBreadcrumbs } from '../../components/QueueSettingsBreadcrumbs';
import { RuleForm } from './RuleForm';

const RULE_FORM_ID = 'rule_form';

type RulePageProps = {
  rule?: SchemaRule;
  schema: WithEtag<Schema>;
  queue: Queue;
  parentPath: string;
};

export const ruleFormSchema = (intl: IntlShape) =>
  z.object({
    name: z.string().min(
      1,
      intl.formatMessage({
        id: 'features.queueSettings.rules.error.form.fieldRequired',
      })
    ),
    description: z.string(),
    triggerCondition: z
      .string()
      .min(
        1,
        intl.formatMessage({
          id: 'features.queueSettings.rules.error.form.fieldRequired',
        })
      )
      .max(
        500,
        intl.formatMessage({
          id: 'features.queueSettings.rules.error.form.formulaLength',
        })
      ),
    enabled: z.boolean(),
    ruleActions: z.array(
      z.object({
        id: z.string(),
        enabled: z.boolean(),
        action: z.object({
          type: z.string(),
          payload: z.unknown(),
        }),
      })
    ),
  });

const useRuleForm = ({ rule }: { rule?: SchemaRule | RuleTemplate }) => {
  const intl = useIntl();
  return useForm<z.TypeOf<ReturnType<typeof ruleFormSchema>>>({
    defaultValues: rule ?? {
      name: '',
      description: '',
      triggerCondition: '',
      enabled: true,
      ruleActions: [],
    },
    resolver: zodResolver(ruleFormSchema(intl)),
  });
};

export const RulePage = ({
  rule,
  schema,
  queue,
  parentPath,
}: RulePageProps) => {
  const intl = useIntl();
  const history = useHistory();

  const dispatch = useDispatch();
  const { hash } = useLocation();

  const { mutate: patchRule, isLoading: isPatching } = usePatchRule();

  const { mutate: createRule, isLoading: isCreating } = useCreateRule(
    schema.url
  );

  const { mutate: deleteRule, isLoading: isDeleting } = useDeleteRule();

  const formMethods = useRuleForm({ rule });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, isSubmitSuccessful },
  } = formMethods;

  const onDeleteSuccess = useCallback(() => {
    dispatch(throwInfo('ruleDeleteSuccess'));

    return history.replace(`${parentPath}/rules`);
  }, [dispatch, history, parentPath]);

  const onDeleteError = useCallback(
    () => dispatch(throwError('ruleDeleteFailed')),
    [dispatch]
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const buttons = [
    !rule ? null : (
      <Controller
        key="toggle-button-control"
        control={control}
        name="enabled"
        render={({ field }) => (
          <IconButton size="small" onClick={() => field.onChange(!field.value)}>
            {field.value ? (
              <SvgIcon color="success">
                <IconToggleRight />
              </SvgIcon>
            ) : (
              <SvgIcon>
                <IconToggleLeft />
              </SvgIcon>
            )}
          </IconButton>
        )}
      />
    ),
    !rule ? null : (
      <Button
        key="delete-rule-button"
        data-cy="delete-rule-btn"
        variant="outlined"
        color="secondary"
        startIcon={isDeleting && <CircularProgress color="inherit" size={20} />}
        disabled={isDeleting}
        onClick={() => setDeleteDialogOpen(true)}
      >
        {intl.formatMessage({
          id: 'features.queueSettings.rules.detail.delete',
        })}
      </Button>
    ),
    <Button
      key="save-rule-button"
      data-cy="save-rule-btn"
      variant="contained"
      type="submit"
      startIcon={
        (isPatching || isCreating) && (
          <CircularProgress color="inherit" size={20} />
        )
      }
      disabled={!isDirty || isPatching || isCreating}
      form={RULE_FORM_ID}
    >
      {intl.formatMessage({
        id: 'features.queueSettings.rules.detail.save',
      })}
    </Button>,
  ];

  const enabled = watch('enabled');
  const name = watch('name');
  const description = watch('description');

  const onSubmit = async (formValues: RuleFormType) => {
    const payload: RuleFormType = {
      ...formValues,
    };

    return rule
      ? patchRule(
          {
            patchedRuleId: rule.id,
            patchedRule: payload,
          },
          {
            onSuccess: () => {
              return dispatch(throwInfo('ruleUpdateSuccess'));
            },
            onError: () => dispatch(throwError('ruleUpdateFailed')),
          }
        )
      : createRule(payload, {
          onSuccess: createdRule => {
            history.replace(`${createdRule.id}/detail`);
            dispatch(throwInfo('ruleCreateSuccess'));
          },
          onError: () => dispatch(throwError('ruleCreationFailed')),
        });
  };

  useEffect(() => {
    // Reset form state when form is submitted (so that you don't see leaving dialog)
    // TODO: isSubmitSuccessful does not actually check success now at all.
    reset(undefined, { keepValues: true });
  }, [isSubmitSuccessful, reset]);

  const [initialCopilotOpen, setInitialCopilotOpen] = useState<boolean>(
    rule === undefined && hash === '#copilot'
  );

  const onBackButtonClicked = useCallback(() => {
    if (history.length > 1) {
      return history.goBack();
    }

    return history.push(`${parentPath}/rules`);
  }, [history, parentPath]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: 'features.queueSettings.rules.list.title',
        }),
        to: `${parentPath}/rules`,
      },
      {
        label:
          rule?.name ??
          intl.formatMessage({
            id: 'features.queueSettings.rules.newRule.title',
          }),
      },
    ];
  }, [intl, parentPath, rule?.name]);

  return (
    <PageLayoutV2
      renderHeader={params => (
        <Header
          scrollableDivRef={params.scrollableDivRef}
          onBackButtonClicked={onBackButtonClicked}
          breadcrumbs={
            <QueueSettingsBreadcrumbs
              breadcrumbs={breadcrumbs}
              queueName={queue.name}
              settingsPath={parentPath}
            />
          }
          buttons={buttons}
          title={name}
          description={description}
        />
      )}
    >
      <Stack
        px={4}
        py={4}
        spacing={4}
        id={RULE_FORM_ID}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <InitialCopilot
          schemaContent={schema.content ?? []}
          onSuccess={value => {
            // keepDefaultValues ensures that isDirty is set properly for leaving dialog
            reset(value, { keepDefaultValues: true });
            history.replace({ hash: undefined });
            setInitialCopilotOpen(false);
          }}
          onClose={() => {
            history.replace({ hash: undefined });
            setInitialCopilotOpen(false);
          }}
          open={initialCopilotOpen}
        />

        <FormProvider {...formMethods}>
          <RuleForm
            key={initialCopilotOpen.toString()}
            control={control}
            enabled={enabled}
            schema={schema}
          />
        </FormProvider>

        <LeavingDialog when={isDirty} />
        {rule ? (
          <DeleteConfirmationDialog
            title={intl.formatMessage({
              id: 'features.queueSettings.rules.deleteRuleConfirmation.title',
            })}
            description={intl.formatMessage(
              {
                id: 'features.queueSettings.rules.deleteRuleConfirmation.text',
              },
              {
                name,
                bold: boldText,
              }
            )}
            open={deleteDialogOpen}
            onCancel={() => setDeleteDialogOpen(false)}
            onConfirm={() => {
              setDeleteDialogOpen(false);
              deleteRule(
                {
                  deletedRuleId: rule.id,
                },
                {
                  onSuccess: onDeleteSuccess,
                  onError: onDeleteError,
                }
              );
            }}
          />
        ) : null}
      </Stack>
    </PageLayoutV2>
  );
};

export const RulePageDetail = ({
  ruleId,
  schema,
  queue,
  parentPath,
}: RulePageProps & { ruleId: ID }) => {
  const { data: rule, isLoading } = useGetRule(ruleId);

  return rule ? (
    <RulePage
      schema={schema}
      queue={queue}
      parentPath={parentPath}
      rule={rule}
    />
  ) : isLoading ? (
    <LinearProgress />
  ) : (
    <Redirect to={`${parentPath}/rules`} />
  );
};

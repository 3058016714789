import { yupResolver } from '@hookform/resolvers/yup';
import { HookPatchPayload } from '@rossum/api-client/hooks';
import {
  ExtensionEvent,
  extensionWebhookType,
  Hook,
  rossumStore,
} from '@rossum/api-client/hooks';
import { InfoOutlined } from '@rossum/ui/icons';
import { Box, Stack, Tooltip } from '@rossum/ui/material';
import { MutateOptions } from '@tanstack/react-query';
import createDOMPurify from 'dompurify';
import { get, isEqual, orderBy } from 'lodash';
import EyeIcon from 'mdi-react/EyeIcon';
import EyeOffIcon from 'mdi-react/EyeOffIcon';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { pickBy } from 'remeda';
import * as yup from 'yup';
import { usePatchHook } from '../../../../business/hooks/usePatchHook';
import ValidationInput from '../../../../components/ReactHookForm/ValidationInput';
import ControlledTextArea from '../../../../components/ReactHookForm/ValidationTextArea';
import HiddingButton from '../../../../components/UI/HiddingButton';
import {
  docsLinks,
  EXTERNAL_CRONTAB_VALIDATOR,
} from '../../../../constants/values';
import { EventMultiSelect } from '../../../../features/extensions/events/components/EventMultiSelect';
import { QueueMultiSelect } from '../../../../features/queues/select-queue/QueueMultiSelect';
import {
  italicText,
  linebreak,
  link,
  white,
} from '../../../../lib/formaterValues';
import { useLocalStorageBooleanFlag } from '../../../../lib/hooks';
import { sanitizeLinks } from '../../../../lib/htmlSanitization';
import { deleteExtension } from '../../../../redux/modules/extensions/actions';
import { EXTENSIONS_ADVANCED_SETTINGS_EXPANDED } from '../../../../redux/modules/localStorage/actions';
import { openModal } from '../../../../redux/modules/modal/actions';
import { HelmetComponent } from '../../../../routes/HelmetComponent';
import { Url } from '../../../../types/basic';
import { HookFormObjectValue } from '../../../../types/CustomTypes/HookFormObject';
import { Extension } from '../../../../types/extensions';
import { State } from '../../../../types/state';
import { Users } from '../../../../types/users';
import { LeavingDialog } from '../../../../ui/leaving-dialog/LeavingDialog';
import PaperBox from '../../../../ui/paper-box/PaperBox';
import { ConfigAppProvider } from '../../../Extensions/containers/ConfigApp/context/ConfigAppProvider';
import AdvancedSettings from '../../../Extensions/containers/CreateExtension/components/AdvancedSettings';
import { getIcon, validateCronString } from '../../../Extensions/lib/helpers';
import { NextLineWithIcon } from '../../../Extensions/lib/shared';
import { useJSONfield } from '../../../Extensions/lib/useJSONfield';
import FormLabel from '../../../User/components/FormLabel';
import {
  isPublicFunction,
  isPublicWebhook,
  webhookUrlValidation,
} from '../../helpers';
import styles from '../../style.module.sass';
import { ExtensionSettingsFooter } from './ExtensionSettingsFooter';
import { ExtensionSettingsHeader } from './ExtensionSettingsHeader';
import {
  ExtensionData,
  getDefaultValues,
  getSecretsWithPlaceholders,
  NO_CHANGE_SECRET_PLACEHOLDER,
} from './helpers';
import ViewCodePart from './ViewCodePart';

const DOMPurify = createDOMPurify();

DOMPurify.addHook('afterSanitizeAttributes', sanitizeLinks);

type StateProps = {
  organizationUsers: Users;
};

type OwnProps = {
  selectedExtension: Extension;
  url: string;
};

type OpenDeleteModal = (_values: {
  url: Url;
  name: string;
  queues: Array<Url>;
}) => void;

type DispatchProps = {
  openDeleteModal: OpenDeleteModal;
};

type Props = DispatchProps & OwnProps & StateProps;

const sortQueues = (queues: Array<Url>) => orderBy(queues, undefined, ['asc']);

const validationSchema = (intl: IntlShape) =>
  yup.object().shape({
    active: yup.boolean().required(),
    description: yup
      .string()
      .max(
        1000,
        intl.formatMessage({
          id: 'containers.settings.extensions.createExtension.description.tooLong',
        })
      )
      .notRequired()
      .nullable(),
    events: yup.array().required(
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.events.required',
      })
    ),
    name: yup.string().required(
      intl.formatMessage({
        id: 'containers.settings.extensions.createExtension.name.required',
      })
    ),
    queues: yup.array(),
    type: yup.string().required('required'),
    secret: yup.string().nullable().notRequired(),
    url: yup.string().when('type', {
      is: extensionWebhookType,
      then: yup.string().when(['private'], {
        is: true,
        then: yup.string().nullable().notRequired(),
        otherwise: webhookUrlValidation(intl),
      }),
      otherwise: yup.string().nullable().notRequired(),
    }),
    sideload: yup.array(),
    tokenOwner: yup.string().nullable(),
    settings: yup.object(),
    runtime: yup.string(),
    schedule: yup
      .string()
      .when('events', (events: ExtensionEvent[], schema: yup.StringSchema) =>
        events.includes('invocation.scheduled')
          ? schema.test(
              'wrongFormat',
              intl.formatMessage({
                id: 'containers.settings.extensions.createExtension.schedule.wrongFormat',
              }),
              validateCronString
            )
          : schema
      ),
  });

const ExtensionSettings = ({
  openDeleteModal,
  organizationUsers,
  selectedExtension,
  url,
}: Props) => {
  const [showSecret, setShowSecret] = useState(false);

  const isFromStore = selectedExtension.extensionSource === rossumStore;
  const [showAdvancedSettings, setShowAdvancedSettings] =
    useLocalStorageBooleanFlag(EXTENSIONS_ADVANCED_SETTINGS_EXPANDED, false);
  const intl = useIntl();

  const pickChangedSecrets = (secrets: HookFormObjectValue | undefined) =>
    pickBy(
      secrets || {},
      secretValue => secretValue !== NO_CHANGE_SECRET_PLACEHOLDER
    );

  const secretsWithPlaceholders = useMemo(
    () =>
      getSecretsWithPlaceholders({
        secretsSchemaProperties: selectedExtension.secretsSchema?.properties,
        settingsSchemaProperties: selectedExtension.settingsSchema?.properties,
      }),
    [
      selectedExtension.secretsSchema?.properties,
      selectedExtension.settingsSchema?.properties,
    ]
  );

  const extensionIsPublicFunction = isPublicFunction(selectedExtension);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
    setError,
    trigger,
  } = useForm<ExtensionData>({
    mode: 'onTouched',
    defaultValues: getDefaultValues(selectedExtension),
    resolver: yupResolver(validationSchema(intl)),
  });
  const watchFields = watch();

  // used as fallback in case of some queues being in deleting state
  // so we do not need to filter assigned queues
  const allQueuesLoaded = useSelector(
    (state: State) => state.workspaces.isLoaded && state.queues.loaded
  );

  const useWebhookPrivateFields = isPublicWebhook(selectedExtension);

  const useFunctionCode = isPublicFunction(selectedExtension);

  const noChanges =
    !allQueuesLoaded ||
    isEqual(
      {
        ...watchFields,
        description: watchFields.description || '',
        queues: sortQueues(watchFields.queues),
        secret: watchFields.secret || '',
      },
      {
        schedule: selectedExtension.config.schedule?.cron ?? '',
        active: selectedExtension.active,
        type: selectedExtension.type,
        name: selectedExtension.name,
        description: selectedExtension.description || '',
        events: selectedExtension.events,
        queues: sortQueues(selectedExtension.queues),
        url: (useWebhookPrivateFields && selectedExtension.config.url) || '',
        secret:
          (useWebhookPrivateFields && selectedExtension.config.secret) || '',
        tokenOwner: selectedExtension.tokenOwner,
        settings: selectedExtension.settings,
        sideload: selectedExtension.sideload,
        secrets: secretsWithPlaceholders,
        private: selectedExtension.config.private,
        runtime: useFunctionCode ? selectedExtension.config.runtime : undefined,
        payloadLoggingEnabled:
          selectedExtension.config.payloadLoggingEnabled ?? false,
      }
    );

  const { mutate, isLoading } = usePatchHook();

  const onSubmit = (mutateOptions?: MutateOptions<Hook>) =>
    handleSubmit(
      ({
        url,
        secret,
        secrets,
        queues,
        schedule,
        runtime,
        payloadLoggingEnabled,
        ...data
      }: ExtensionData) => {
        const scheduleConfig = schedule ? { schedule: { cron: schedule } } : {};
        const runtimeConfig = extensionIsPublicFunction ? { runtime } : {};

        const extension = {
          ...data,
          queues,
          secrets: pickChangedSecrets(secrets),
          ...(useWebhookPrivateFields
            ? {
                config: {
                  url,
                  secret: secret || null,
                  payloadLoggingEnabled,
                  ...scheduleConfig,
                },
              }
            : {
                config: {
                  payloadLoggingEnabled,
                  ...scheduleConfig,
                  ...runtimeConfig,
                },
              }),
        };

        return mutate(
          {
            hookId: selectedExtension.id,
            // @ts-expect-error because of redux vs apiClient types mismatch
            payload: extension,
            meta: {
              // do not display message when user is retrying to save failed function
              withMessage: selectedExtension.status !== 'failed',
            },
          },
          mutateOptions
        );
      }
    );

  const updateExtensionDetail = (payload: HookPatchPayload) =>
    mutate({
      hookId: selectedExtension.id,
      payload,
      meta: { withMessage: false },
    });

  const settingsJSONField = useJSONfield<ExtensionData>({
    name: 'settings',
    trigger,
    setError,
    control,
    defaultValue: selectedExtension.settings,
    saveField: (name, value) =>
      updateExtensionDetail({ [name]: value, type: selectedExtension.type }),
    schema: selectedExtension.settingsSchema,
  });

  const secretsJSONField = useJSONfield<ExtensionData>({
    name: 'secrets',
    trigger,
    setError,
    control,
    schema: selectedExtension.secretsSchema,
  });

  const showSecretsSection = !(
    isFromStore && !selectedExtension.secretsSchema?.properties
  );

  return (
    <div className={styles.Wrapper} data-page-title="extension-settings">
      <HelmetComponent
        dynamicName={selectedExtension.name}
        translationKey="features.routes.pageTitles.extensions.edit"
      />
      <PaperBox>
        <ExtensionSettingsHeader
          selectedExtension={selectedExtension}
          isValid={isValid}
          onSubmit={onSubmit()}
          control={control}
        />
        <div className={styles.WidthLimit}>
          <form
            onSubmit={onSubmit({
              onSuccess: () => {
                secretsJSONField.onChange(
                  JSON.stringify(secretsWithPlaceholders, null, 2)
                );
              },
            })}
          >
            {isFromStore && (
              <div className={styles.UpperPart}>
                <div className={styles.UpperPartTitle}>
                  {getIcon(
                    selectedExtension.type,
                    isPublicFunction(selectedExtension)
                      ? selectedExtension.config?.runtime
                      : undefined,
                    { size: 20 }
                  )}
                  {selectedExtension.name}
                </div>
                <div className={styles.FromStoreDescription}>
                  {selectedExtension.description}
                </div>
              </div>
            )}
            {isFromStore && selectedExtension.guide && (
              <Box
                className={styles.UpperPart}
                sx={{
                  '& button': {
                    color: theme =>
                      `${theme.palette.primary.contrastText} !important`,
                    backgroundColor: theme =>
                      `${theme.palette.primary.main} !important`,
                  },
                }}
              >
                <div className={styles.UpperPartTitle}>
                  <FormattedMessage id="containers.settings.extensions.guide" />
                </div>
                <div
                  className={styles.Guide}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(selectedExtension.guide, {
                      ADD_TAGS: ['iframe'],
                      ADD_ATTR: ['allow', 'allowfullscreen'],
                    }),
                  }}
                />
              </Box>
            )}
            {!isFromStore && useFunctionCode && (
              <ViewCodePart
                selectedExtension={selectedExtension}
                isFromStore={isFromStore}
                routeUrl={url}
              />
            )}
            <div className={styles.FormLabel}>
              <FormattedMessage
                id={
                  isFromStore
                    ? 'containers.settings.extensions.createExtension.yourName.label'
                    : 'containers.settings.extensions.createExtension.name.label'
                }
              />
            </div>
            <div className={styles.NameInputWidthLimiter}>
              <ValidationInput<ExtensionData>
                control={control}
                name="name"
                getErrorMessage={(_, { message }) => message ?? ''}
                placeholder={intl.formatMessage({
                  id: `containers.settings.extensions.createExtension.name.placeholder`,
                })}
                dataCy="extensions-name-input"
              />
            </div>
            {!isFromStore && (
              <>
                <div className={styles.LabelWithOptional}>
                  <div className={styles.FormLabel}>
                    <FormattedMessage id="containers.settings.extensions.createExtension.description.label" />
                  </div>
                  <span className={styles.Optional}>
                    <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
                  </span>
                </div>
                <ControlledTextArea<ExtensionData>
                  className={styles.Textarea}
                  control={control}
                  name="description"
                  placeholder={intl.formatMessage({
                    id: 'containers.settings.extensions.createExtension.description.placeholder',
                  })}
                  getErrorMessage={(_, { message }) => message ?? ''}
                  dataCy="extensions-description-input"
                />
                <div className={styles.FormLabel}>
                  <FormLabel>
                    <Stack direction="row" gap={0.5}>
                      <FormattedMessage id="containers.settings.extensions.createExtension.events.label" />
                      <Tooltip
                        title={
                          <FormattedMessage
                            id="containers.settings.extensions.function.events.tooltip"
                            values={{
                              link: link(docsLinks.webhookEvents, {
                                color: 'white',
                              }),
                              linebreak,
                            }}
                          />
                        }
                        placement="top"
                      >
                        <InfoOutlined fontSize="small" />
                      </Tooltip>
                    </Stack>
                  </FormLabel>
                </div>
                <div className={styles.SubLabel}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.events.subLabel" />
                </div>
                <Controller
                  control={control}
                  name="events"
                  render={({ field }) => <EventMultiSelect {...field} />}
                />
                <div className={styles.ErrorMessagePlaceholder}>
                  {errors.events && (
                    <span
                      data-cy="error-message-eventsDropdown"
                      className={styles.ErrorMessage}
                    >
                      <FormattedMessage id="containers.settings.extensions.createExtension.events.required" />
                    </span>
                  )}
                </div>
              </>
            )}
            {watchFields.events.includes('invocation.scheduled') && (
              <>
                <div className={styles.FormLabel}>
                  <FormLabel>
                    <FormattedMessage id="containers.settings.extensions.createExtension.schedule.label" />
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="containers.settings.extensions.createExtension.schedule.tooltip"
                          values={{
                            link: link(EXTERNAL_CRONTAB_VALIDATOR, {
                              color: 'white',
                            }),
                            linebreak,
                          }}
                        />
                      }
                      placement="top"
                    >
                      <InfoOutlined />
                    </Tooltip>
                  </FormLabel>
                </div>
                <div className={styles.SubLabel}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.schedule.subLabel" />
                </div>
                <ValidationInput
                  control={control}
                  name="schedule"
                  placeholder="*/10 * * * *"
                  dataCy="extensions-webhook-schedule-input"
                  getErrorMessage={(_, { message }) => message ?? ''}
                />
              </>
            )}
            <div className={styles.FormLabel}>
              <FormattedMessage id="containers.settings.extensions.createExtension.queues.label" />
            </div>
            <div className={styles.SubLabel}>
              <FormattedMessage
                id="containers.settings.extensions.createExtension.queues.subLabel"
                values={{
                  linebreak,
                  italicText,
                }}
              />
            </div>
            <Controller
              control={control}
              name="queues"
              render={({ field }) => (
                <QueueMultiSelect {...field} withLabel={false} />
              )}
            />
            {useWebhookPrivateFields && (
              <>
                <div className={styles.FormLabel}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.label" />
                </div>
                <div className={styles.SubLabel}>
                  <FormattedMessage id="containers.settings.extensions.createExtension.webhook.url.subLabel" />
                </div>
                <ValidationInput<ExtensionData>
                  name="url"
                  control={control}
                  getErrorMessage={(_, { message }) => message ?? ''}
                  dataCy="extensions-webhook-url-input"
                  placeholder={intl.formatMessage({
                    id: `containers.settings.extensions.createExtension.webhook.url.placeholder`,
                  })}
                />
                <div className={styles.LabelWithOptional}>
                  <div>
                    <div className={styles.FormLabel}>
                      <FormattedMessage id="containers.settings.extensions.createExtension.webhook.sharedSecret.label" />
                    </div>
                    <div className={styles.SubLabel}>
                      <FormattedMessage
                        id="containers.settings.extensions.createExtension.webhook.sharedSecret.subLabel"
                        values={{ link: link(docsLinks.validatingPayloads) }}
                      />
                    </div>
                  </div>
                  <span className={styles.Optional}>
                    <FormattedMessage id="containers.settings.extensions.createExtension.description.fieldDescription" />
                  </span>
                </div>
                <ValidationInput<ExtensionData>
                  autoComplete="new-password"
                  name="secret"
                  type={showSecret ? 'text' : 'password'}
                  rightIconProps={{
                    onClick: () => setShowSecret(!showSecret),
                    'data-cy': 'eye-icon',
                  }}
                  rightIcon={showSecret ? <EyeOffIcon /> : <EyeIcon />}
                  dataCy="extensions-webhook-secret-input"
                  control={control}
                  getErrorMessage={(_, { message }) => message ?? ''}
                  placeholder={intl.formatMessage({
                    id: `containers.settings.extensions.createExtension.webhook.secret.placeholder`,
                  })}
                />
              </>
            )}
            <AdvancedSettings<ExtensionData>
              control={control}
              allOrgUsers={organizationUsers.list}
              showAdvancedSettings={showAdvancedSettings}
              setShowAdvancedSettings={setShowAdvancedSettings}
              users={organizationUsers.list}
              settingsJSONField={settingsJSONField}
              secretsJSONField={secretsJSONField}
              isFromStore={isFromStore}
              readMoreUrl={selectedExtension.readMoreUrl}
              name={selectedExtension.name}
              selectedExtension={selectedExtension}
              showSecretsSection={showSecretsSection}
            />
            {isFromStore && useFunctionCode && (
              <ViewCodePart
                selectedExtension={selectedExtension}
                isFromStore={isFromStore}
                routeUrl={url}
              />
            )}
            <div className={styles.ButtonWrapper}>
              <HiddingButton
                disabled={
                  get(selectedExtension, 'status') === 'pending' || !isValid
                }
                hideCondition={noChanges}
                messageId="containers.settings.queues.save"
                dataCy={`extensions-${selectedExtension.type}-confirm-button`}
                type="submit"
                isLoading={isLoading}
              />
            </div>
            <LeavingDialog when={!noChanges} />
          </form>
        </div>
        <ExtensionSettingsFooter
          selectedExtension={selectedExtension}
          openDeleteModal={openDeleteModal}
        />
      </PaperBox>
    </div>
  );
};

const ExtensionSettingsWithConfigAppContext = (props: Props) => {
  return (
    <ConfigAppProvider selectedExtension={props.selectedExtension}>
      {timestamp => {
        return <ExtensionSettings {...props} key={timestamp} />;
      }}
    </ConfigAppProvider>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  organizationUsers: state.users,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  openDeleteModal: ({ url, name, queues }) =>
    dispatch(
      openModal({
        textId: 'extensionDelete',
        confirmAction: () => deleteExtension(url),
        confirmType: 'Danger',
        values: {
          white,
          name,
          queuesCount: queues.length,
          nextLine: NextLineWithIcon,
        },
      })
    ),
});

const ExtensionSettingsRoute = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  State
>(
  mapStateToProps,
  mapDispatchToProps
)(ExtensionSettingsWithConfigAppContext);

export default ExtensionSettingsRoute;

import { Hook } from '@rossum/api-client/hooks';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Paper,
  paperClasses,
  Skeleton,
  Stack,
  TextField,
} from '@rossum/ui/material';
import { useIntl } from 'react-intl';

type HooksAutocompleteProps = {
  setHookFilter: (hooks: Array<string>) => void;
  value: string | undefined;
  extensions: Array<Hook> | undefined;
  isFetchingExtensions: boolean;
  isLoadingExtensions: boolean;
  width: number;
};

const MENU_LIST_MIN_HEIGHT = 450;
const MENU_CONTENT_MAX_WIDTH = 600;

export const HooksAutocomplete = ({
  extensions = [],
  isFetchingExtensions,
  isLoadingExtensions,
  value = '',
  setHookFilter,
  width,
}: HooksAutocompleteProps) => {
  const intl = useIntl();
  const hasValue = !!value;

  const existingSelected = extensions.find(e => `${e.id}` === value);

  const existingNotFound = Boolean(
    extensions.length > 0 && value && !existingSelected
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FormControl variant="outlined" sx={{ width }} error={existingNotFound}>
        <Autocomplete
          size="small"
          fullWidth
          value={
            existingSelected
              ? {
                  value,
                  label: `${existingSelected?.name}`,
                }
              : {
                  value: '',
                  label: '',
                }
          }
          onChange={(_, value) => {
            setHookFilter([value?.value ?? '']);
          }}
          disableClearable={isFetchingExtensions}
          autoHighlight
          renderInput={params => (
            <TextField
              {...params}
              label={intl.formatMessage({
                id: 'containers.settings.extensions.logs.filters.extension.label',
              })}
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  isLoadingExtensions && hasValue ? (
                    <Skeleton variant="text" width="200%" />
                  ) : (
                    params.InputProps.startAdornment
                  ),
                endAdornment: isFetchingExtensions ? (
                  <Stack sx={{ mr: 0 }}>
                    <CircularProgress size={20} />
                    {params.InputProps.endAdornment}
                  </Stack>
                ) : (
                  params.InputProps.endAdornment
                ),
              }}
            />
          )}
          ListboxProps={{
            style: {
              maxHeight: MENU_LIST_MIN_HEIGHT,
            },
          }}
          PaperComponent={({ children }) => (
            <Paper
              elevation={6}
              sx={{
                maxHeight: MENU_LIST_MIN_HEIGHT,
                maxWidth: MENU_CONTENT_MAX_WIDTH,
                width: 'fit-content',
                [`& .${paperClasses.root}`]: {},
              }}
            >
              {children}
            </Paper>
          )}
          options={extensions.map(e => ({
            value: String(e.id),
            label: e.name,
          }))}
          getOptionLabel={option =>
            option.value.length ? `${option.value}: ${option.label}` : ''
          }
          renderOption={(props, option, _state, ownerState) => {
            // eslint-disable-next-line react/prop-types
            const { key, ...optionProps } = props;

            return (
              <MenuItem
                key={key}
                value={`${option.value}`}
                data-cy={`logs-hook-menu-item-${option.value}`}
                selected={`${option.value}` === value}
                {...optionProps}
              >
                {ownerState.getOptionLabel(option)}
              </MenuItem>
            );
          }}
          disabled={isLoadingExtensions}
          data-cy="extensions-logs-select"
        />
        {existingNotFound ? (
          <FormHelperText>
            {intl.formatMessage({
              id: 'containers.settings.extensions.logs.filters.extension.notFoundError',
            })}
          </FormHelperText>
        ) : null}
      </FormControl>
    </Stack>
  );
};

import { ExternalToast, toast, Toaster } from 'sonner';
import { ToasterMessage, ToasterMessageProps } from './ToasterMessage';
import { Severity } from './types';

const DEFAULT_TIMEOUT = 6000;

const renderComponent =
  (severity: Severity) =>
  (
    {
      title,
      description,
      isDismissible = true,
      onClose,
      toastAction,
      id,
    }: Omit<ToasterMessageProps, 'severity'>,
    options?: ExternalToast
  ) => {
    toast.custom(
      id => (
        <ToasterMessage
          title={title}
          description={description}
          isDismissible={isDismissible}
          severity={severity}
          onClose={() => {
            onClose?.();
            toast.dismiss(id);
          }}
          toastAction={toastAction}
        />
      ),
      {
        id,
        duration: options?.duration ?? DEFAULT_TIMEOUT,
        ...options,
      }
    );
  };

export const notify = {
  info: renderComponent('info'),
  success: renderComponent('success'),
  error: renderComponent('error'),
  warning: renderComponent('warning'),
};

export const ToasterContainer = () => <Toaster position="bottom-center" />;

import { zodResolver } from '@hookform/resolvers/zod';
import { IconTable, IconTrash } from '@rossum/ui/icons/tabler';
import {
  Button,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { DSResultsResponseResultsItem } from 'libs/mdh-api-client/src';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { fromEntries, omit } from 'remeda';
import { v4 } from 'uuid';
import { z } from 'zod';
import { PageLayoutV2 } from '../../../components/PageLayoutV2/PageLayoutV2';
import TextFieldControl from '../../../components/ReactHookForm/controls/TextFieldControl';
import { button } from '../../../lib/formaterValues';
import { ContentGroup } from '../../../ui/content-group/ContentGroup';
import { LeavingDialog } from '../../../ui/leaving-dialog/LeavingDialog';
import { notify } from '../../toaster';
import { NotFound } from '../components/NotFound';
import { useDatasetContext } from '../context';
import { DatasetsHeader } from '../header';
import { useCreateDataset } from '../hooks/useCreateDataset';
import {
  GET_DATASET_DETAILS_QUERY_KEYS,
  useGetDatasetDetails,
  useGetDatasetDetailsMutation,
} from '../hooks/useGetDatasetDetails';
import { useGetDatasetParams } from '../hooks/useGetDatasetParams';
import { GET_DATASETS_QUERY_KEYS } from '../hooks/useGetDatasets';
import { useRecreateDataset } from '../hooks/useRecreateDataset';
import { useUpdateDataset } from '../hooks/useUpdateDataset';
import {
  datasetSettingsPath,
  datasetSettingsRoute,
  datasetTablePath,
} from '../routes';
import { pageSpacingStyles } from '../styles';
import { removeIdColumnKeys, transformNameIntoId } from '../utils';
import { DeleteButton } from './DeleteButton';
import { LoadingContent } from './LoadingContent';

const FORM_ID = 'dataset-settings';
const PLACEHOLDER_DATA = 'placeholder_data';

type SettingsForm = {
  label: string;
  columnNames: { old: string; new: string }[];
  data: DSResultsResponseResultsItem[];
};
const emptyForm: SettingsForm = {
  columnNames: [],
  data: [],
  label: '',
};

const formSchema = (intl: IntlShape) =>
  z.object({
    label: z.string().min(1, {
      message: intl.formatMessage({
        id: 'features.datasets.settings.error.required',
      }),
    }),
    columnNames: z
      .array(
        z.object({
          old: z.string(),
          new: z.string().min(1, {
            message: intl.formatMessage({
              id: 'features.datasets.settings.error.required',
            }),
          }),
        })
      )
      .min(1),
    data: z.array(z.record(z.string())),
  });

const normalizeDatasetResponse = ({
  data,
  datasetName,
}: {
  data: DSResultsResponseResultsItem[];
  datasetName: string;
}): SettingsForm => {
  const firstItem = data[0];
  const columnNames = firstItem
    ? removeIdColumnKeys(Object.keys(firstItem)).map(c => ({
        old: c,
        new: c,
      }))
    : [];

  return {
    columnNames,
    data,
    label: datasetName,
  };
};

export const DatasetSettings = () => {
  const intl = useIntl();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { currentDatasetId, currentDatasetName } = useGetDatasetParams({
    route: datasetSettingsRoute(),
  });

  const { registerTask, datasetTasks } = useDatasetContext();

  const { data } = useGetDatasetDetails({ name: currentDatasetName });

  const isDatasetMissing = data ? data.length === 0 : false;

  const {
    mutateAsync: getDatasetDetails,
    isLoading: isLoadingDataset,
    isIdle,
  } = useGetDatasetDetailsMutation();

  const { mutate: updateDataset, isLoading: isDatasetUpdating } =
    useUpdateDataset();

  const { mutate: createDataset, isLoading: isCreatingDataset } =
    useCreateDataset();

  const { mutate: recreateDataset, isLoading: isRecreatingDataset } =
    useRecreateDataset();

  const getLatestFormDefaults = async () => {
    if (currentDatasetName) {
      return getDatasetDetails({ name: currentDatasetName }).then(data =>
        normalizeDatasetResponse({ data, datasetName: currentDatasetName })
      );
    }
    return emptyForm;
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, errors, dirtyFields },
  } = useForm<SettingsForm>({
    defaultValues: getLatestFormDefaults,
    resolver: zodResolver(formSchema(intl)),
  });

  const isPollingTask = datasetTasks.some(
    task =>
      task.datasetName === currentDatasetName ||
      task.datasetName === watch('label')
  );

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'columnNames',
  });

  const normalizeToUpdate = (form: SettingsForm) => {
    const dirtyColumns = form.columnNames.filter(c => c.new !== c.old);

    const dataColumns = removeIdColumnKeys(Object.keys(form.data[0] ?? {}));
    const deletedColumns = dataColumns.filter(name =>
      form.columnNames.every(c => c.old !== name)
    );

    const normalizedDataset = dirtyColumns.reduce((acc, col) => {
      return acc.map(item => {
        const value = item[col.old];
        // update existing value
        if (value) return { ...omit(item, [col.old]), [col.new]: value };

        // add new value
        return { ...item, [col.new]: '' };
      });
    }, form.data);

    return deletedColumns.length
      ? deletedColumns.reduce(
          (acc, col) => acc.map(item => omit(item, [col])),
          normalizedDataset
        )
      : normalizedDataset;
  };

  const normalizeToCreate = (form: SettingsForm) => {
    const datasetEmptyRow = fromEntries(
      form.columnNames.map(c => [c.new, PLACEHOLDER_DATA])
    );

    return [datasetEmptyRow];
  };

  const resetFormProperly = async () => {
    if (currentDatasetName) {
      const id = transformNameIntoId(currentDatasetName);
      const userLeftPage = id
        ? history.location.pathname !== datasetSettingsPath(id)
        : false;

      if (!userLeftPage) {
        getLatestFormDefaults().then(data => {
          reset(data);
        });
      }
    }
  };

  const submitDataset = (form: SettingsForm) => {
    const shouldRecreate = !!dirtyFields.label;

    if (shouldRecreate && currentDatasetName) {
      recreateDataset({
        oldName: currentDatasetName,
        newName: form.label,
        data: normalizeToUpdate(form),
      });
      return;
    }

    const mutationPurpose = currentDatasetName ? 'update' : 'create';
    const normalizedDataset =
      mutationPurpose === 'update'
        ? normalizeToUpdate(form)
        : normalizeToCreate(form);

    const payload = {
      datasetName: form.label,
      data: normalizedDataset,
    };
    const datasetId = transformNameIntoId(form.label);
    const onSuccess = (response: {
      taskId: string | null;
      datasetName: string;
    }) => {
      if (response.taskId) {
        registerTask({
          type: mutationPurpose,
          taskId: response.taskId,
          datasetName: response.datasetName,
          onSuccess: () => {
            notify.success({
              title: intl.formatMessage(
                {
                  id: `features.datasets.context.taskNotification.${mutationPurpose}.success.title`,
                },
                mutationPurpose === 'create' && datasetId
                  ? {
                      datasetName: form.label,
                      button: button(() =>
                        history.push(datasetSettingsPath(datasetId), {
                          textDecoration: 'underline',
                        })
                      ),
                    }
                  : { datasetName: form.label }
              ),
            });

            resetFormProperly();
            if (mutationPurpose === 'create') {
              queryClient.invalidateQueries({
                queryKey: GET_DATASETS_QUERY_KEYS,
              });
            } else if (currentDatasetName)
              queryClient.invalidateQueries({
                queryKey: [
                  ...GET_DATASET_DETAILS_QUERY_KEYS,
                  currentDatasetName,
                ],
              });
          },
          onFailed: response => {
            // expected message is "Dataset already exists"
            const isDuplicate = response.data.message
              ?.toLowerCase()
              .includes('already exists');

            notify.error({
              title: intl.formatMessage(
                {
                  id: `features.datasets.context.taskNotification.${mutationPurpose}.error.title`,
                },
                { datasetName: form.label }
              ),
              description: isDuplicate
                ? intl.formatMessage({
                    id: 'features.datasets.context.taskNotification.create.error.duplicate',
                  })
                : undefined,
            });
          },
        });
      }
    };

    if (mutationPurpose === 'update') {
      updateDataset(payload, { onSuccess });
    } else createDataset(payload, { onSuccess });
  };

  const isFormLoading = !isIdle && isLoadingDataset;

  const isUpdating =
    isPollingTask ||
    isDatasetUpdating ||
    isCreatingDataset ||
    isRecreatingDataset;

  return (
    <PageLayoutV2
      renderHeader={props => (
        <DatasetsHeader
          {...props}
          title={
            !isDatasetMissing
              ? intl.formatMessage(
                  {
                    id: `features.datasets.settings.header.${currentDatasetName ? 'datasetTitle' : 'createTitle'}`,
                  },
                  currentDatasetName ? { datasetName: currentDatasetName } : {}
                )
              : ''
          }
          description=""
          breadcrumbs={
            !isDatasetMissing
              ? currentDatasetName
                ? [
                    {
                      label: currentDatasetName,
                      to: currentDatasetId
                        ? datasetTablePath(currentDatasetId)
                        : undefined,
                    },
                    {
                      label: intl.formatMessage({
                        id: 'features.datasets.settings.breadcrumbText',
                      }),
                    },
                  ]
                : [
                    {
                      label: intl.formatMessage({
                        id: 'features.datasets.createNew.breadcrumbText',
                      }),
                    },
                  ]
              : []
          }
          buttons={
            !isDatasetMissing
              ? [
                  ...(currentDatasetId
                    ? [
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'features.datasets.settings.header.button.tableTooltip',
                          })}
                          key={v4()}
                        >
                          <IconButton
                            onClick={() => {
                              history.push(datasetTablePath(currentDatasetId));
                            }}
                          >
                            <SvgIcon>
                              <IconTable />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>,
                      ]
                    : []),
                  <DeleteButton
                    key={v4()}
                    datasetName={currentDatasetName ?? watch('label')}
                    shouldRedirect={!currentDatasetName}
                  />,
                  <Button
                    key={v4()}
                    variant="contained"
                    type="submit"
                    form={FORM_ID}
                    disabled={!isDirty || isUpdating}
                    endIcon={isUpdating ? <CircularProgress size={14} /> : null}
                  >
                    {intl.formatMessage({
                      id: 'features.datasets.settings.header.button.save',
                    })}
                  </Button>,
                ]
              : []
          }
        />
      )}
    >
      <LeavingDialog when={!isPollingTask && isDirty} />
      {isFormLoading ? (
        <LoadingContent />
      ) : !isDatasetMissing ? (
        <Stack
          sx={pageSpacingStyles}
          gap={2}
          component="form"
          id={FORM_ID}
          onSubmit={handleSubmit(submitDataset)}
        >
          <ContentGroup
            title={intl.formatMessage({
              id: 'features.datasets.settings.form.idSection.title',
            })}
            description={
              <Stack spacing={1}>
                <Typography variant="inherit">
                  {intl.formatMessage({
                    id: 'features.datasets.settings.form.idSection.description',
                  })}
                </Typography>
                {/* TODO: Link */}
                <Typography component={Link} href="#" variant="inherit">
                  {intl.formatMessage({
                    id: 'features.datasets.settings.form.idSection.link',
                  })}
                </Typography>
              </Stack>
            }
          >
            <Stack gap={2}>
              <TextFieldControl
                ControllerProps={{ control, name: 'label' }}
                label="Label"
                FieldLabelProps={{
                  layout: 'floating',
                }}
                disabled={isUpdating}
              />
            </Stack>
          </ContentGroup>
          <ContentGroup
            title={intl.formatMessage({
              id: 'features.datasets.settings.form.columnsSection.title',
            })}
            description={intl.formatMessage({
              id: 'features.datasets.settings.form.columnsSection.description',
            })}
          >
            <Stack gap={3}>
              {fields.length > 0 ? (
                fields.map((item, index) => {
                  return (
                    <Stack direction="row" key={item.id} gap={1}>
                      <Controller
                        control={control}
                        name={`columnNames.${index}.new`}
                        render={({ field: { value, ...fieldProps } }) => (
                          <TextField
                            {...fieldProps}
                            value={value ?? ''}
                            size="small"
                            fullWidth
                            disabled={isUpdating}
                            helperText={
                              errors.columnNames?.[index] ? (
                                <Typography variant="body2" color="error">
                                  {intl.formatMessage({
                                    id: 'features.datasets.settings.error.required',
                                  })}
                                </Typography>
                              ) : null
                            }
                          />
                        )}
                      />
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                        disabled={isUpdating}
                      >
                        <SvgIcon>
                          <IconTrash />
                        </SvgIcon>
                      </IconButton>
                    </Stack>
                  );
                })
              ) : (
                <Stack gap={0.5}>
                  <Stack
                    component={Paper}
                    textAlign="center"
                    p={2}
                    elevation={2}
                    sx={
                      errors.columnNames
                        ? { border: t => `1px solid ${t.palette.error.main}` }
                        : {}
                    }
                  >
                    <Typography variant="h6" color="text.secondary">
                      {intl.formatMessage({
                        id: 'features.datasets.settings.form.columnsSection.noColumns.title',
                      })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {intl.formatMessage(
                        {
                          id: 'features.datasets.settings.form.columnsSection.noColumns.text',
                        },
                        {
                          button: button(() => append({ old: '', new: '' }), {
                            textDecoration: 'underline',
                          }),
                        }
                      )}
                    </Typography>
                  </Stack>
                  {errors.columnNames ? (
                    <Typography
                      variant="body2"
                      color="error"
                      textAlign="center"
                    >
                      {intl.formatMessage({
                        id: 'features.datasets.settings.error.columnsRequired',
                      })}
                    </Typography>
                  ) : null}
                </Stack>
              )}
              <Button
                variant="contained"
                sx={{ alignSelf: 'flex-end' }}
                onClick={() => append({ old: '', new: '' })}
                disabled={isUpdating}
              >
                {intl.formatMessage({
                  id: 'features.datasets.settings.form.button.addColumn',
                })}
              </Button>
            </Stack>
          </ContentGroup>
        </Stack>
      ) : (
        <NotFound />
      )}
    </PageLayoutV2>
  );
};

import { ExpandRounded } from '@rossum/ui/icons';
import {
  AccordionDetails,
  Box,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import JsonEditor, {
  Props as EditorProps,
} from '../../../../components/UI/Editor/components/JsonEditor';
import CopyToClipboardButton from '../../../../ui/copy-to-clipboard/CopyToClipboardButton';
import {
  LOG_PADDING_SPACING,
  LogContentType,
  logsTabs,
  LogTab,
} from '../helpers';

type TabPanelProps = {
  children?: ReactNode;
  active: boolean;
  tabId: string;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, active, tabId } = props;

  return (
    <div role="tabpanel" hidden={!active} id={tabId}>
      {active && <Box>{children}</Box>}
    </div>
  );
};

type LogDetailProps = {
  detail: Record<
    LogContentType,
    { value: string | null; editorProps?: Partial<EditorProps> }
  >;
  message: string;
};

export const LogDetail = ({ detail, message }: LogDetailProps) => {
  const [currentTab, setCurrentTab] = useState<LogTab>('request');
  const [expanded, setExpanded] = useState(false);

  const intl = useIntl();

  const valueToClipboard = detail[currentTab].value;

  const handleChange = (newTab: LogTab) => setCurrentTab(newTab);

  return (
    <AccordionDetails sx={{ p: LOG_PADDING_SPACING, pt: 0 }}>
      <Divider />
      <Box sx={{ width: '100%', mt: 1 }}>
        {message !== '' && (
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight={500}>
              {`${intl.formatMessage({
                id: 'containers.settings.extensions.logs.message',
              })}:`}
            </Typography>
            <Box
              sx={{
                px: 1,
                py: 0.5,
              }}
            >
              <Typography
                variant="body2"
                fontFamily="RobotoMono"
                sx={{
                  overflowX: 'auto', // needed for overflowed text
                  whiteSpace: 'pre-wrap', // needed for line breaks in case of multiple messages
                }}
              >
                {message}
              </Typography>
            </Box>
          </Stack>
        )}
        <Tabs
          value={currentTab}
          onChange={(_, value: LogTab) => handleChange(value)}
        >
          {logsTabs.map(tab => {
            return (
              <Tab
                key={tab}
                value={tab}
                label={
                  <Typography variant="body2" fontWeight={500}>
                    {intl.formatMessage({
                      id: `containers.settings.extensions.logs.${tab}`,
                    })}
                  </Typography>
                }
              />
            );
          })}
          <Stack
            alignItems="center"
            alignSelf="center"
            sx={{ ml: 'auto' }}
            direction="row"
          >
            {valueToClipboard && (
              <CopyToClipboardButton
                content={valueToClipboard}
                iconColor="text.primary"
              />
            )}
            <Tooltip
              placement="top"
              title={intl.formatMessage({
                id: `containers.settings.extensions.logs.${
                  expanded ? 'collapse' : 'expand'
                }`,
              })}
            >
              <IconButton onClick={() => setExpanded(!expanded)}>
                <ExpandRounded fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Tabs>
        {logsTabs.map(tab => {
          const { value } = detail[tab];
          const missingDataMessage = intl.formatMessage({
            id: `containers.settings.extensions.logs.detailDataAreMissing`,
          });

          return (
            <TabPanel active={currentTab === tab} tabId={tab} key={tab}>
              <JsonEditor
                name={tab}
                value={value === null ? missingDataMessage : value}
                {...detail[tab].editorProps}
                debounceChangePeriod={0}
                height={expanded ? 600 : 260}
                readonly
              />
            </TabPanel>
          );
        })}
      </Box>
    </AccordionDetails>
  );
};

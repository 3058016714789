import { SubdirectoryArrowRight } from '@rossum/ui/icons';
import {
  Box,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@rossum/ui/material';
import { constructDocumentUrl } from '../../../../lib/url';
import { dataDisabled } from '../../../../redux/modules/annotation/helpers';
import { ID } from '../../../../types/basic';
import Filename from '../../../../ui/filename/Filename';
import StatusChip from '../../../../ui/status/StatusChip';
import { disabledContainerStyles } from '../../../pricing/components/DisabledRow';
import { AttachmentT, isAnnotationDisabled } from './helpers';
import styles from './styles.module.sass';

type Props = {
  // Attachment with annotation present.
  attachment: AttachmentT & Pick<Required<AttachmentT>, 'annotation'>;
  userId: ID;
  isChild: boolean;
  onMouseEnter: (attachment: AttachmentT) => void;
  onMouseLeave: () => void;
};

const AnnotationAttachment = ({
  attachment,
  userId,
  isChild,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  const { annotation } = attachment;

  return (
    <ListItemButton
      disableRipple
      disableGutters
      sx={{
        py: 0,
        pl: 2,
        borderRadius: 1,
        ...(attachment.annotation.restrictedAccess
          ? disabledContainerStyles
          : {}),
      }}
    >
      {isChild && (
        <SubdirectoryArrowRight
          fontSize="small"
          sx={{ color: theme => theme.palette.text.secondary }}
        />
      )}
      <ListItemIcon sx={{ minWidth: 25, mr: 1 }}>
        {annotation.status !== 'purged' ? (
          <StatusChip
            status={annotation.status}
            isOwned={attachment.annotationModifier?.id === userId}
            modifier={attachment.annotationModifier?.username}
          />
        ) : null}
      </ListItemIcon>
      <ListItemText sx={{ my: 0 }}>
        <Link
          // TODO isAnnotationDisabled cursor
          onClick={e =>
            isAnnotationDisabled(annotation.status) && e.preventDefault()
          }
          target="_blank"
          href={constructDocumentUrl({ id: annotation.id })}
          rel="noreferrer"
          underline="none"
          sx={{ '&:hover': { textDecoration: 'none' } }}
        >
          <Box
            className={styles.PagePreview}
            onMouseEnter={() => onMouseEnter(attachment)}
            onMouseLeave={() => onMouseLeave()}
            sx={{ py: 1 }}
          >
            <Typography
              variant="body2"
              color="text.primary"
              whiteSpace="nowrap"
              component="div"
            >
              <Filename
                status={attachment.annotation.status}
                disabled={dataDisabled.includes(annotation.status)}
                originalFilename={attachment.document.originalFileName}
                disableClipboard
              />
            </Typography>
          </Box>
        </Link>
      </ListItemText>
    </ListItemButton>
  );
};

export { AnnotationAttachment };

import { MiniValidationScreen } from '@rossum/mini-validation-screen';
import { Stack } from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route as ReactRoute,
  RouteChildrenProps,
  Router,
  Switch,
} from 'react-router';
import { QUERY_KEY_QUEUES_UNPAGINATED } from '../business/queues/useUnpaginatedQueues';
import { useDefaultListBacklink } from '../components/AnnotationInformation/components/useAnnotationBacklink';
import Announcements from '../components/Announcements';
import Navbar from '../components/NavBar';
import {
  ApprovalWorkflowsGuard,
  EmbeddedModeGuard,
  NonAdminRestrictor,
  nonAdminRestrictorRoute,
  StatisticsRouteGuard,
} from '../components/Restrictors';
import { DEV_FEATURES_ENABLED } from '../constants/config';
import RedirectToNewDashboard from '../containers/AnnotationList/RedirectToNewDashboard';
import { AutomationRoutes } from '../containers/Automation/AutomationRoutes';
import { automationPath } from '../containers/Automation/helpers';
import { ChangesSaved } from '../containers/ChangesSaved';
import { CHANGES_SAVED_PATH } from '../containers/ChangesSaved/constants';
import DocumentValidation from '../containers/DocumentValidation';
import IntegrationTest from '../containers/IntegrationTest';
import IntroScreen from '../containers/IntroScreen';
import Login from '../containers/Login';
import Maintenance from '../containers/Maintenance';
import Modals from '../containers/Modals';
import OrganizationLoader from '../containers/OrganizationLoader';
import PasswordConfirm from '../containers/Password/Confirm';
import PasswordReset from '../containers/Password/Reset';
import PersonalInfo from '../containers/PersonalInfo';
import { settingsPath } from '../containers/Settings/helpers';
import { SettingsRoutes } from '../containers/Settings/SettingsRoutes';
import Statistics from '../containers/Statistics';
import TimeExpired from '../containers/TimeExpired';
import { usersPath } from '../containers/Users/helpers';
import { WorkflowRoutes } from '../containers/WorkflowRoutes/WorkflowRoutes';
import RequireAuth from '../decorators/RequireAuth';
import { Billing } from '../features/billing';
import { EditDocument } from '../features/document-edit/EditDocument';
import { DocumentList } from '../features/document-list/DocumentList';
import { getObservedAnnotations } from '../features/document-list/getObservedAnnotations';
import { ALL_DOCUMENTS_QUERY_KEY } from '../features/document-list/hooks/useFetchDashboardData';
import { QUERY_KEY_STATUS_COUNTS } from '../features/document-list/statuses/hooks/useStatusTabCounts';
import { ErrorPage } from '../features/error-page';
import { ExtensionInteropUi } from '../features/extension-interop/ExtensionInteropUi';
import { QueueSettingsRoute } from '../features/queue-settings/routes/QueueSettingsRoute';
import { QuickSearch } from '../features/quick-search/QuickSearch';
import { TaskContextProvider } from '../features/tasks/TaskContext';
import { TasksDialog } from '../features/tasks/TasksDialog';
import { useLogPageView } from '../lib/gtm';
import { history } from '../redux/configureStore';
import {
  fontFamilySelector,
  monoFontSelector,
} from '../redux/modules/organization/selectors';
import {
  enterLogin as enterLoginAction,
  enterStatistics as enterStatisticsAction,
  enterValidation as enterValidationAction,
  leaveStatistics as leaveStatisticsAction,
  leaveValidation as leaveValidationAction,
} from '../redux/modules/ui/actions';
import { State } from '../types/state';
import { useFeatureFlag } from '../unleash/useFeatureFlag';
import { HelmetComponent } from './HelmetComponent';
import Route from './Route';
import styles from './style.module.sass';

type StateProps = {
  useMonoFont: boolean;
  fontFamily: string;
};

type DispatchProps = {
  enterLogin: () => void;
  enterStatistics: () => void;
  enterValidation: () => void;
  leaveStatistics: () => void;
  leaveValidation: () => void;
};

type Props = StateProps & DispatchProps;

const Routes = ({
  enterLogin,
  enterStatistics,
  enterValidation,
  leaveStatistics,
  leaveValidation,
  useMonoFont,
  fontFamily,
}: Props) => {
  const queryClient = useQueryClient();
  useLogPageView(history);
  useDefaultListBacklink(history);

  const aggressiveRefetchEnabled = useFeatureFlag(
    'aggressive-dashboard-refetching'
  );

  const invalidateDashboardCounts = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_STATUS_COUNTS],
    });

    // not sure why we refetch queues but I left it as it was
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_QUEUES_UNPAGINATED],
    });
  }, [queryClient]);

  const invalidateDashboardQueries = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [ALL_DOCUMENTS_QUERY_KEY],
    });
  }, [queryClient]);

  const handleTaskFinished = useCallback(
    (_taskType: 'importTask', payload: number[]) => {
      if (aggressiveRefetchEnabled) {
        const observedAnnotations = new Set(
          getObservedAnnotations(queryClient).map(({ id }) => id)
        );

        // always invalidate counts
        invalidateDashboardCounts();

        // invalidate dashboard data if some of the imported annotations are currently observed
        if (payload.some(id => observedAnnotations.has(id))) {
          invalidateDashboardQueries();
        }
      }
    },
    [
      aggressiveRefetchEnabled,
      invalidateDashboardCounts,
      invalidateDashboardQueries,
      queryClient,
    ]
  );

  // the user will always be defined under RequireAuth hence this flag should always be used within the scope of RequireAuth
  return (
    <Router history={history}>
      <HelmetComponent />
      <Stack
        className={clsx(
          styles.BasepageContainerLayout,
          useMonoFont && 'monospace',
          'basepageContainerLayout'
        )}
        sx={{
          fontFamily,
          backgroundColor: 'background.default',
        }}
      >
        <Announcements />
        <Navbar />
        <div className={styles.BasepageContainer}>
          <Modals />
          <Switch>
            <Route path="/maintenance" component={Maintenance} />
            <Route path="/timeExpired" component={TimeExpired} />
            <Route path="/error" component={ErrorPage} />
            <Route path={CHANGES_SAVED_PATH} component={ChangesSaved} />
            <Route path="/recovery" component={PasswordReset} />
            <Route path="/resetPassword" component={PasswordConfirm} />
            <Route path="/integration-test" component={IntegrationTest} />
            <Route path="/mobileIntroScreen" component={IntroScreen} />
            <Route path="/organizationLoading" component={OrganizationLoader} />
            <Route exact path="/" component={Login} onEnter={enterLogin} />
            <RequireAuth>
              <TaskContextProvider
                onUploadCreated={() => {
                  invalidateDashboardCounts();
                  invalidateDashboardQueries();
                }}
              >
                <TasksDialog onTaskFinished={handleTaskFinished} />
                <Switch>
                  <Route
                    path="/queues/:queueId"
                    exact
                    component={RedirectToNewDashboard}
                  />
                  <Route path="/documents" component={DocumentList} />
                  <Route
                    path="/annotations/:queueId?"
                    component={RedirectToNewDashboard}
                  />
                  <Route
                    path="/emails/:queueId?"
                    component={RedirectToNewDashboard}
                  />
                  <Route
                    onEnter={enterValidation}
                    onExit={leaveValidation}
                    path={[
                      '/document/:annotationId?',
                      '/embedded/document/:annotationId?',
                    ]}
                    render={() => (
                      <EmbeddedModeGuard>
                        <Switch>
                          <Route
                            path={[
                              '/document/:annotationId/edit',
                              '/embedded/document/:annotationId/edit',
                            ]}
                            component={EditDocument}
                          />
                          <Route>
                            {(
                              props: RouteChildrenProps<{
                                annotationId?: string;
                              }>
                            ) => {
                              return props.match?.params.annotationId ? (
                                <DocumentValidation {...props} />
                              ) : (
                                <Redirect to="/" />
                              );
                            }}
                          </Route>
                        </Switch>
                      </EmbeddedModeGuard>
                    )}
                  />
                  {DEV_FEATURES_ENABLED ? (
                    <Route
                      path={['/document-v2/:annotationId']}
                      render={({ match }) => (
                        <MiniValidationScreen
                          annotationId={Number(match.params.annotationId)}
                        />
                      )}
                    />
                  ) : null}

                  <Route
                    path="/requests"
                    render={() => (
                      <ApprovalWorkflowsGuard>
                        <WorkflowRoutes />
                      </ApprovalWorkflowsGuard>
                    )}
                  />

                  {/* these routes are restricted for non admin users */}
                  <Route
                    path={[settingsPath(), automationPath()]}
                    render={routeProps => (
                      <NonAdminRestrictor
                        restrictComponent={<Redirect to="/documents" />}
                      >
                        <Switch>
                          <Route
                            path={automationPath()}
                            component={AutomationRoutes}
                          />
                          <Route
                            path={settingsPath()}
                            render={() => <SettingsRoutes {...routeProps} />}
                          />
                        </Switch>
                      </NonAdminRestrictor>
                    )}
                  />

                  <Route
                    path={['/users', '/users/:id']}
                    render={({ location }) => (
                      <Redirect
                        to={location.pathname.replace('/users', usersPath())}
                      />
                    )}
                  />
                  <ReactRoute path="/queues/:queueId/settings">
                    {nonAdminRestrictorRoute(routeProps => (
                      <QueueSettingsRoute {...routeProps} />
                    ))}
                  </ReactRoute>

                  <Route path="/account/:section" component={PersonalInfo} />
                  <Route path="/billing" component={Billing} />
                  <Route
                    path="/statistics"
                    onEnter={enterStatistics}
                    onExit={leaveStatistics}
                    render={props => (
                      <StatisticsRouteGuard>
                        <Statistics {...props} />
                      </StatisticsRouteGuard>
                    )}
                  />

                  <Route
                    render={() => <Redirect to="/error?reason=not-found" />}
                  />
                </Switch>
              </TaskContextProvider>
              <ExtensionInteropUi />
              <NonAdminRestrictor>
                <QuickSearch />
              </NonAdminRestrictor>
            </RequireAuth>
          </Switch>
        </div>
      </Stack>
    </Router>
  );
};

const mapDispatchToProps = {
  enterLogin: enterLoginAction,
  enterStatistics: enterStatisticsAction,
  leaveStatistics: leaveStatisticsAction,
  enterValidation: enterValidationAction,
  leaveValidation: leaveValidationAction,
};

const mapStateToProps = (state: State) => ({
  useMonoFont: monoFontSelector(state),
  fontFamily: fontFamilySelector(state),
});

export default connect<StateProps, DispatchProps, Record<string, never>, State>(
  mapStateToProps,
  mapDispatchToProps
)(Routes);

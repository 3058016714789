import { Collapse, Stack } from '@rossum/ui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'remeda';
import { relevantBlockersSelector } from '../../../../../redux/modules/annotation/selectors';
import { useDocumentStore } from '../../../document-store/DocumentStore';
import { SidebarContainer } from '../../shared/SidebarContainer';
import { AnnotationBlocker } from './AnnotationBlocker';
import { DatapointBlocker } from './DatapointBlocker';

type AutomationBlockersListType = {
  handleSelectDatapoint: (id: number) => void;
};

const LIST_MAX_HEIGHT = 270;

const AutomationBlockers = React.memo(
  ({ handleSelectDatapoint }: AutomationBlockersListType) => {
    const intl = useIntl();

    const automationBlockers = useSelector(relevantBlockersSelector);

    const automationBlockersVisible = useDocumentStore(
      state => state.sidebarState.blockersVisible
    );

    const [annotationBlockers, datapointBlockers] = useMemo(
      () =>
        R.partition(
          automationBlockers,
          blocker => blocker.level === 'annotation'
        ),
      [automationBlockers]
    );

    const containerRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      if (containerRef.current && automationBlockersVisible) {
        containerRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, [automationBlockersVisible]);

    return (
      <Collapse in={automationBlockersVisible} ref={containerRef}>
        <SidebarContainer
          title={intl.formatMessage({
            id: 'components.sidebarV2.automationBlockers.title',
          })}
        >
          <Stack
            sx={{
              maxHeight: LIST_MAX_HEIGHT,
              overflow: 'auto',
              py: 1,
            }}
            spacing={2}
          >
            {annotationBlockers.map(blocker => (
              <AnnotationBlocker key={blocker.type} blocker={blocker} />
            ))}
            {datapointBlockers.map(blocker => (
              <DatapointBlocker
                key={`${blocker.schemaId}-${blocker.type}`}
                blocker={blocker}
                handleSelectDatapoint={handleSelectDatapoint}
              />
            ))}
          </Stack>
        </SidebarContainer>
      </Collapse>
    );
  }
);

AutomationBlockers.displayName = 'AutomationBlockers';

export { AutomationBlockers };
